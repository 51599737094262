import React, { useState } from 'react';
import { Alert, AlertTitle, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { changeKYCStatus } from '../../lib/service/DashboardApi/dashboardApi';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CustomAlert = styled(Alert)(({ theme }) => ({
  '& .MuiAlert-action': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .action-container': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  borderRadius: '10px',
  boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
  transition: 'transform 0.3s ease, opacity 0.3s ease',
}));

const VerificationNotification = ({ statusLink, onOpenModal }) => {
  const [visible, setVisible] = useState(true);
  const { kycStatus, initiateKycMailSent }  = statusLink;

  const handleClose = async () => {
    try {
      await changeKYCStatus({ show_notif: true });
      setVisible(false);
    } catch (error) {
      console.error('Failed to change KYC status:', error);
    }
  };

  if (!visible) {
    return null;
  }

  let alertTitle, alertMessage, buttonText, severity;
  if (kycStatus === 'COMPLETED') {
    alertTitle = "Verification completed";
    alertMessage = "Congratulations! Your verification process has been successfully completed. You can now enjoy the full benefit of endl with verified status.";
    buttonText = "Got it";
    severity = "success";
  } else if (kycStatus === 'NOT_INITIATED' && !initiateKycMailSent) {
    alertTitle = "Initiate verification";
    alertMessage = "Complete your KYC to unlock all features and secure transactions.";
    buttonText = "Start KYC";
    severity = "info";
  } else if (initiateKycMailSent) {
    alertTitle = "KYC in progress";
    alertMessage = "We will notify you when the verification is completed and the status will be updated automatically.";
    severity = "warning";
  } else if (kycStatus === 'REJECTED') {
    alertTitle = "Verification failed";
    alertMessage = "We regret to inform you that the verification process has failed. This could be due to several reasons, including incorrect information provided or expired documents.";
    buttonText = "See status";
    severity = "error";
  } else if (kycStatus === 'PROCESSING') {
    alertTitle = "KYC in progress";
    alertMessage = "We will notify you when the verification is completed and the status will be updated automatically.";
    buttonText = "See status";
    severity = "warning";
  } else {
    alertTitle = "Complete your KYC";
    alertMessage = "Complete your KYC to unlock all features and secure transactions.";
    buttonText = "See status";
    severity = "warning";
  }

  return (
    <CustomAlert
      severity={severity}
      action={
        <div className="action-container">
          {!initiateKycMailSent &&(
            <Button
            color="inherit"
            size="small"
            onClick={kycStatus !== 'COMPLETED' ? onOpenModal : handleClose}
            sx={{
              marginRight: '10px',
              border: '1px solid white',
              color: '#000',
              backgroundColor: 'White',
              '&:hover': {
                backgroundColor: 'White',
              },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {buttonText}
            {kycStatus !== 'COMPLETED' && (<ArrowForwardIcon sx={{ marginLeft: '5px' }} />)}
          </Button>
          )}
          {kycStatus === 'COMPLETED' && (
             <IconButton
             aria-label="close"
             color="inherit"
             size="small"
             onClick={handleClose}
            >
             <CloseIcon fontSize="inherit" />
           </IconButton>
          )}  
        </div>
      }
      style={{ marginBottom: '16px' }}
    >
      <AlertTitle>{alertTitle}</AlertTitle>
      {alertMessage}
    </CustomAlert>
  );
};

export default VerificationNotification;
