import React, { useMemo, useState, useCallback, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal, IconButton, Grid, useMediaQuery } from "@mui/material";
import FormSummerySidebar from "./FormSummerySidebar";
import { getAllRecipient } from "../../../lib/service/PaymentDetailApi/PaymentDetailApi";
import StepperUI from "../../Common/StepperUI/StepperUI";
import SendMoneyPaymentDetails from "../../Forms/SendMoneyPaymentDetailsForm";
import AddAndEditManualRecipientModal from "../AddAndEditManualRecipientModal";
import CryptoToFiat from "./CryptoToFiatFlow/CryptoToFiat";
import endlMainLogo from "../../../Images/brandings/endlMainLogo.png";
import commonHelpers from "../../../utils/common.helpers";
import _debounce from "lodash.debounce";
import FiatToFiat from "./FiatToFiatFlow/FiatToFiat";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  maxHeight: "100vh",
};

const logoStyle = {
  position: "absolute",
  top: "20px",
  left: "20px",
  width: "100px",
  height: "auto",
};

const SendMoneyModal = ({ open, onClose, addNewTransaction }) => {
  const [step, setStep] = useState(0);
  const steps = useMemo(() => ["Payment Details", "Payment Method", "Recipient & Amount", "Review & pay"], []);
  
  const [selectedPaymentType, setSelectedPaymentType] = useState("cryptoToFiat");
  const [selectedRecipient, setSelectedRecipient] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [addRecipientModal, setAddRecipientModal] = useState(false);
  const [isRecipientLoading, setIsRecipientLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [errorTxn, setErrorTxn] = useState(false);
  const [successTxn, setSuccessTxn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const handleNext = useCallback(() => {
    setStep((prev) => prev + 1);
  }, []);

  const handleGoBack = useCallback(() => {
    if (step !== 0) {
      setStep((prev) => prev - 1);
    }
  }, [step]);

  const handleRecipientOptionChange = useCallback(
    (event) => {
      setSearchQuery("");
      const recipientId = event.target.value;
      const recipient = recipients.find((recipient) => recipient.id === recipientId);
      if (recipient) {
        setSelectedRecipient(recipient);
        setSearchQuery("");
      } else {
        setSelectedRecipient(undefined);
      }
    },
    [recipients]
  );
  
  const handlePaymentTypeOptionChange = (event) => {
    const newPaymentType = event.target.value;
    
    setSelectedPaymentType(newPaymentType);
    
    setSelectedRecipient(null);

    setSearchQuery("");
  };
  
  

  // Fetch recipients
  const fetchAllRecipient = async() => {
    setIsRecipientLoading(true);
    try {
      const response = await getAllRecipient({
        page: page,
        size: size,
        sortBy: "createdOn,desc",
        recipientType: ["BUSINESS", "INDIVIDUAL"],
        receiveCategory: ["BANK_ACCOUNT"],
        search: searchQuery,
      });

      if (response.data.recipients) {
        const processedRecipients = response.data.recipients.map((recipient) => {
          const { firstName, lastName, ...rest } = recipient;
          return {
            id: recipient.id,
            name: lastName ? commonHelpers.capitalizeFirstLetter(`${firstName} ${lastName}`) : commonHelpers.capitalizeFirstLetter(firstName),
            ...rest,
          };
        });

        const uniqueRecipients = [...recipients, ...processedRecipients].reduce((acc, current) => {
          const x = acc.find(item => item.id === current.id);
          if (!x) {
            acc.push(current);
          }
          return acc;
        }, []);

        setRecipients(uniqueRecipients);
        setTotalCount(response.data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching recipients:", error);
    } finally {
      setIsRecipientLoading(false);
    }
  };

  // Fetch the first batch of recipients when the modal opens
  useEffect(() => {
      fetchAllRecipient();
  }, [searchQuery, page]);

  const searchRecipients = _debounce((query) => {
    setPage(0); 
    setSearchQuery(query);
    setRecipients([]);
  },500);

  // Function to fetch more recipients for infinite scroll
  const fetchMoreRecipients = () => {
    if (recipients.length < totalCount) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const addRecipientModalHandler = () => {
    setAddRecipientModal(true);
  };

  const addNewRecipient = (recipient) => {
    const { firstName, lastName, ...rest } = recipient;
    const processedRecipient = {
      id: recipient.id,
      name: lastName ? `${firstName} ${lastName}` : firstName,
      ...rest,
    };
    setRecipients((prev) => [processedRecipient, ...prev]);
    setPage(0);
    setSearchQuery("");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style }}>
        {addRecipientModal && (
          <AddAndEditManualRecipientModal
            open={addRecipientModal}
            onClose={() => setAddRecipientModal(false)}
            addNewRecipient={addNewRecipient}
            provideReceiveMode={selectedPaymentType === "cryptoToFiat" ? "BANK_ACCOUNT" : ""}
            mode={isEditing ? "edit" : "create"}
            editRecipientData={selectedRecipient}
          />
        )}
        <Box padding={2} borderBottom={1} display={"flex"} justifyContent={"center"} alignItems={"center"} borderColor={"rgba(0,0,0,0.3)"} position={"relative"} width="100%">
          {isMdUp && <img src={endlMainLogo} alt="Logo" style={logoStyle} />}
          <Box sx={{ width: { xs: "90%", sm: "40%" } }}>
            <StepperUI step={step} steps={steps} successTxn={successTxn} errorTxn={errorTxn} />
          </Box>
          <IconButton onClick={onClose} aria-label="close" sx={{ position: "absolute", right: "12px" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {step === 0 && (
          <Grid container py={5} justifyContent={{ sm: "center", lg: "flex-start" }}>
            <Grid item xs={12} sm={12} lg={4}>
              <FormSummerySidebar title={"Payment details"} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SendMoneyPaymentDetails
                selectedPaymentType={selectedPaymentType}
                isEditing={isEditing}
                recipients={recipients}
                handleRecipientOptionChange={handleRecipientOptionChange}
                handlePaymentTypeOptionChange={handlePaymentTypeOptionChange}
                handleNext={handleNext}
                addRecipientHandler={addRecipientModalHandler}
                selectedRecipient={selectedRecipient}
                isRecipientLoading={isRecipientLoading}
                totalCount={totalCount}
                fetchMoreRecipients={fetchMoreRecipients} 
                page={page}
                size={size}
                searchRecipients={searchRecipients}
                setSearchQuery={setSearchQuery}
              />
            </Grid>
          </Grid>
        )}

        {step > 0 && selectedPaymentType === "cryptoToFiat" && (
          <CryptoToFiat
            step={step}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            selectedPaymentType={selectedPaymentType}
            selectedRecipient={selectedRecipient}
            addNewTransaction={addNewTransaction}
            onClose={onClose}
            errorTxn={errorTxn}
            successTxn={successTxn}
            setErrorTxn={setErrorTxn}
            setSuccessTxn={setSuccessTxn}
          />
        )}
        {step > 0 && selectedPaymentType === "fiatToFiat" && (
          <FiatToFiat
            step={step}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            selectedPaymentType={selectedPaymentType}
            selectedRecipient={selectedRecipient}
            addNewTransaction={addNewTransaction}
            onClose={onClose}
            errorTxn={errorTxn}
            successTxn={successTxn}
            setErrorTxn={setErrorTxn}
            setSuccessTxn={setSuccessTxn}
          />
        )}
      </Box>
    </Modal>
  );
};

export default SendMoneyModal;
