import directService from "../AppService";
import { checkAuthToken } from "../AuthToken";

function getAuthToken() {
  return localStorage.getItem("authToken");
}

const addRecipients = (data) => {
  try {
    const checkToken = checkAuthToken();
    if (checkToken) {
      const token = getAuthToken();
      return directService({
        url: `/api/v1/recipient/add`,
        method: "POST",
        data: data,
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
    }
  } catch (error) {
    console.log(error);
  }
};

const getRecipient = (id) => {
  try {
    const checkToken = checkAuthToken();
    if (checkToken) {
      const token = getAuthToken();
      return directService({
        url: `/api/v1/recipient/${id}`,
        method: "GET",
        // data: data,
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
    }
  } catch (error) {
    console.log(error);
  }

};

const getCountry = (data) => {
  try {
    const checkToken = checkAuthToken();
    if (checkToken) {
      const token = getAuthToken();
      return directService({
        url: `/api/v1/country/list`,
        method: "POST",
        data: data,
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
    }
  } catch (error) {
    console.log(error);
  }
};

const updateRecipient = (data) => {
 
  try {
    const checkToken = checkAuthToken();
    if (checkToken) {
      const token = getAuthToken();
      return directService({
        url: `/api/v1/recipient/update`,
        method: "PATCH",
        data: data,
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
    }
  } catch (error) {
    console.log(error);
  }
};

const deleteRecipient = (id) => {
 
  try {
    const checkToken = checkAuthToken();
    if (checkToken) {
      const token = getAuthToken();
      return directService({
        url: `/api/v1/recipient/${id}`,
        method: "DELETE",
        // data: data,
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
    }
  } catch (error) {
    console.log(error);
  }
};

const getAllRecipients = (data) => {
  
  try {
    const checkToken = checkAuthToken();
    if (checkToken) {
      const token = getAuthToken();
      return directService({
        url: `/api/v1/recipient/all`,
        method: "POST",
        data: data,
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
    }
  } catch (error) {
    console.log(error);
  }
};

const exportRecipients = (data) => {
  try {
      const checkToken = checkAuthToken()
      if (checkToken) {
          const token = getAuthToken();
          if (!token) {
              console.error('No auth token found. Please login.');
              return;
          }
          return directService(
              {
                  url: `api/v1/recipient/generate/document`,
                  method: 'POST',
                  data: data,
                  headers: { Authorization: `Bearer ${token}` },
                  responseType: 'blob',
              },

          )
      }
  } catch (error) {
      console.log(error)
  }
}

export {
  addRecipients,
  getRecipient,
  getAllRecipients,
  deleteRecipient,
  updateRecipient,
  getCountry,
  exportRecipients,
};
