import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const FormSummerySidebar = ({
  title,
  showSummery,
  showStepOneSummery,
  showStepTwoSummery,
  walletAddress,
  sourceCurrency,
  width,
  selectedPaymentType,
  selectedRecipient,
  selectedPaymentMethod,
  networkValue,
}) => {
  return (
    <Box
      width={width || "100%"}
      display="flex"
      justifyContent="center"
      mb={{ xs: "20px", md: "0px" }}
    >
      <Box width={{ xs: "100%", sm: "50%", lg: "250px" }}>
        <Typography
          variant="h5"
          gutterBottom
          fontSize="22px"
          fontFamily="PPMori-Semi-Bold"
          fontWeight={500}
          textAlign={{ xs: 'center', md: "start" }}
          mb={2}
        >
          {title}
        </Typography>
        {showSummery && (
          <Accordion expanded elevation={0} sx={{ width: "100%" }}>
            <AccordionSummary>
              <Typography
                variant="h6"
                fontSize="14px"
                fontWeight="bold"
                fontFamily="PPMori-Regular"
              >
                Summary
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              {showStepOneSummery && (
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography fontSize={12} fontFamily="PPMori-Regular">
                      Recipient
                    </Typography>
                    <Typography fontSize={12} fontFamily="PPMori-Regular">
                      {selectedRecipient?.name || `${selectedRecipient?.firstName} ${selectedRecipient?.lastName}`}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography fontSize={12} fontFamily="PPMori-Regular">
                      Bank Name
                    </Typography>
                    <Typography fontSize={12} color="rgba(0,0,0,0.70)" fontFamily="PPMori-Regular">
                      {selectedRecipient?.bankName}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography fontSize={12} fontFamily="PPMori-Regular">
                      Account Number/IBAN
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <MoreHorizIcon fontSize="small" />
                      <Typography fontSize={12} color="rgba(0,0,0,0.70)" fontFamily="PPMori-Regular">
                        {selectedRecipient?.bankAccountNumber?.slice(-4)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {showStepTwoSummery && (
                <>
                  <Divider sx={{ my: 2 }} />
                  {selectedPaymentType === "cryptoToFiat" &&
                    selectedPaymentMethod === "CONNECTED_WALLET" && (
                      <ConnectWalletSummery
                        walletAddress={walletAddress}
                        sourceCurrency={sourceCurrency}
                        networkValue={networkValue}
                      />
                    )}
                  {selectedPaymentType === "cryptoToFiat" &&
                    selectedPaymentMethod === "MANUAL_PAYMENT" && (
                      <ManualPaymentSummery sourceCurrency={sourceCurrency} networkValue={networkValue} />
                    )}
                  {selectedPaymentType === "fiatToFiat" && (
                    <FiatPaymentSummery sourceCurrency={sourceCurrency} />
                  )}
                </>
              )}
            </AccordionDetails>
            <Divider />
          </Accordion>
        )}
      </Box>
    </Box>
  );
};

const ConnectWalletSummery = ({ walletAddress, sourceCurrency, networkValue }) => (
  <Box display="flex" flexDirection="column" gap={1}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Payment method
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Connected wallet
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Wallet Id
      </Typography>
      <Typography fontSize="10px" fontFamily="PPMori-Regular">
        MetaMask({walletAddress.slice(0, 6)}...{walletAddress.slice(-4)})
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Blockchain network
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        {networkValue}
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Source currency
      </Typography>
      <Typography fontSize="10px" fontFamily="PPMori-Regular">
        {sourceCurrency}
      </Typography>
    </Box>
  </Box>
);

const ManualPaymentSummery = ({ sourceCurrency, networkValue }) => (
  <Box display="flex" flexDirection="column" gap={1}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Payment method
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        QR Code payment
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Blockchain network
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular">
        {networkValue}
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Source currency
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular">
        {sourceCurrency}
      </Typography>
    </Box>
  </Box>
);

const FiatPaymentSummery = ({ sourceCurrency }) => (
  <Box display="flex" flexDirection="column" gap={1}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Payment method
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Endl Account
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Source currency
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular">
        {sourceCurrency}
      </Typography>
    </Box>
  </Box>
);

export default FormSummerySidebar;
