import * as React from "react";
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { isDateInRange, isSameDay } from "../../utils/date.helpers";
dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})(({ theme, isSelected, isHovered, day, lastDay, firstDay }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

function Day(props) {
  const { day, selectedDay, rangeDate, ...other } = props;
  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      firstDay={rangeDate?rangeDate[0] : undefined}
      lastDay={rangeDate?rangeDate[1] : undefined}
      isSelected={rangeDate &&(
        isSameDay(rangeDate[0], rangeDate[1])
          ? isSameDay(day, rangeDate[0])
          : isDateInRange(day, rangeDate[0], rangeDate[1]))
      }
    />
  );
}
export default function DateCalendarValue({ value, rangeDate, onDateSelect, ...props }) {
  const defaultValue = dayjs();
  let selectedValue =
    value && dayjs(value).isValid() ? dayjs(value) : defaultValue;
    if(rangeDate && dayjs(rangeDate[0]).isSame(rangeDate[1])){
      selectedValue = dayjs(rangeDate[0])
    }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateCalendar", "DateCalendar"]}>
        <DemoItem>
          <DateCalendar
            value={selectedValue}
            onChange={onDateSelect}
            showDaysOutsideCurrentMonth
            slots={{ day: Day }}
            slotProps={{
              day: () => ({
                selectedDay: selectedValue,
                rangeDate: rangeDate,
              }),
            }}
            disableFuture
            {...props}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}

export const LocalDatePicker = ({ value, onChange, error, ...props }) => {
  const defaultValue = dayjs();
  const selectedValue =
    value && dayjs(value).isValid() ? dayjs(value) : defaultValue;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DemoContainer
        components={["DatePicker", "DatePicker"]}
        sx={{
          width: "100%",
        }}
      > */}
      <DatePicker
        sx={{
          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
            padding: "11.5px 14px",
          },
          width: "100%",
        }}
        renderInput={(params) => <TextField error={error} {...params} />}
        value={selectedValue}
        onChange={onChange}
        disableFuture
        {...props}
      />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
};
