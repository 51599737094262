import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box, Stack } from "@mui/material";
import { ReactComponent as AlertTriangle } from "../../Images/AlertTriangle.svg";

const TransactionFailure = ({
  retryAction,
  goBackAction,
  goBackActionText,
  retryActionText,
  error,
}) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
        gap={2}
      >
        <AlertTriangle height={60} width={60} />
        <Typography
          variant="h5"
          fontSize={26}
          textAlign="center"
          color={"#0F0F0F"}
        >
          Transaction failed
        </Typography>
        <Stack
          width={530}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          gap={2.5}
          mt={1.5}
          mb={1.5}
        >
          <Typography variant="body1" color="#0F0F0F" fontSize={17} fontWeight={400}>
            It seems there was an issue processing your transaction. This may
            have occurred due to one of the following reasons:
          </Typography>
          {/* <ul style={{ color: "#86837f", fontSize: "12px", paddingLeft: "20px", margin: 0 }}>
            <li style={{ listStyleType: "disc", listStylePosition: "inside", color: "red" }}> 
              <span style={{ color: "#000" }}>Insufficient funds in your wallet</span>
            </li>
            <li style={{ listStyleType: "disc", listStylePosition: "inside", color: "red" }}>
              <span style={{ color: "#000" }}>Connectivity issues</span>
            </li>
            <li style={{ listStyleType: "disc", listStylePosition: "inside", color: "red" }}>
              <span style={{ color: "#000" }}>Incorrect recipient details</span>
            </li>
          </ul> */}
          <ul
            style={{
              color: "#0F0F0F",
              fontSize: "17px",
              paddingLeft: "20px",
              margin: 0,
            }}
          >
            <li
              style={{
                listStyleType: "disc",
                color: "#0F0F0F",
              }}
            >
              {error?.message?.split(".")?.[0]}
            </li>
          </ul>
          <Typography variant="body1" color="#0F0F0F" fontSize={17}>
            Please review the information and try again. If the issue persists,{" "}
            feel free to contact our support team for further assistance.
          </Typography>
        </Stack>
        <Box display={"flex"} justifyContent={"space-between"} width={530} mt={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={goBackAction}
            sx={{
              borderColor: "#462A9C",
              color: "#462A9C",
              fontSize: "16px",
              padding: '10px 50px',
              textTransform:"capitalize"
            }}
          >
            {goBackActionText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={retryAction}
            sx={{
              backgroundColor: "#462A9C",
              color: "#FFFFF",
              fontSize: "16px",
              padding : '10px 50px',
              textTransform:"capitalize",
            }}
          >
            {retryActionText}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TransactionFailure;
