import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Tooltip,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as MetaMask } from "../../Images/MetaMask.svg";
import { ReactComponent as QrCode } from "../../Images/Qrcode.svg";
import { KeyboardArrowDown } from "@mui/icons-material";
import ConnectWallet from "../../container/DashboardContainer/ConnectWallet";
import ETH from "../../Images/eth.svg";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};
const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));
const SendPaymentMethodForm = ({
  sourceCurrency,
  walletAddress,
  handleCurrencyChange,
  handleGoBack,
  handleNext,
  sourceCurrencies,
  selectedPaymentMethod,
  onPaymentMethodChange,
  networkList,
  handleNetworkChange,
  networkValue,
  IsQRGenerate,
  isQrLoading,
  isConnected
}) => {
  console.log(isQrLoading, 'isQrLoadingisQrLoading')
  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={2}>
      <Typography
        fontSize="16px"
        fontWeight={'bold'}
        fontFamily="PPMori-Semi-Bold"
      >
        Select Payment Method
      </Typography>
      <Paper
        variant="outlined"
        style={{
          backgroundColor: "#FBFAFC",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          borderRadius: "8px",
          padding: "20px",
          border: '1.5px solid #96949C'
        }}
      >
        <Typography
          fontSize="14px"
          marginBottom="10px"
          fontFamily="PPMori-Regular"
        >
          Cryptocurrency
        </Typography>

        <RadioGroup
          value={selectedPaymentMethod}
          onChange={(e) => onPaymentMethodChange(e.target.value)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <FormControlLabel
            value={"CONNECTED_WALLET"}
            control={<Radio style={{ display: "none" }} />}
            disabled={!walletAddress}
            componentsProps={{
              typography: {
                sx: {
                  width: '100%',
                },
              },
            }}
            label={
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"100%"}
                padding={1}
                border={1}
                borderColor={
                  selectedPaymentMethod === "CONNECTED_WALLET"
                    ? "#462A9C"
                    : "rgba(0, 0, 0, 0.12)"
                }
                borderRadius={2}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "PPMori-Regular",
                    gap: 2,
                  }}
                >
                  <Box>
                    <MetaMask />
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      fontSize={12}
                      style={{
                        textTransform: "none",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                      {walletAddress
                        ? "Connected Wallet"
                        : "No wallet connected"}
                    </Typography>
                    {walletAddress && (
                      <Typography
                        fontSize={10}
                        style={{
                          textTransform: "none",
                          fontFamily: "PPMori-Regular",
                        }}
                      >
                        MetaMask{" "}
                        {`(${walletAddress.slice(0, 6)}...${walletAddress.slice(
                          -4
                        )})`}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <ConnectWallet />
              </Box>
            }
            sx={{ margin: 0 }}
          />
          <FormControlLabel
            value={"MANUAL_PAYMENT"}
            control={<Radio style={{ display: "none" }} />}
            componentsProps={{
              typography: {
                sx: {
                  width: '100%',
                },
              },
            }}
            label={
              <Box
                fullWidth
                display={"flex"}
                gap={1}
                sx={{
                  width: "100%",
                }}
                padding={2}
                border={1}
                borderColor={
                  selectedPaymentMethod === "MANUAL_PAYMENT"
                    ? "#462A9C"
                    : "rgba(0, 0, 0, 0.12) "
                }
                borderRadius={2}
              >
                <QrCode />
                <Typography
                  fontSize={12}
                  style={{
                    marginLeft: "10px",
                    fontFamily: "PPMori-Regular",
                  }}
                  variant="body1"
                >
                  QR Code Payment
                </Typography>
              </Box>
            }
            sx={{ margin: 0 }}
          />
        </RadioGroup>
        {(!IsQRGenerate && selectedPaymentMethod === 'MANUAL_PAYMENT' && !isQrLoading) && (
          <Typography color="error" variant="caption">
            QR code payment service is unavailable right now. You can try alternate payment method
          </Typography>
        )}
        {isQrLoading && (<Skeleton variant="rectangular" width={'100%'} height={20} sx={{ backgroundColor: '#f0f0f0' }} />)}
        {(IsQRGenerate && selectedPaymentMethod === 'MANUAL_PAYMENT') && (
          <Typography variant="caption">
            On the final step, you'll find the wallet address. You can either copy this address to initiate the payment manually or scan the provided QR code.
          </Typography>
        )}
      </Paper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography style={{ fontSize: "12px" }}>
          Network{" "}
        </Typography>
        <FormControl
          fullWidth
          variant="outlined"
          style={{
            width: "100%",
            fontSize: "12px",
          }}
        >
          {!networkValue && <InputLabel shrink={false}>Select network</InputLabel>}
          <Select
            id="source-currency"
            value={networkValue}
            onChange={handleNetworkChange}
            sx={{
              width: "100%",
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              }
            }}
            IconComponent={KeyboardArrowDown}
            style={{ fontSize: "12px" }}
          >
            {networkList?.map((currency) => (
              <MenuItem
                key={currency.name}
                value={currency.value}
              >
                {
                  currency.value === "Ethereum" ? (
                    <img src={ETH} width={20} height={20} alt={"ETH"} style={{ marginRight: "10px" }} />
                  ) : null
                }
                {currency.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography style={{ fontSize: "12px" }}>
          Source Currency{" "}
        </Typography>
        <FormControl
          fullWidth
          variant="outlined"
          style={{
            width: "100%",
            fontSize: "12px",
          }}
        >
          {!sourceCurrency && <InputLabel shrink={false}>Select source currency</InputLabel>}
          <Select
            id="source-currency"
            value={sourceCurrency}
            onChange={handleCurrencyChange}
            sx={{
              width: "100%",
            }}
            IconComponent={KeyboardArrowDown}
            endAdornment={
              <Tooltip
                fontFamily="PPMori-Regular"
                title="This is currency that will be deducted from your credit card and converted to transfer to the recipient’s receiving currency."
                arrow
                placement="right"
              >
                <IconButton sx={{ marginRight: "10px" }}>
                  <InfoOutlinedIcon
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            }
            style={{ fontSize: "12px" }}
          >
            {sourceCurrencies?.map((currency) => {
              const countryCode = currency.code ? currency.code.toLowerCase() : 'default';
              const imagePath = images[`${countryCode}.png`];
              return (
                <MenuItem
                  key={currency.code}
                  value={currency.code}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={imagePath}
                    alt={currency.code}
                    style={{
                      width: "15px",
                      marginRight: "8px",
                      verticalAlign: "middle",
                    }}
                  />
                  <span style={{ verticalAlign: "middle" }}>{currency.code}</span>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Box
          display="flex"
          padding="10px"
          borderRadius="4px"
          bgcolor="#f5f5f5"
          border="1px solid #ddd"
          marginTop="10px"
        >
          <InfoOutlinedIcon
            style={{
              width: "20px",
              height: "20px",
              marginRight: "8px",
              color: "#007bff"
            }}
          />
          <span style={{
            fontSize: "14px",
            lineHeight: "1.5",
            color: "#333" 
          }}>
            We support <strong>USDC</strong> and <strong>USDT</strong> on the Ethereum network as of now. Stay tuned, we'll be adding more tokens across multiple blockchains soon!
          </span>
        </Box>
      </Box>
      <Box spacing={2} wrap="noWrap" display={"flex"} gap={1} width={"100%"}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            textTransform: "none",
            borderColor: "#462A9C",
            color: "#462A9C",
            borderRadius: "8px",
            fontFamily: "PPMori-Regular",
            fontSize: "12px",
            width: "100%",
            py: 1,
          }}
          fullWidth
          onClick={handleGoBack}
        >
          Go back
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "none",
            backgroundColor: "#462A9C",
            borderRadius: "8px",
            color: "#ffffff",
            fontSize: "12px",
            fontFamily: "PPMori-Regular",
            width: "100%",
            py: 1,
          }}
          onClick={handleNext}
          disabled={!selectedPaymentMethod || !sourceCurrency || (!IsQRGenerate && selectedPaymentMethod === 'MANUAL_PAYMENT')}
        >
          {" "}
          Next
        </Button>
      </Box>

    </Box>
  );
};

export default SendPaymentMethodForm;
