import React from 'react';
import { Box } from '@mui/material';
import TermsContent from './TermsContent';
const TermsOfUseModal = ({ show, handleClose }) => {
  return (
    <Box show={show} onHide={handleClose} size="lg" centered>
        <TermsContent />
    </Box>
  );
};

export default TermsOfUseModal;
