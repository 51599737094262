// import sever_ENV from "../../../env"
import directService from "../AppService"
import { checkAuthToken } from "../AuthToken"

function getAuthToken() {
    return localStorage.getItem('authToken');
}

const getAllTranscation = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/txn/all`,
                    method: 'POST',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const submitTransaction = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/txn/submit`,
                    method: 'PUT',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const getTranscationById = (id) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/txn/${id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const exportTransaction = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/txn/generate/document`,
                    method: 'POST',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob',
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

export { getAllTranscation, submitTransaction , getTranscationById , exportTransaction }