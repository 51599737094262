import { Box, Stack } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const BaseLayout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: "row" },
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <Navbar />
      <Stack flex={1} sx={{ overflowY: "auto", height: "100%" }}>
        <Outlet />
      </Stack>
    </Box>
  );
};

export default BaseLayout;
