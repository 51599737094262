import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableContainer,
} from '@mui/material';
import BasicPopover from "../Popover/BasicPopover";
import EmptyTableComponent from "./EmptyTableComponent";
import './TableComponent.css';
import dot from "../../Images/dot.png";
import RenderSkeletonRow from "../LoaderUI/TableLoader"
import { formatDate } from '../../lib/service/AuthToken';
import commonHelpers from '../../utils/common.helpers';

const tabledata = {
  title: "Send money",
  recipient: "No transactions yet",
  description:
    "Make a transaction to view the activity here.",
};
const transcationData = [
  "Recipient Name",
  "Transaction ID",
  "Type",
  "Currency",
  "Amount",
  "Payment Method",
  "Date Created",
  "Status"
];

const FilledRowComponent = ({ row }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpen = () => {
    setOpen(!open)
  };


const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));
const imagePath = images[`${(row.sourceCurrency)?.toLowerCase()}.png`];
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell><span style={{ fontSize: '14px' }}>{commonHelpers.capitalizeFirstLetter(`${row.recipientData.firstName} ${row.recipientData.lastName}`)}</span></TableCell>
        <TableCell component="th" scope="row">
          <span style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>{row.txnId}</span>
        </TableCell>
        <TableCell>
          <img src={imagePath} style={{ width: "15px", marginRight: '5px' }} />
            {row.sourceCurrency}
        </TableCell>
        <TableCell className='tablecell'><span style={{ fontSize: '14px', fontFamily: 'PPMori-Regular', fontWeight: 'bold' }}>{Number(row.sourceAmount)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></TableCell>
        <TableCell className='tablecell'><span style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>{formatDate(row.updatedOn)}</span></TableCell>
        <TableCell className='tablecell'>
          <span className={
            row.status == 'INITIATED' || row.status == 'PENDING'
              ? 'status-pending'
              : row.status == 'REJECTED' || row.status == 'FAILED'
                ? 'status-failed'
                : row.status == 'COMPLETE'
                  ? 'status-active'
                  : 'status-active'
          }>{row.status}</span>
        </TableCell>
        <TableCell align="left" className='tablecell'>
          <img src={dot} alt="" className="dot" onClick={handleClick} />
        </TableCell>
        <BasicPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleOpen={handleOpen} rowData={row} />
      </TableRow>
    </>
  );
};

FilledRowComponent.propTypes = {
  row: PropTypes.object.isRequired,
};

const FilledTableComponent = ({ rows, page, rowsPerPage}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Recipient Name</TableCell>
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Transaction ID</TableCell>
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Currency</TableCell>
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Amount</TableCell>
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Date Created</TableCell>
            <TableCell sx={{ backgroundColor: '#F6F5F9', fontWeight: 'bold' }}>Status</TableCell>
            <TableCell sx={{ backgroundColor: '#F6F5F9', fontWeight: 'bold' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <FilledRowComponent key={row.txnId} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

FilledTableComponent.propTypes = {
  rows: PropTypes.array.isRequired,
};

const DashboardTransactionTable = ({ rows, loading, page, rowsPerPage, onNoTransactionYetClick }) => {
  return (
    <>
      {loading ? (
        <RenderSkeletonRow rowHeadingdata={transcationData} />
      ) : rows.length === 0 ? (
        <EmptyTableComponent tabledata={tabledata} rowHeadingdata={transcationData} dashboard={true} onNoTransactionYetClick={onNoTransactionYetClick}/>
      ) :
        (
          <FilledTableComponent rows={rows} loading={loading} page={page} rowsPerPage={rowsPerPage} />
        )
      }
    </>
  );
};

DashboardTransactionTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default DashboardTransactionTable;
