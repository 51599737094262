import React from "react";
import AppHOC from "./AppHOC";
import AppNavigator from "./navigator/";
import "./fonts/PPMori.css";

function App() {
  return (
    <AppHOC>
      <AppNavigator />
    </AppHOC>
  );
}

export default App;
