import RouteConstants from "./RoutesConstants";
import Dashboard from "../pages/Dashboard/Dashboard";
import Accounts from '../container/Account/AccountsContainer';
import AccountDetails from '../container/Account/AccountDetailsContainer';
import TransactionsHistroy from "../container/TranscationHistoryContainer/TranscationHistoryContainer";
import Recipients from "../pages/Recipients/Recipients";
import Profile from '../container/Myprofile/myprofileContainer';
import HelpCenterContainer from '../container/HelpCenter/HelpCenterContainer';
import ContactSupportForm from '../container/HelpCenter/SupportContainer';

const Routes = [
    {
        path: '/',
        component: Dashboard,
        name: RouteConstants.Dashboard,
    },
    {
        path: '/accounts',
        component: Accounts,
        name: RouteConstants.Accounts,
    },
    {
        path: '/account-details/:accountId',
        component: AccountDetails,
        name: RouteConstants.AccountsDetails,
    },
    {
        path: '/transactions',
        component: TransactionsHistroy,
        name: RouteConstants.Transactions,
    },
    {
        path: '/recipients',
        component: Recipients,
        name: RouteConstants.Recipients,
    },
    {
        path: '/myprofile',
        component: Profile,
        name: RouteConstants.Profile,
    },
    {
        path: '/help-center',
        component: HelpCenterContainer,
        name: RouteConstants.HelpCenter,
    },
    {
        path: '/contact-support',
        component: ContactSupportForm,
        name: RouteConstants.ContactUs,
    },
]

export default Routes;