import React from "react";
import { Typography, Button, TextField, Box } from "@mui/material";
import OTP from "../OTP/OTP";
import TermsOfUseModal from "../../container/TermsOfUse/TermsAndCondition";
import PrivacyModal from "../../container/PrivacyComponent/PrivacyComponent";

const TransactionOTPVerifyForm = ({
  otpLength,
  otpExpirationTimer,
  handleResendOTP,
  handleVerifyOTPAndSubmit,
  handleBack,
  buttonTitle,
  isLoading
}) => {
  const [isPrivacyModalOpen, setPrivacyModalOpen] = React.useState(false);
  const [isTermsModalOpen, setTermsModalOpen] = React.useState(false);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      secs < 10 ? "0" + secs : secs
    }`;
  };
  
  const toggleTermsAndConditionModal = () => setTermsModalOpen(!isTermsModalOpen);

  const togglePrivacyPolicyModal = () => setPrivacyModalOpen(!isPrivacyModalOpen);

  return (
    <Box display="flex" flexDirection="column" gap={2} width={"100%"}>
      <Typography
        variant="h5"
        gutterBottom
        style={{
          fontFamily: "PPMori-Regular",
          fontSize: "16px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Verify payment with code from email
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontFamily: "PPMori-Regular",
          fontWeight: "light",
          marginBottom: "30px",
        }}
      >
        To initiate the payment, please enter the OTP code sent to the email
        address.
      </Typography>
      <Box
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <OTP
          otpLength={6}
          onResendOTP={handleResendOTP}
          onVerifyOTP={handleVerifyOTPAndSubmit}
          otpExpirationTimer={otpExpirationTimer}
          isVerifyingOTP={isLoading}
        />
        <Button
          variant="text"
          fullWidth
          onClick={handleBack}
          sx={{
            textTransform: "none",
            fontFamily: "PPMori-Regular",
            border: "1px solid #462A9C",
            color: "#462A9C",
            p: 1,
          }}
        >
          Go back
        </Button>
        <Typography
          variant="caption"
          display="block"
          style={{
            fontFamily: "PPMori-Regular",
            marginTop: "16px",
            fontSize: "10px",
          }}
        >
          By clicking Verify & send payment, you accept the{" "}
          <a
            style={{ color: "black", fontWeight: "bold", cursor: "pointer" }}
            onClick={toggleTermsAndConditionModal}
          >
            Terms & Conditions
          </a>{" "}
          and{" "}
          <a
            style={{ color: "black", fontWeight: "bold", cursor: "pointer" }}
            onClick={togglePrivacyPolicyModal}
          >
            Privacy Policy
          </a>
          , and authorize endl to initiate the transaction detailed above.
        </Typography>
      </Box>
      <TermsOfUseModal open={isTermsModalOpen} handleClose={toggleTermsAndConditionModal} />
      <PrivacyModal open={isPrivacyModalOpen} handleClose={togglePrivacyPolicyModal} />
    </Box>
  );
};

export default TransactionOTPVerifyForm;
