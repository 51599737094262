import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Stack,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SvgIcon from "@mui/material/SvgIcon";
import {
  FilterAltOutlined,
} from '@mui/icons-material';
import TransciationTable from "../../components/TableComponent/FilledRowComponent"
import { ReactComponent as exportfile } from "../../Images/file-export.svg";
import {
  PastDays,
} from "../../components/BasicDateRangePicker/BasicDateRangePicker";
import { ReactComponent as calender } from "../../Images/calendar.svg";
import FilterPayment from "../../components/FilterPayment/FilterPayment";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Notification from '../../components/Notification/Notification';
import Papa from 'papaparse';
import './TranscationHistory.css'
import { formatDate } from '../../lib/service/AuthToken';
import commonHelpers from '../../utils/common.helpers';

const sortByOptionsArray = [
  { value: "createdOn,asc", label: "Date: Ascending" },
  { value: "createdOn,desc", label: "Date: Descending" },
  // { value: "recipientName,asc", label: "Recipient name: A-Z" }, Note:- Commented for future reference
  // { value: "recipientName,desc", label: "Recipient name: Z-A" }, Note:- Commented for future reference
  { value: "sourceAmountRequestedInSourceCurrency,desc", label: "Amount: High to Low" },
  { value: "sourceAmountRequestedInSourceCurrency,asc", label: "Amount: Low to High" },
];

const statusOptionsArray = [
  { value: 'INITIATED', label: 'Initiated' },
  { value: 'IN_REVIEW', label: 'In Review' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'COMPLETE', label: 'Completed' },
  { value: 'REJECTED', label: 'Rejected' },
];


const TranscationHistory = ({
  searchTerm,
  days,
  handleSearch,
  handleDays,
  handleTranscationChange,
  transactionsType,
  handleChangeDatecreated,
  dateCreated,
  rows,
  loading,
  page,
  rowsPerPage,
  totalCount,
  onChangePage,
  onChangeRowsPerPage,
  handleChangeStatus,
  status,
  handleApplyFilters,
  anchorEl,
  handleClick,
  handleDateFilterChange,
  clearAllFilters,
  resetFilters,
  showClearAllButton,
  setPage,
  tempSearchData,
  setTempSearchData,
  onExportCsvClick,
  onExportPdfClick,
}) => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [csvData, setCSVData] = useState([]);
  const [rangeDate, setRangeDate] = useState(null);
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'lg'));

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  // const handleExportCSV = () => {
  //   const csvData = rows.map(row => {

  //     const charges = row.chargesData.reduce((acc, charge) => {
  //       acc[charge.chargeType] = charge.chargeAmount + " " + charge.chargeCurrency;
  //       return acc;
  //     }, {});
  
  //     return {
  //       'Date': formatDate(row.updatedOn) || '',
  //       'Transaction ID': row.txnId,
  //       'Recipient Name': commonHelpers.capitalizeFirstLetter(`${row.recipientData.firstName} ${row.recipientData.lastName}`),
  //       'Bank Name': row.recipientData.bankName || '',
  //       'Bank Account No.': row.recipientData.bankAccountNumber || '',
  //       'IFSC': row.recipientData.ifsc || '',
  //       'SWIFT/BIC': row.recipientData.swiftBic || '',
  //       'Source Amount': row.sourceAmount + " " + row.sourceCurrency,
  //       'Destination Amount': row.destinationAmount + " " + row.destinationCurrency,
  //       'FX Rate': row.fxRate || '',
  //       'Transfer Purpose': row.transferPurpose || '',
  //       'Source of Funds': row.fundsSource || '',
  //       'Flat Fee': charges.FLAT_FEE || '0 USD',
  //       'Variable Fee': charges.VARIABLE_FEE || '0 USD',
  //       'Exchange Markup Fee': charges.EXCHANGE_MARKUP_FEE || '0 USD',
  //       'Tax on Source': charges.TAX_ON_SOURCE || '0 USD',
  //       'Tax on Destination': charges.TAX_ON_DESTINATION || '0 USD',
  //       'Off Ramp Fee': charges.OFF_RAMP_FEE || '0 USD',
  //       'Gas Fee': charges.GAS_FEE || '0 USD',
  //       'Total Charges': row.chargesTotal || '',
  //       'Status': row.status || '',
  //       'Transfer Purpose Code': row.transferPurposeCode || '',
  //       'Attached document(s)': row.invoiceFileUrl || 'No document available',
  //     };
  //   });
  
  //   const csvString = Papa.unparse(csvData);
  //   const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement("a");
  //   if (link.download !== undefined) {
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", 'transaction_history.csv');
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } else {
  //     console.error("Exporting CSV is not supported in this browser.");
  //   }
  
  //   setNotificationOpen(true);
  //   setNotificationMessage(`File "transaction_history.csv" created successfully`);
  // };

  // const handleExportPDF = () => {
  //   const doc = new jsPDF('landscape');

  //   const tableBody = [];

  //   rows.forEach((transaction) => {
  //     const {
  //       txnId,
  //       recipientData: { firstName, lastName, bankName, bankAccountNumber },
  //       sourceAmount,
  //       destinationAmount,
  //       fxRate,
  //       transferPurpose,
  //       createdOn,
  //       chargesTotal,
  //       destinationCurrency,
  //       sourceCurrency,
  //     } = transaction;
  
  //     tableBody.push([
  //       new Date(createdOn).toLocaleString(),
  //       txnId,
  //       `${firstName} ${lastName}`,
  //       bankName,
  //       bankAccountNumber,
  //       `${sourceAmount} ${sourceCurrency}`,
  //       `${destinationAmount} ${destinationCurrency}`,
  //       fxRate,
  //       chargesTotal,
  //       transferPurpose,
  //     ]);
  //   });
  

  //   const tableHeaders = [
  //     "Date", "Transaction ID","Recipient Name", "Bank Name", "Bank Account No.",
  //     "Source Amount", "Destination Amount", "FX Rate", "Total Charges", "Transfer Purpose"];
  
  //   doc.autoTable({
  //     head: [tableHeaders],
  //     body: tableBody,
  //     startY: 20,
  //     theme: 'grid',
  //     styles: {
  //       fontSize: 8,
  //       cellPadding: 2,
  //     },
  //     headStyles: {
  //       fillColor: [100, 100, 100],
  //       textColor: [255, 255, 255],
  //       halign: 'center',
  //       valign: 'middle',
  //       fontSize: 9,
  //     },
  //     margin: { top: 30 }
  //   });
  
  //   const fileName = "transaction_history.pdf";
  //   doc.save(fileName);
  //   setNotificationOpen(true);
  //   setNotificationMessage(`File "${fileName}" created successfully`);
  // };
  

  const updateCSVData = () => {
    const csvData = rows['txns'].map(row => ({
      'Recipient Name': row.recipientName,
      'Transaction Id': row.txnId,
      'Type': row.type,
      'Currency': row.sourceCurrency,
      'Amount': row.sourceAmount,
      'Payment Method': row.paymentMethod,
      'Date Created' : row.updateOn,
      'Status': row.status
    }));
    setCSVData(csvData);
  };

  const handleDateChange = (value) => {
    setRangeDate(value)
    if(handleDateFilterChange){
      handleDateFilterChange(value)
    }
  }

  return (
    <>
      <Box sx={{ mt: 0, mb: 4 }}>
        <Stack
          direction="column"
          spacing={2}
          padding={3}
          sx={{
            backgroundColor: "#F6F5F9",
            padding: { xs: 2, sm: 3 },
          }}
        >
          <Stack
            direction={{ xs: "column", sm:"row", md: "row" }}
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width={"100%"}>
              <Typography
                variant="h3"
                fontSize="26px"
                fontFamily="PPMori-Regular"
                textAlign={"start"}
              >
                Transactions
              </Typography>
            </Box>
            {rows.length ? (
              <Stack
                width={{xs:"100%" , sm:"70%" , md:"70%" , lg:"40%"}}
                direction="row"
                spacing={2}
                justifyContent={{ xs: "flex-start", md: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderColor: "#462A9C",
                    color: "#462A9C",
                    fontFamily: "PPMori-Regular",
                    textTransform: "none",
                  }}
                  fullWidth
                  startIcon={<SvgIcon component={exportfile} />}
                  onClick={onExportPdfClick}
                >
                  Export PDF
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    borderColor: "#462A9C",
                    color: "#462A9C",
                    fontFamily: "PPMori-Regular",
                    textTransform: "none",
                  }}
                  fullWidth
                  startIcon={<SvgIcon component={exportfile} />}
                  onClick={onExportCsvClick}
                >
                  Export CSV
                </Button>
              </Stack>
            ) : null}
          </Stack>
        </Stack>

        <Box sx={{ margin: { xs: "10px", md: "10px 25px" } }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            spacing={{xs:2 ,sm:0 }}
          >
            <TextField
              value={tempSearchData}
              placeholder="Search by keywords..."
              variant="outlined"
              size="small"
              onChange={(e)=>{handleSearch(e) ; setTempSearchData(e.target.value)}}
              style={{ fontFamily: "PPMori-Regular" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "8px",
                      fontFamily: "PPMori-Regular",
                    }}
                    position="start"
                  >
                    <SearchIcon style={{ width: "20px", height: "20px" }} />
                  </InputAdornment>
                ),
                style:{fontSize:"14px"}
              }}
              sx={{ width: { xs: "100%", sm:"20ch" , md:"24ch" }}}
            />
            <Stack direction={{ xs: "column", sm: "row" }} justifyContent={{sm:"flex-end"}} alignItems="center" spacing={2} width={"100%"}>
            <FormControl sx={{ m: 1, minWidth: {xs:"100%",sm: showClearAllButton() ? 100 : 120, md:120} }} size="small">
              <PastDays
                rangeDate={rangeDate}
                setRangeDate={handleDateChange}
                fromTransactionModule={true}
              />
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: {xs:"100%",sm: showClearAllButton() ? 100 : 120, md:120} }} size="small">
              <Select
                id="status-of-transcation"
                value={status}
                displayEmpty
                onChange={handleChangeStatus}
                renderValue={(selected) =>
                  selected
                    ? statusOptionsArray.find((fd) => fd.value === selected)
                        ?.label
                    : "Status"
                }
                inputProps={{style:{fontSize:"14px"}}}
              >
                {statusOptionsArray.map((item) => (
                  <MenuItem
                    value={item.value}
                    style={{ fontSize: "14px", fontFamily: "PPMori-Regular" }}
                    disabled={item.disable}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: {xs:"100%",sm: showClearAllButton() ? 100 : 120, md:120} }} size="small">
              <Select
                id="date-of-transcation"
                value={dateCreated}
                onChange={handleChangeDatecreated}
                displayEmpty
                renderValue={(selected) =>{
                  const selectedItem = sortByOptionsArray.find((fd) => fd.value === selected);
                  // Check if the view is on tablet and truncate the label accordingly
                  return selectedItem 
                    ? isTablet 
                      ? selectedItem.label.slice(0, 7)  // Truncate to 7 characters on tablet
                      : selectedItem.label              // Full label for other screens
                    : "Sort"
                }}
                inputProps={{style:{fontSize:"14px"}}}
              >
                {sortByOptionsArray.map((item) => (
                  <MenuItem
                    value={item.value}
                    style={{ fontSize: "14px", fontFamily: "PPMori-Regular" }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Stack
              direction="row"
              alignItems="center"
              sx={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                cursor: "pointer",
                padding: "5px",
                width: {xs:"100%" , sm: showClearAllButton() ? "90px" : "95px", md:"95px"},
                "&:hover": {
                  borderColor: "rgba(0, 0, 0, 1)",
                },
                alignItems: "center",
              }}
              onClick={handleClick}
            >
              
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", fontFamily: "PPMori-Regular", alignItems: "center",marginLeft:'6px'}}
              >
                Filters
              </Typography>
              <IconButton sx={{ padding : "3px", marginLeft:'4px' }}>
                <FilterAltOutlined fontSize='small'/>
              </IconButton>
            </Stack>

            <FilterPayment
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleApplyFilters={handleApplyFilters}
              resetFilters={resetFilters}
              setPage={setPage}
            />

            {showClearAllButton() && (
              <Typography
                variant="outlined"
                size="small"
                sx={{
                  cursor: "pointer",
                  color: "#462A9C",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textAlign:{sm:"center"},
                  width:{xs:"100%" , sm:"7%"}
                }}
                onClick={() => {
                  setRangeDate(null);
                  clearAllFilters();
                }}
              >
                Clear All
              </Typography>
            )}
            </Stack>
          </Stack>
        </Box>

        <Box
          sx={{
            margin: { xs: "10px", md: "0 25px" },
            flexGrow: 1,
            overflow: "hidden",
            border: 1,
            borderColor: "rgba(0,0,0,0.1)",
            borderRadius: 1,
          }}
        >
          <TransciationTable
            rows={rows}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={totalCount}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </Box>

        <Notification
          open={notificationOpen}
          onClose={handleNotificationClose}
          message={notificationMessage}
        />
      </Box>
    </>
  );
};

export default TranscationHistory;
