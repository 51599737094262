import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({
  element: Component,
  authToken,
}) => {
  return authToken ? <Component /> : <Navigate to="/login" />;
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  authToken: PropTypes.string.isRequired,
}

const PublicRoute = ({
  element: Component,
  authToken,
}) => {
  return authToken ? <Navigate to="/" /> : <Component/>;
};

PublicRoute.propTypes = {
  element: PropTypes.element.isRequired,
  authToken: PropTypes.string.isRequired,
}

export { ProtectedRoute, PublicRoute };