import React from "react";
import { Box, Grid } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import FormSummerySidebar from "../FormSummerySidebar";
import FiatReviewDetails from "./ReviewDetails";
import {
    getQuote,
    showTranscationPurpose,
    invoiceUpload,
} from "../../../../lib/service/DashboardApi/dashboardApi";
import { getAllAccountsList } from "../../../../lib/service/accountsApi/accountsApi";
import { submitDeposit } from "../../../../lib/service/depositApi/depositApi";
import { submitTransaction } from "../../../../lib/service/TranscationApi/transcationApi";
import LoadingTxn from "../../../LoaderUI/LoadingTxn";
import TransactionSuccess from "../../../Alerts/TransactionSuccess";
import TransactionFailure from "../../../Alerts/TransactionFailure";
import SendMoneyTransferAmountForm from "../../../Forms/SendMoneyTransferAmountFrom";
import SendFiatPaymentMethodForm from "../../../Forms/SendFiatPaymentMethodForm";
import TransactionOTPVerifyForm from "../../../Forms/TransactionVerifyOTPForm";
import TopUpModal from "../../TopUpModal/TopUpModal";
import { useProfile } from "../../../../context/ProfileContext";
import _debounce from "lodash.debounce";
import { sendOTP, verifyOTP } from "../../../../lib/service/otpAPi/otpApi";
import { notify } from "../../../Notification/Notification";
const FiatToFiat = ({
    handleGoBack,
    handleNext,
    selectedPaymentType,
    selectedRecipient,
    step,
    addNewTransaction,
    onClose,
    errorTxn,
    successTxn,
    setErrorTxn,
    setSuccessTxn
}) => {
    const { profile } = useProfile();
    const [sourceCurrency, setSourceCurrency] = useState("");
    const [receiveCurrency, setReceiveCurrency] = useState(selectedRecipient?.currency);
    const [transaction, setTransaction] = useState({});
    const [exchangeRate, setExchangeRate] = useState(null);
    const [receiveAmount, setReceiveAmount] = useState("");
    const [chargeTotal, setchargeTotal] = useState(0);
    const [totalFeesCurrency, setTotalFeesCurrency] = useState(0);
    const [totalFeesAmount, setTotalFeesAmount] = useState(0);
    const [destinationCurrencies, setDestinationCurrencies] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [sendAmount, setSendAmount] = useState();
    const [sourceCurrencies, setSourceCurrencies] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('ENDL_ACCOUNT')
    const [isLoading, setIsLoading] = useState(false);
    const [chargesFee, setChargesFees] = useState([]);
    const [showReason, setReason] = useState("");
    const [description, setDescription] = useState("");
    const [isQrLoading, setQrLoading] = useState("");
    const [transactionPurpose, setTransactionPurpose] = useState("");
    const [invoiceFileIdSave, setinvoiceFileIdSave] = useState("");
    const [showAmountError, setAmountError] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [txnError, setTxnError] = useState('');
    const [isReasonMandatory, setIsResonMandatory] = useState(false);
    const [isQuoteExpired, setIsQuoteExpired] = useState(false);
    const [expiresOn, setExpiresOn] = useState(null);
    const [accountsList, setAccountsList] = useState([]);
    const [accountLoader, setAccountLoader] = useState(false);
    const [otp, setOtp] = useState(Array(6).fill(""));
    const [otpExpirationTimer, setOtpExpirationTimer] = useState(15 * 60);
    const [topUpModalOpen, setTopUpModalOpen] = useState(false);
    const [isVerifyingOTP, setIsVerifyingOTP] = useState();
    const [amountToConvert , setAmountToConvert] = useState("");
    const [otpVerified, setOtpVerified ] = useState(false);
    useEffect(() => {
        let expirationInterval;
        if (otpExpirationTimer > 0) {
            expirationInterval = setInterval(() => {
                setOtpExpirationTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(expirationInterval);
        };
    }, [otpExpirationTimer]);

    const sendOtpToEmail = useCallback(async () => {
        try {
            await sendOTP({
                email: profile.email, phone: null, otpMode: 'EMAIL', operationType: 'TRANSACTION'
            });
            setOtpExpirationTimer(15 * 60);
        } catch (error) {
            console.error("Failed to send OTP:", error);
        }
    }, []);

    const handleResendOTP = useCallback(() => {
        sendOtpToEmail();
    }, [otpExpirationTimer]);

    const handleOtpChange = useCallback(
        (index) => (event) => {
            const newOtp = [...otp];
            newOtp[index] = event.target.value;
            setOtp(newOtp);
            if (event.target.nextSibling && event.target.value) {
                event.target.nextSibling.focus();
            }
        },
        [otp]
    );
    const handleVerifyOTP = async (otp) => {
        try {
            setIsVerifyingOTP(true);
            const varify = await verifyOTP({
                otp: otp,
                email: profile.email,
                phone: null,
                otpMode: 'EMAIL'
            });
            setOtpVerified(true);
            handleSubmit()
        } catch (e) {

            if (e?.errors?.length > 0) {
                e.errors.map((err) =>
                    notify.error({
                        message: err.errMsg,
                        description: err.errDesc,
                    })
                );
            } else {
                notify.error({
                    message: "Failed to verify the OTP.",
                    description:
                        "We can not proceed the request for email verification. Please try again later",
                });
            }
        } finally {
            setIsVerifyingOTP(false);
        }
    };
    useEffect(() => {
        const fetchTransactionPurpose = async () => {
            try {
                const response = await showTranscationPurpose();
                setTransactionPurpose(response.data);
            } catch (error) {
                console.error("Error fetching transaction purpose:", error);
            }
        };

        const fetchAccountsList = async () => {
            setAccountLoader(true);
            try {
                const response = await getAllAccountsList();
                setAccountsList(response.data.accounts);
                setAccountLoader(false);
            } catch (error) {
                setAccountLoader(false);
                console.error("Error fetching accounts list:", error);
            }
        };

        fetchTransactionPurpose();
        fetchAccountsList();
    }, []);


    const handleSubmit = async () => {
        let number = Number(sendAmount.replaceAll(",", ""));
        setIsLoading(true);
        try {
            let response;
            if (selectedFile) {
                response = await invoiceUpload(selectedFile);
            }
            const confirmationId = `txn_${Math.random().toString(36).substr(2, 9)}_${Date.now()}`;
            const depositData = {
                depositType: 'ENDL_ACCOUNT',
                confirmationId: confirmationId,
                amountRequested: number,
                currencyRequested: sourceCurrency,
            };
            const res = await submitDeposit(depositData);
            const deposit = res.data;
            const txnData = {
                nameOrAlias: "Test Deposit 1",
                userId: deposit.userId,
                quoteId: transaction.quoteId,
                depositId: deposit.depositId,
                transferPurpose: description,
                transferPurposeCode: showReason,
                recipientId: selectedRecipient.id,
                fundsSource: "SALARY",
                endlTransactionMode: "FIAT_TO_FIAT",
                invoiceFileId: response?.data?.invoiceFileId || "",
                otpVerified: otpVerified
            };
            await submitTransaction(txnData);
            addNewTransaction();
            setSuccessTxn(true);
            setIsLoading(false);
        } catch (error) {
            setErrorTxn(true);
            setTxnError(error)
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleReceiveCurrencyChange = useCallback((event) => {
        setReceiveCurrency(event.target.value);
    }, []);

    const fetchQuote = _debounce(
        async ({ sourceCurrency, sourceAmount, receiveCurrency, receiveAmount }) => {
            setIsFetching(true);
            try {
                const payload = {
                    partnerId: "1112",
                    depositType: 'ENDL_ACCOUNT',
                    accountType: "INDIVIDUAL",
                    source: {
                        currency: sourceCurrency,
                    },
                    destination: {
                        currency: receiveCurrency,
                    },
                    recipientReceiveMode: selectedRecipient?.paymentRail || "SWIFT_IN",
                }
                if (sourceAmount) {
                    payload.source.amount = sourceAmount;
                }
                if (receiveAmount) {
                    payload.destination.amount = receiveAmount;
                }

                const response = await getQuote(payload);
                if (response?.code === 200) {
                    const { source, destination, fxRate, feeList, feeTotal, feeCurrency } =
                        response.data;

                    setTransaction(response.data);
                    setExchangeRate(response.data.fxRate);

                    setChargesFees(feeList);
                    setTotalFeesAmount(destination.amountWithoutFeesIncluded);
                    setExpiresOn(new Date(response.data.expiresOn));
                    setchargeTotal(feeTotal);
                    setTotalFeesCurrency(feeCurrency)
                    setAmountError("");
                    setSendAmount(Number(parseFloat(source?.amount)?.toFixed(2))?.toLocaleString());
                    setReceiveAmount(Number(parseFloat(destination?.amount)?.toFixed(2))?.toLocaleString());
                    setAmountToConvert(source?.amountToConvert)
                } else {
                    setTransaction({})
                    setExchangeRate(0);
                    setChargesFees([]);
                    setchargeTotal(0);
                    setExpiresOn(null)
                    setTotalFeesCurrency('')
                    setAmountError(response.errors[0]['errDesc'])
                    setAmountToConvert('')
                }
            } catch (error) {
                console.error("Error fetching quote:", error);
                if (error && error.data) {
                    const { source, destination } = error?.data;
                    if(source?.amount === null){
                        setSendAmount('')
                      }else{
                        setSendAmount(Number(parseFloat(source?.amount)?.toFixed(2))?.toLocaleString());
                      }
                      if(destination?.amount === null){
                        setReceiveAmount("")
                      }else{
                        setReceiveAmount(Number(parseFloat(destination?.amount)?.toFixed(2))?.toLocaleString());
                      }
                }
                setTransaction({});
                setExchangeRate(0);
                setChargesFees(0);
                setTotalFeesAmount(0);
                setchargeTotal(0);
                setExpiresOn(null)
                setTotalFeesCurrency('')
                setAmountError(error?.errors?.[0]?.['errDesc'])
                setAmountToConvert('')
            } finally {
                setIsFetching(false);
            }
        }, 1200
    )

    useEffect(() => {
        const checkExpiration = () => {
            if (expiresOn) {
                setIsQuoteExpired(new Date() > expiresOn);
            }
        };

        const interval = setInterval(checkExpiration, 1000);
        return () => clearInterval(interval);
    }, [expiresOn]);

    const handleSendAmountChange = useCallback(
      (amount) => {
        setSendAmount(amount);
        setReceiveAmount("");
        setTransaction({});
        setExchangeRate(0);
        setChargesFees([]);
        setchargeTotal(0);
        setAmountError("");
        setTotalFeesCurrency("");
        setExpiresOn(null);
        fetchQuote({
          sourceCurrency,
          sourceAmount: Number(amount?.split(',')?.join('')),
          receiveCurrency,
          receiveAmount: "",
        });
      },
      [
        sourceCurrency,
        receiveCurrency,
        setReceiveAmount,
        setTransaction,
        setExchangeRate,
        setChargesFees,
        setchargeTotal,
        setAmountError,
        setTotalFeesCurrency,
      ]
    );

    const handleReceiveAmountChange = useCallback(
      (amount) => {
        setReceiveAmount(amount);
        setSendAmount("");
        setTransaction({});
        setExchangeRate(0);
        setChargesFees([]);
        setchargeTotal(0);
        setAmountError("");
        setTotalFeesCurrency("");
        setExpiresOn(null);
        fetchQuote({
          sourceCurrency,
          sourceAmount: "",
          receiveCurrency,
          receiveAmount: Number(amount?.split(',')?.join('')),
        });
      },
      [
        sourceCurrency,
        receiveCurrency,
        setSendAmount,
        setTransaction,
        setExchangeRate,
        setChargesFees,
        setchargeTotal,
        setAmountError,
        setTotalFeesCurrency,
      ]
    );

    const handleSendOTP = async () => {
        handleNext()
        sendOtpToEmail()
    };

    const handleCurrencyChange = useCallback((event) => {
        setSourceCurrency(event.target.value);
        setSendAmount(null);
        setReceiveAmount("");
        setTransaction({});
        setExchangeRate(0);
        setChargesFees([]);
        setchargeTotal(0);
        setTotalFeesCurrency("");
        setAmountError("");
    }, [setSourceCurrency, setSendAmount, setReceiveAmount, setTransaction, setExchangeRate, setChargesFees, setchargeTotal, setTotalFeesCurrency, setAmountError]);

    const handleSelectChange = (event, newValue) => {
        const selectedCode = newValue ? newValue.code : ""; 
        const selectedItem = transactionPurpose.codes.find((i) => i.code === selectedCode);
        const isReasonRequired = selectedItem?.invoiceMandatory || false;
        setReason(selectedCode);
        setDescription(selectedItem?.description || "");
        setIsResonMandatory(isReasonRequired);

    };

    const handleSendMoneyBackBtn = () => {
        setReceiveAmount("");
        setSendAmount('');
        setTransaction({})
        setExchangeRate(0);
        setChargesFees([]);
        setchargeTotal(0);
        setTotalFeesCurrency('')
        setAmountError("");
        handleGoBack();
        setReason("");
        setExpiresOn(null)
        setSelectedFile(null);
    }

    const handleOpenTop = () => {
        setTopUpModalOpen(true)
    }

    const isValidateInvoice = () => {
        if (selectedRecipient?.paymentRail === "SWIFT" && selectedRecipient?.relationshipToCustomer !== "SELF") {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            {step === 1 && (
                <Grid container py={5} justifyContent={{ xs: "center", sm: "center", lg: "flex-start" }}>
                    <Grid item xs={12} sm={12} lg={4} p={{ xs: "0px 20px", sm: "0px" }}>
                        <FormSummerySidebar
                            title={"Payment method"}
                            showSummery
                            showStepOneSummery
                            selectedPaymentType={selectedPaymentType}
                            selectedRecipient={selectedRecipient}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} p={{ xs: "0px 20px", sm: "0px" }}>
                        <SendFiatPaymentMethodForm
                            sourceCurrency={sourceCurrency}
                            handleCurrencyChange={handleCurrencyChange}
                            handleGoBack={handleGoBack}
                            handleNext={handleNext}
                            sourceCurrencies={sourceCurrencies}
                            accountsList={accountsList}
                            selectedPaymentMethod={selectedPaymentMethod}
                            handleOpenTop={handleOpenTop}
                            accountLoader={accountLoader}
                        />
                    </Grid>
                </Grid>
            )}
            {step === 2 && (
                <Box
                    padding={{ xs: 3, md: 5 }}
                    display={"flex"}
                    style={{ maxHeight: "100vh", overflow: "auto" }}
                    flexDirection={{ xs: "column", sm: "column", lg: "row" }}
                >
                    <FormSummerySidebar
                        title={"Transfer amount"}
                        showSummery
                        showStepOneSummery
                        selectedPaymentType={selectedPaymentType}
                        selectedRecipient={selectedRecipient}
                        showStepTwoSummery
                        sourceCurrency={sourceCurrency}
                        width={{ lg: "30%" }}
                    />
                    <Box mt={2} flexGrow={1}>
                        <SendMoneyTransferAmountForm
                            sourceCurrency={sourceCurrency}
                            destinationCurrencies={destinationCurrencies}
                            receiveCurrency={receiveCurrency}
                            receiveAmount={receiveAmount}
                            totalFeesAmount={totalFeesAmount}
                            chargesFee={chargesFee}
                            exchangeRate={exchangeRate}
                            sendAmount={sendAmount}
                            handleReceiveCurrencyChange={handleReceiveCurrencyChange}
                            handleSendAmountChange={handleSendAmountChange}
                            isFetching={isFetching}
                            handleGoBack={handleSendMoneyBackBtn}
                            handleNext={handleNext}
                            chargeTotal={chargeTotal}
                            showReason={showReason}
                            handleSelectChange={handleSelectChange}
                            transactionPurpose={transactionPurpose}
                            setinvoiceFileIdSave={setinvoiceFileIdSave}
                            showAmountError={showAmountError}
                            setSelectedFile={setSelectedFile}
                            selectedFile={selectedFile}
                            isReasonMandatory={isReasonMandatory}
                            selectedRecipient={selectedRecipient?.institutionalAddress?.countryThreeLetter}
                            receiveCurrencyValue={selectedRecipient?.institutionalAddress?.country}
                            setSearchTerm={setSearchTerm}
                            searchTerm={searchTerm}
                            onReceiveAmountChange={handleReceiveAmountChange}
                            totalFeesCurrency={totalFeesCurrency}
                            isQuoteExpired={isQuoteExpired}
                            expiresOn={expiresOn}
                            isValidateInvoice={selectedRecipient?.paymentRail === "SWIFT" && selectedRecipient?.relationshipToCustomer !== "SELF"}
                            amountToConvert={amountToConvert}
                        />
                    </Box>
                </Box>
            )}
            {step === 3 && (
                <div style={{ maxHeight: "100vh", overflow: "auto" }}>
                    <FiatReviewDetails
                        sendAmount={sendAmount}
                        chargesFee={chargesFee}
                        sourceCurrency={sourceCurrency}
                        handleGoBack={handleGoBack}
                        isFetching={isFetching}
                        receiveAmount={receiveAmount}
                        receiveCurrency={receiveCurrency}
                        selectedPaymentType={selectedPaymentType}
                        selectedRecipient={selectedRecipient}
                        chargeTotal={chargeTotal}
                        handleNext={handleSendOTP}
                        totalFeesCurrency={totalFeesCurrency}
                        isQuoteExpired={isQuoteExpired}
                        expiresOn={expiresOn}
                        attachedFile={selectedFile}
                        exchangeRate={exchangeRate}
                        amountToConvert={amountToConvert}
                    />
                </div>
            )}
            {step === 4 &&
                (isLoading ? (
                    <Box flexGrow={1}>
                        <LoadingTxn message={"Waiting for fiat..."} title1={'All done. We are now waiting to receive your fiat currency.'} title2={'This typically takes only a few minutes.'} />
                    </Box>
                ) : successTxn ? (
                    <Box flexGrow={1}>
                        <TransactionSuccess
                            title={"Transaction Initiated"}
                            message={
                                "Your fiat currency has been received and is currently being processed for the recipient. We’ll notify you once the transfer is done."
                            }
                            actionButtonText={"Go to dashboard"}
                            buttonAction={() => window.location.reload()}
                        />
                    </Box>
                ) : errorTxn ? (
                    <Box flexGrow={1}>
                        <TransactionFailure
                            goBackAction={onClose}
                            retryAction={() => { }}
                            retryActionText={"Retry Transaction"}
                            goBackActionText={"Go to Dashboard"}
                            error={txnError}
                        />
                    </Box>
                ) :

                    <Box padding={5} display={"flex"}>
                        <FormSummerySidebar title={"Review & Pay"} />
                        <TransactionOTPVerifyForm
                            otpExpirationTimer={2 * 60}
                            handleResendOTP={handleResendOTP}
                            handleVerifyOTPAndSubmit={handleVerifyOTP}
                            handleBack={handleGoBack}
                            goBackToDashBoard={onClose}
                            buttonTitle={"Verify & pay with wallet"}
                            isLoading={isLoading}
                        />
                        <Box width={"100%"}></Box>
                    </Box>)}
            {topUpModalOpen && (
                <TopUpModal
                    open={topUpModalOpen}
                    onClose={() => setTopUpModalOpen(false)}
                    accountDetails={accountsList?.length ? accountsList[0] : accountsList}
                />
            )}
        </>
    );
};

export default FiatToFiat;
