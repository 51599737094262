import React from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { ProfileProvider } from "./context/ProfileContext";
import { ActiveStepProvider } from "./container/ActiveStepContext";
import { WalletProvider } from "./context/WalletContext";
import ErrorBoundary from "./layouts/ErrorBoundary/ErrorBoundary";
import theme from './theme/Theme';

const AppHOC = ({ children }) => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-right" containerStyle={{ zIndex: 9999999 }} />
        <ProfileProvider>
          <ActiveStepProvider>
            <WalletProvider>
              <ErrorBoundary>{children}</ErrorBoundary>
            </WalletProvider>
          </ActiveStepProvider>
        </ProfileProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default AppHOC;