import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  Tooltip,
  Modal,
} from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { notify } from "../../../../components/Notification/Notification";
import PdfViewer from "./PdfViewer";
import { AttachFile } from "@mui/icons-material";

const ManualPaymentReviewDetails = ({
  sendAmount,
  sourceCurrency,
  receiveAmount,
  receiveCurrency,
  selectedRecipient,
  chargeTotal,
  handleGoBack,
  submitMaunalTranscation,
  depositAddress,
  qrCodeImage,
  handleManualPayment,
  chargesFee,
  totalFeesCurrency,
  attachedFile = {},
  isQuoteExpired,
  expiresOn,
  exchangeRate,
  selectedPaymentType,
  amountToConvert,
}) => {
console.log((receiveAmount), 'receiveAmountreceiveAmount')
  const [timeLeft, setTimeLeft] = useState(600);
  const [notified, setNotified] = useState(false);
  const [remainingTime, setRemainingTime] = useState("");
  const [open, setIsOpen] = useState(false);

  useEffect(() => {
    if (expiresOn) {
      const expirationDate = new Date(expiresOn);
      const totalDuration = expirationDate - new Date();

      const updateRemainingTime = () => {
        const now = new Date();
        const timeDiff = expirationDate - now;
        if (timeDiff <= 0) {
          setRemainingTime("Expired");
          isQuoteExpired = true;
        } else {
          const minutes = Math.floor(timeDiff / 1000 / 60);
          const seconds = Math.floor((timeDiff / 1000) % 60);
          setRemainingTime(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
        }
      };

      updateRemainingTime();
      const timerInterval = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [expiresOn, isQuoteExpired]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          handleGoBack();
          return 0;
        }

        if (prevTime <= 2 && !notified) {
          notify.success({
            message: "Time is almost up! Please reinstate the payment.",
          });
          setNotified(true);
        }

        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [handleGoBack]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        notify.success({
          message: `Copied ${text}`,
        });
        console.log("Text copied to clipboard");
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  const toggleIsOpen = () => {
    setIsOpen(!open);
  };

  return (
    <Grid container p={{ xs: 3, md: 5 }} justifyContent={{ xs: "center", lg: "flex-start" }}>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Box display={"flex"} justifyContent={"center"}>
          <Box display={"flex"} width={{ xs: "100%", sm: "50%", lg: "60%" }} gap={2} flexDirection={"column"}>
            <Typography variant="h5" fontWeight={600}>
              Review & pay
            </Typography>
            <Typography fontSize={14} color="error.main">
              Your quotation expires in <span fontWeight={'bold'}>{remainingTime}</span>.
            </Typography>
            {/* <Typography fontSize={14} textAlign={{ xs: "end", md: "start" }}>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {remainingTime}{" "}
              </span>{" "}
              remaining
            </Typography> */}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={2}>
          <Typography fontWeight={600} textAlign={'center'}>
            Scan QR code
          </Typography>
          <Box display={"flex"} gap={2} flexDirection={"column"} alignItems={"center"}>
            <Box
              border={1}
              borderRadius={1}
              borderColor={"rgba(0,0,0,0.4)"}
              p={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={200}
              height={200}
            >
              {qrCodeImage ? (
                <img
                  width={"100%"}
                  height={"100%"}
                  src={qrCodeImage}
                  alt="QR Code"
                />
              ) : (
                <Typography>Loading...</Typography>
              )}
            </Box>
            <Typography fontSize={14}>Scan this QR code using your wallet app.</Typography>
          </Box>
          <Divider />
          <Typography fontSize={14} fontWeight={600}>
            Or copy details and pay with any wallet
          </Typography>
          <Box>
            <Typography fontSize={14}>Send amount</Typography>
            <Box
              border={1}
              borderRadius={1}
              borderColor={"rgba(0,0,0,0.4)"}
              p={1}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography>
                {(sendAmount)?.toLocaleString()} {sourceCurrency}
              </Typography>
              <IconButton
                onClick={() =>
                  copyToClipboard(`${sendAmount} ${sourceCurrency}`)
                }
              >
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Typography fontSize={14}>Send to address</Typography>
            <Box
              border={1}
              borderRadius={1}
              borderColor={"rgba(0,0,0,0.4)"}
              p={1}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography width={{ xs: "90%", md: "100%" }} overflow={{ xs: "hidden" }}>{depositAddress}</Typography>
              <IconButton onClick={() => copyToClipboard(depositAddress)}>
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />

          {/* Total fee with Tooltip for detailed fee breakdown */}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography color={"rgba(0,0,0,0.60)"} fontSize={14} fontWeight={'bold'}>
                Total fees
              </Typography>
              {chargesFee?.length ? (
                <Tooltip
                  title={
                    <Box>
                      {chargesFee?.map((fee, index) => (
                        <Box key={index} display="flex" justifyContent="space-between" gap={1}>
                          <Typography fontSize={12}>{fee.feeName}</Typography>
                          <Typography fontSize={12}>{fee.feeAmount} {fee.feeCurrency}</Typography>
                        </Box>
                      ))}
                    </Box>
                  }
                  arrow
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              ) : null}
            </Box>
            <Typography fontSize={14}>{chargeTotal} {totalFeesCurrency}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography color={"rgba(0,0,0,0.60)"} fontSize={14}>
              Exchange rate
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: "PPMori-Regular",
              }}
            >

              {exchangeRate ? (<>
                1 {sourceCurrency} = {exchangeRate} {receiveCurrency}</>) : 0}

            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography color={"rgba(0,0,0,0.60)"} fontSize={14}>
              Total Amount we'll Convert
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: "PPMori-Regular",
              }}
            >
              {Number(amountToConvert)?.toLocaleString()} {sourceCurrency}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography color={"rgba(0,0,0,0.60)"} fontSize={14}>
              Recipient gets
            </Typography>
            <Typography fontWeight={600} fontSize={18}>
              {(receiveAmount)?.toLocaleString()} {receiveCurrency}
            </Typography>
          </Box>
          <Divider />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              variant="body1"
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
              fontSize={14}
            >
              Recipient
            </Typography>
            <Typography
              variant="body1"
              fontFamily="PPMori-Regular"
              fontSize={14}
            >
              {selectedRecipient?.name}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
              fontSize={14}
            >
              To bank account
            </Typography>
            <Typography
              fontFamily="PPMori-Regular"
              variant="body1"
              fontSize={14}
            >
              {selectedRecipient?.account_number_external ?
                `${selectedRecipient?.name}...${selectedRecipient?.account_number_external?.slice(-4)}` :
                `${selectedRecipient?.bankName}...${selectedRecipient?.bankAccountNumber?.slice(-4)}`
              }
            </Typography>
          </Box>
          <Divider />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
              fontSize={14}
            >
              Payment method
            </Typography>
            <Typography
              fontFamily="PPMori-Regular"
              variant="body1"
              fontSize={14}
            >
              QR Code payment
            </Typography>
          </Box>
          <Divider />
          {/* Attached Document */}
          {selectedPaymentType === "cryptoToFiat" && (
          <Box display={"flex"} justifyContent={"space-between"} alignItems="center" mb={2}>
            <Typography
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
              fontSize={14}
            >Attached document</Typography>
            {attachedFile?.name ?
              <Button
                startIcon={attachedFile?.name && (<AttachFile />)}
                variant="text"
                onClick={toggleIsOpen}
                sx={{ textDecoration: attachedFile?.name ? "underline" : 'none' }}
              >
                {attachedFile?.name?.length > 20 ? `${attachedFile?.name?.slice(0, 20)}` + `...` : attachedFile?.name}
              </Button> : <Typography variant="body1" fontFamily="PPMori-Regular">
                N/A
              </Typography>}
          </Box>)}
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
              backgroundColor: "#462A9C",
              ":hover": {
                backgroundColor: "#462A9C",
              },
            }}
            onClick={handleManualPayment}
          >
            Finish, I've paid
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: "none",
              borderColor: "#462A9C",
              color: "#462A9C",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
              mt: 1,
            }}
            onClick={handleGoBack}
          >
            Go back
          </Button>
        </Box>
        {/* Modal for attached document */}
        <Modal open={open} onClose={toggleIsOpen}>
          <Box
            width={"80%"}
            height={"80%"}
            display="flex"
            flexDirection="column"
            bgcolor="background.paper"
            borderRadius={3}
            boxShadow={5}
            mx="auto"
            my="15vh"
            p={2}
            outline="none"
          >
            <PdfViewer file={attachedFile} />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default ManualPaymentReviewDetails;
