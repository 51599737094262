import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notify } from "../../components/Notification/Notification";
import { sendOTP, verifyOTP } from "../../lib/service/otpAPi/otpApi";
import TwoFALogin from "../../pages/LoginAndSignUp/TwoFALogin";
import { setAuthToken } from "../../lib/service/AuthToken";

const TwoFALoginContainer = () => {
  const location = useLocation();
  const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.userCredentials &&
      location.userCredentials.email
    ) {
      handleSendOTP();
    } else {
      notify.error({
        message: "No user credential found. Please try login again.",
      });
    }
  }, [location, location.state]);
  const handleSendOTP = async () => {
    try {
      await sendOTP({
        email: location.userCredentials.email,
        operation_type: "twoFactor",
      });
      notify.success({
        message: "OTP has been sent successfully",
        description:
          "We have sent the OTP to your " +
          location.state.userCredentials.email,
      });
    } catch (e) {
      if (e?.errors?.length > 0) {
        e.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to send the OTP.",
          description:
            "We can not proceed the request for verification of email. Please try again later.",
        });
      }
    }
  };
  const handleVerifyAndLogin = async (otp) => {
    try {
      setIsVerifyingOTP(true);
      const res = await verifyOTP({
        otp: otp,
        email: location.userCredentials.email,
      });
      const authToken = res.data?.token?.token;
      const expiryDate = res.data?.token?.expirydate;
      if (authToken && expiryDate) {
        setAuthToken(authToken, expiryDate);
        notify.success({
          message: "You are successfully logged in.",
        });

        navigate("/");
      }
    } catch (e) {
      if (e?.errors?.length > 0) {
        e.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to verify the OTP.",
          description:
            "We can not proceed the request for email verification. Please try again later",
        });
      }
    } finally {
      setIsVerifyingOTP(false);
    }
  };
  const handleBack = () => {
    navigate("/login");
  };
  return (
    <TwoFALogin
      handleBack={handleBack}
      handleResend={handleSendOTP}
      handleVerifyAndLogin={handleVerifyAndLogin}
      isVerifyingOTP={isVerifyingOTP}
    />
  );
};

export default TwoFALoginContainer;
