import React, { useState, useCallback } from "react";
import { Stepper, Step, StepLabel, styled, StepConnector } from "@mui/material";
import Check from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Custom connector with black color
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor: "#E6E5EA",
    borderTopWidth: 2,
  },
}));

// Custom step icon
const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...(ownerState.active && {
    color: "#000000",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#fff",
    backgroundColor: "#000000",
    borderRadius: "50%",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 24,
    height: 24,
    borderRadius: "50%",
    border: `2px solid ${ownerState.error ? '#ff0000' : '#000000'}`, // Red border if error
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ownerState.error ? '#ff0000' : 'transparent', // Red background if error
  },
  "& .QontoStepIcon-innerCircle": {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: ownerState.active ? "#000000" : "#fff",
  },
}));

function CustomStepIcon(props) {
  const { active, completed, className, errorTxn, index } = props;

  const isErrorStep = errorTxn && index === 3; 

  return (
    <CustomStepIconRoot ownerState={{ active, completed, error: isErrorStep }} className={className}>
      {isErrorStep ? (
        <div className="QontoStepIcon-circle">
          <InfoOutlinedIcon style={{ color: "#ffffff", fontSize: 18 }} />
        </div>
      ) : completed ? (
        <div className="QontoStepIcon-completedIcon">
          <Check />
        </div>
      ) : active ? (
        <div className="QontoStepIcon-circle">
          <div className="QontoStepIcon-innerCircle" />
        </div>
      ) : (
        <div className="QontoStepIcon-circle">
          <div className="QontoStepIcon-innerCircle" />
        </div>
      )}
    </CustomStepIconRoot>
  );
}

const StepperUI = ({ steps, step, successTxn, errorTxn }) => {
  return (
    <Stepper activeStep={step} alternativeLabel connector={<QontoConnector />}>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(props) => (
              <CustomStepIcon
                {...props}
                errorTxn={errorTxn}
                index={index} 
              />
            )}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperUI;
