import * as React from "react";
import { MenuItem, FormControl, Box } from "@mui/material";
import { useState, useEffect } from "react";
import DateCalendarValue from "../Calender/NewCalender";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import Popover from "@mui/material/Popover";
import DateRangeIcon from '@mui/icons-material/DateRange';

export function PastDays({ rangeDate, setRangeDate, fromTransactionModule = false }) {
  const [pastDays, setPastDays] = useState("ALL_TIME");
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempDateRange, setTempDateRange] = useState("ALL_TIME");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!rangeDate) {
      setPastDays("ALL_TIME");
      setTempDateRange([dayjs().startOf("year").subtract(1, "day"), dayjs()]);
    } else {
      let currentCase = pastDays;

      if (fromTransactionModule && pastDays === "PAST_7_DAYS") {
        currentCase = "LAST_7_DAYS";
      }
      switch (currentCase) {
        case "RESET":
          setTempDateRange("ALL_TIME");
          break;
        case "ALL_TIME":
          setTempDateRange([dayjs().startOf("year").subtract(1, "day"), dayjs()]);
          break;
        case "YESTERDAY":
          setTempDateRange([dayjs().subtract(1, "day"), dayjs().subtract(1, "day")]);
          break;
        case "PAST_7_DAYS":
          setTempDateRange([dayjs().subtract(7, "day"), dayjs()])
          break;
        case "LAST_7_DAYS":
          setTempDateRange([dayjs().subtract(7, "day"), dayjs()])
          break;
        case "PAST_30_DAYS":
          setTempDateRange([dayjs().subtract(30, "day"), dayjs()]);
          break;
        case "PAST_90_DAYS":
          setTempDateRange([dayjs().subtract(90, "day"), dayjs()]);
          break;
        default:
          setTempDateRange(pastDays);
          break;
      }
    }
  }, [rangeDate])

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDayRange = (value) => {
    switch (value) {
      case "RESET":
        setPastDays("ALL_TIME");
        setRangeDate("ALL_TIME");
        break;
      case "ALL_TIME":
        setPastDays("ALL_TIME");
        setRangeDate(fromTransactionModule ? value : null);
        break;
      case "YESTERDAY":
        setPastDays("YESTERDAY");
        setRangeDate(fromTransactionModule ? "YESTERDAY" : [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")]);
        break;
      case "PAST_7_DAYS":
        setPastDays("PAST_7_DAYS");
        setRangeDate(fromTransactionModule ? "LAST_7_DAYS" : [dayjs().subtract(7, "day"), dayjs()]);
        break;
      case "PAST_30_DAYS":
        setPastDays("PAST_30_DAYS");
        setRangeDate([dayjs().subtract(30, "day"), dayjs()]);
        break;
      case "PAST_90_DAYS":
        setPastDays("PAST_90_DAYS");
        setRangeDate([dayjs().subtract(90, "day"), dayjs()]);
        break;
      default:
        setRangeDate(null);
        break;
    }
    handleClose();
  };

  const daySetHandler = (newValue) => {
    setPastDays([newValue, newValue]);
    setRangeDate([newValue, newValue]);
    handleClose();
  };

  const getLabel = (value) => {
    switch (value) {
      case "ALL_TIME":
        return "Date Range";
      case "YESTERDAY":
        return "Yesterday";
      case "PAST_7_DAYS":
        return "Past 7 days";
      case "PAST_30_DAYS":
        return "Past 30 days";
      case "PAST_90_DAYS":
        return "Past 90 days";
      default:
        return value[0].format("DD/MM/YYYY");
    }
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        size="large"
        onClick={handleClick}
        sx={{
          padding: "6px 15px",
          borderColor: "rgba(0,0,0,0.26)",
          color: "black",
          textTransform: "none",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          fontSize: "14px",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 1)",
          },
        }}
      >
        {pastDays ? getLabel(pastDays) : rangeDate && rangeDate[0]?.format("DD/MM/YYYY")}
        <DateRangeIcon fontSize="small" sx={{ color: "#707070", marginLeft: "10px" }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FormControl
          sx={{ m: 1, minWidth: 150, display: "flex", gap: "20px" }}
          size="small"
        >
          <MenuList>
            <MenuItem
              onClick={() => handleDayRange("ALL_TIME")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular", backgroundColor: pastDays === "ALL_TIME" ? "#1A76D2" : null, color: pastDays === "ALL_TIME" ? "#FFFFFF" : null }}
            >
              All time
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("YESTERDAY")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular", backgroundColor: pastDays === "YESTERDAY" ? "#1A76D2" : null, color: pastDays === "YESTERDAY" ? "#FFFFFF" : null }}
            >
              Yesterday
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("PAST_7_DAYS")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular", backgroundColor: pastDays === "PAST_7_DAYS" ? "#1A76D2" : null, color: pastDays === "PAST_7_DAYS" ? "#FFFFFF" : null }}
            >
              Past 7 days
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("PAST_30_DAYS")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular", backgroundColor: pastDays === "PAST_30_DAYS" ? "#1A76D2" : null, color: pastDays === "PAST_30_DAYS" ? "#FFFFFF" : null }}
            >
              Past 30 days
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("PAST_90_DAYS")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular", backgroundColor: pastDays === "PAST_90_DAYS" ? "#1A76D2" : null, color: pastDays === "PAST_90_DAYS" ? "#FFFFFF" : null }}
            >
              Past 90 days
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("RESET")}
              style={{
                width: "8rem",
                marginTop: "3.5rem",
                fontFamily: "PPMori-Regular",
              }}
            >
              Reset
            </MenuItem>
          </MenuList>
          <div className="datevalue">
            <DateCalendarValue
              rangeDate={tempDateRange}
              onDateSelect={daySetHandler}
            />
          </div>
        </FormControl>
      </Popover>
    </div>
  );
}
