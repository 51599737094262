import React from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  Button,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(
  require.context("../../Images/flags", false, /\.(png)$/)
);

const TransferDetailsForm = ({
  accounts,
  transferFrom,
  transferTo,
  handleTransferFromChanges,
  handleTransferToChanges,
  handleNext,
  isFetching,
}) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      gap={2}
      flexDirection={"column"}
    >
      <Typography
        fontWeight={600}
        variant="h6"
        textAlign={"left"}
        width={"100%"}
      >
        Select accounts
      </Typography>
      <Box display={"flex"} gap={"1px"} flexDirection={"column"} width={"100%"}>
        <FormControl fullWidth margin="normal">
          {!transferFrom && (
            <InputLabel shrink={false} id="recipientName-select-label">
              Select an account
            </InputLabel>
          )}
          <Select
            required
            // labelId="recipientName-select-label"
            id="recipientName-select"
            value={transferFrom?.id}
            onChange={handleTransferFromChanges}
            style={{
              width: "100%",
            }}
            IconComponent={KeyboardArrowDown}
          >
            {!isFetching ? (
              accounts.length > 0 ? (
                accounts.map((account) => (
                  <MenuItem
                    key={account.id}
                    value={account.id}
                    style={{
                      fontSize: "10px",
                      fontFamily: "PPMori-Regular",
                    }}
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography variant="body1" fontSize={12}>
                        {account.currency} {account.balance}
                      </Typography>

                      <Typography variant="body1" fontSize={10}>
                        {account.name
                          ? account.name
                          : account?.account_number_external}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))
              ) : (
                <Typography p={1} textAlign={"center"}>
                  You don't have any account. Please open a account.
                </Typography>
              )
            ) : (
              <Typography p={1} textAlign={"center"}>
                Getting accounts information...
              </Typography>
            )}
          </Select>
        </FormControl>
        {!isFetching &&
          accounts?.length >= 2 &&
          transferFrom?.balance === 0 && (
            <Box
              sx={{ background: "#ffe5e3" }}
              borderRadius={1}
              padding={1}
              display={"flex"}
              gap={"3px"}
              alignItems={"center"}
            >
              <WarningAmberOutlinedIcon
                sx={{ color: "#df291b" }}
                fontSize="small"
              />
              <Typography fontSize={12}>
                You do not have enough balance please select a different account
                to transfer money
              </Typography>
            </Box>
          )}
        {!isFetching && accounts?.length < 2 && (
          <Box
            sx={{ background: "#ffe5e3" }}
            borderRadius={1}
            padding={1}
            display={"flex"}
            gap={"3px"}
            alignItems={"center"}
          >
            <WarningAmberOutlinedIcon
              sx={{ color: "#df291b" }}
              fontSize="small"
            />
            <Typography fontSize={12}>
              You should have at least two accounts to Internal Transfer
            </Typography>
          </Box>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"1px"} width={"100%"}>
        <FormControl fullWidth margin="normal">
          {!transferTo && (
            <InputLabel shrink={false} id="recipientName-select-label">
              Select an account
            </InputLabel>
          )}
          <Select
            required
            labelId="recipientName-select-label"
            id="recipientName-select"
            placeholder="Recipient name"
            value={transferTo?.id}
            onChange={handleTransferToChanges}
            style={{
              width: "100%",
            }}
            disabled={accounts.length < 2 || isFetching}
            IconComponent={KeyboardArrowDown}
          >
            {accounts.map((account) => (
              <MenuItem
                key={account.id}
                value={account.id}
                style={{
                  fontSize: "10px",
                  fontFamily: "PPMori-Regular",
                }}
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography variant="body1" fontSize={12}>
                    {account.currency} {account.balance}
                  </Typography>

                  <Typography variant="body1" fontSize={10}>
                    {account?.name
                      ? account?.name
                      : account?.account_number_external}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {transferFrom && transferTo && transferFrom.id === transferTo.id && (
          <Box
            sx={{ background: "#ffe5e3" }}
            borderRadius={1}
            padding={1}
            display={"flex"}
            gap={"3px"}
            alignItems={"center"}
          >
            <WarningAmberOutlinedIcon
              sx={{ color: "#df291b" }}
              fontSize="small"
            />
            <Typography fontSize={12}>
              You can't send to/from the same account
            </Typography>
          </Box>
        )}
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Button
          onClick={handleNext}
          variant="contained"
          color="primary"
          sx={{
            fontFamily: "PPMori-Semi-Bold",
            width: "150px",
            backgroundColor: "#462A9C",
            color: "#ffffff",
            fontSize: "12px",
            textTransform: "none",
            py: 1,
          }}
          disabled={
            !transferTo ||
            !transferFrom ||
            transferFrom?.id === transferTo?.id ||
            accounts?.length < 2 ||
            isFetching ||
            transferFrom?.balance === 0
          }
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default TransferDetailsForm;
