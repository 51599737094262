import React, { useEffect, useState } from "react";
import { Box, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StepperUI from "../../Common/StepperUI/StepperUI";
import SidebarSummery from "./SidebarSummery";
import TransferDetailsForm from "../../Forms/TransferDetailsForm";
import TransferAmountForm from "../../Forms/TransferAmountForm";
import Summery from "./Summery";
import TransactionOTPVerifyForm from "../../Forms/TransactionVerifyOTPForm";
import LoadingTxn from "../../LoaderUI/LoadingTxn";
import TransactionSuccess from "../../Alerts/TransactionSuccess";
import TransactionFailure from "../../Alerts/TransactionFailure";
import { getAllAccountsList, transferBetweenAccounts } from "../../../lib/service/accountsApi/accountsApi";
import { notify } from "../../Notification/Notification";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
};
const InternalTransferModal = ({ open, onClose }) => {
  const [step, setStep] = useState(0);
  const [sendAmount, setSendAmount] = useState(0);
  const [isTransferring, setIsTransFerring] = useState(false);
  const [successTxn, setSuccessTxn] = useState(false);
  const [transferFrom, setTransferFrom] = useState();
  const [transferTo, setTransferTo] = useState();
  const [errorTxn, setErrorTxn] = useState(false);
  const steps = ["Transfer Details", "Transfer Amount", "Review and Pay"];
  const [accounts, setAccounts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    const getAllAccountsDetails = async () => {
      setIsFetching(true);
      try {
        const res = await getAllAccountsList();
        setAccounts(res.data.accounts);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    };
    getAllAccountsDetails();
  }, []);
  const handleNext = () => {
    if (step != 3) {
      setStep((prev) => prev + 1);
    }
  };
  const handleGoBack = () => {
    if (step == 0) return;
    setStep((prev) => prev - 1);
  };
  const handleVerifyAndPay = async() => {
    setIsTransFerring(true);
    try {
      const res = await transferBetweenAccounts({
        action: "FIX_SOURCE",
        amount: sendAmount,
        source_account_id: transferFrom,
        destination_account_id: transferTo,
        description: "Converting 5 USDC to USD",
      })
      if(res.code === 200) {
        notify.success({
          message: "Amount transfered successfully",
        })
        setSuccessTxn(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsTransFerring(false);
    }
  };
  const handleTransferFromChanges = (e) => {
    const account = accounts.find((account) => account.id === e.target.value);
    setTransferFrom(account);
  };
  const handleTransferToChanges = (e) => {
    const account = accounts.find((account) => account.id === e.target.value);
    setTransferTo(account);
  };
  const handleSendAmount = (e) => {
    setSendAmount(e.target.value);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style }}>
        <Box
          padding={2}
          borderBottom={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          borderColor={"rgba(0,0,0,0.3)"}
          position={"relative"}
          width="100%"
        >
          <Box sx={{ width: "40%" }}>
            <StepperUI step={step} steps={steps} />
          </Box>

          <IconButton
            onClick={onClose}
            aria-label="close-open-account-modal"
            sx={{ position: "absolute", right: "12px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {step === 0 && (
          <Box padding={5} display={"flex"}>
            <SidebarSummery title={"Transfer details"} />
            <Box width={"100%"}>
              <TransferDetailsForm
                accounts={accounts}
                handleNext={handleNext}
                transferFrom={transferFrom}
                transferTo={transferTo}
                handleTransferFromChanges={handleTransferFromChanges}
                handleTransferToChanges={handleTransferToChanges}
                isFetching={isFetching}
              />
            </Box>
            <Box width={"100%"} />
          </Box>
        )}
        {step === 1 && (
          <Box padding={5} display={"flex"}>
            <SidebarSummery
              title={"Transfer Amount"}
              showSummery
              stepOneSummery
              transferFrom={transferFrom}
              transferTo={transferTo}
            />
            <Box width={"100%"}>
              <TransferAmountForm
                transferFrom={transferFrom}
                transferTo={transferTo}
                transferAmount={sendAmount}
                handleAmountChange={handleSendAmount}
                handleNext={handleNext}
                handleGoBack={handleGoBack}
              />
            </Box>
            <Box width={"100%"} />
          </Box>
        )}
        {step === 2 && (
          <Box padding={5} display={"flex"}>
            <SidebarSummery title={"Transfer Amount"} />
            <Box width={"100%"}>
              <Summery
                handleGoBack={handleGoBack}
                handleNext={handleNext}
                transferFrom={transferFrom}
                transferTo={transferTo}
              />
            </Box>
            <Box width={"100%"} />
          </Box>
        )}
        {step == 3 &&
          (isTransferring ? (
            <Box flexGrow={1}>
              <LoadingTxn message={"Transferring amount"} />
            </Box>
          ) : successTxn ? (
            <Box flexGrow={1}>
              <TransactionSuccess
                message={"You have successfully send money"}
                actionButtonText={"Go to account details"}
                buttonAction={() => {}}
              />
            </Box>
          ) : errorTxn ? (
            <Box flexGrow={1}>
              <TransactionFailure
                goBackAction={() => {}}
                retryAction={() => {}}
                retryActionText={"Retry transaction"}
                goBackActionText={"Go back to Account Details"}
              />
            </Box>
          ) : (
            <Box padding={5} display={"flex"}>
              <SidebarSummery title={"Review and verify"} />
              <TransactionOTPVerifyForm
                handleBack={handleGoBack}
                otpLength={6}
                otpExpirationTimer={2 * 60}
                handleResendOTP={() => {}}
                handleVerifyOTP={handleVerifyAndPay}
              />
              <Box width={"100%"}></Box>
            </Box>
          ))}
      </Box>
    </Modal>
  );
};

export default InternalTransferModal;
