import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Select,
    MenuItem,
    Paper,
    RadioGroup,
    FormControlLabel,
    Radio,
    Skeleton, 
    styled
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ErrorOutline } from "@mui/icons-material";

// Styled Radio Components
const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === "dark"
            ? "0 0 0 1px rgb(16 22 26 / 40%)"
            : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    ".Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
        color: "black",
    },
    "input:hover ~ &": {
        backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background:
            theme.palette.mode === "dark"
                ? "rgba(57,75,89,.5)"
                : "rgba(206,217,224,.5)",
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#462A9C",
    "&::before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: "#462A9C",
    },
});

function BpRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

// Function to load images dynamically
const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
        images[item.replace("./", "")] = r(item);
    });
    return images;
};
const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));

const SendFiatPaymentMethodForm = ({
    sourceCurrency,
    handleCurrencyChange,
    handleGoBack,
    handleNext,
    sourceCurrencies,
    selectedPaymentMethod,
    onPaymentMethodChange,
    accountsList,
    handleOpenTop,
    accountLoader 
}) => {
    const [insufficientFunds, setInsufficientFunds] = useState(false);

    useEffect(() => {
        const selectedAccount = accountsList.find(account => account.currency === sourceCurrency);
        if (selectedAccount) {
            setInsufficientFunds(selectedAccount.balance <= 100); // Balance less than or equal to 100
        }
    }, [sourceCurrency, accountsList]);

    return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={2}>
            <Typography fontSize="16px" fontWeight="bold">
                Select Payment Method
            </Typography>

            <RadioGroup
                aria-label="payment-type"
                name="paymentType"
                value={selectedPaymentMethod}
                onChange={onPaymentMethodChange}
                style={{ width: "100%" }}
            >
                <Box
                    sx={{
                        border: "1.5px solid #efeeeb",
                        borderRadius: "8px",
                        backgroundColor: "#f9f9f9",
                        mb: 2,
                        padding: "12px",
                    }}
                >
                    <FormControlLabel
                        value="credit/debit"
                        control={<BpRadio />}
                        label="Credit/Debit Card"
                        disabled
                        sx={{
                            ".MuiFormControlLabel-label": { fontSize: 14 },
                        }}
                    />
                </Box>

                <Box
                    sx={{
                        border: "1.5px solid",
                        borderColor: selectedPaymentMethod === "ENDL_ACCOUNT" ? "#462A9C" : "#efeeeb",
                        backgroundColor: "#fcfbfa",
                        borderRadius: "8px",
                        padding: "12px",
                    }}
                >
                    <FormControlLabel
                        value="ENDL_ACCOUNT"
                        control={<BpRadio />}
                        label="endl Account"
                        sx={{
                            ".MuiFormControlLabel-label": { fontSize: 14 },
                        }}
                    />

                    {/* Display accounts under endl Account option */}
                    {selectedPaymentMethod === "ENDL_ACCOUNT" && (
                        <Box mt={1} display="flex" flexDirection="column" gap={1} paddingLeft={3}>
                            {accountLoader ? (
                                [...Array(2)].map((_, index) => (
                                    <Skeleton key={index} variant="rectangular" width="100%" height={50} sx={{ borderRadius: "8px", mb: 1 }} />
                                ))
                            ) : (
                                accountsList.map((account) => {
                                    const countryCode = account.currency ? account.currency.toLowerCase() : "default";
                                    const imagePath = images[`${countryCode}.png`];

                                    return (
                                        <Paper
                                            key={account.id}
                                            variant="outlined"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "10px",
                                                gap: "10px",
                                                borderRadius: "8px",
                                                borderColor: "#efeeeb",
                                                width: "100%",
                                                backgroundColor: "#ffffff",
                                            }}
                                        >
                                            <img src={imagePath} alt={account.currency} style={{ width: "24px" }} />
                                            <Box display="flex" flexDirection="column">
                                                <Typography fontSize={14}>
                                                    {account.currency} {Number(account.balance.toFixed(2))?.toLocaleString()}
                                                </Typography>
                                                <Typography fontSize={12}>{account.name}</Typography>
                                            </Box>
                                        </Paper>
                                    );
                                })
                            )}
                        </Box>
                    )}
                </Box>
            </RadioGroup>

            <Box mt={2} display="flex" flexDirection="column" width="100%">
                <Typography fontSize="12px">Source account</Typography>
                <Select
                    id="source-currency"
                    value={sourceCurrency}
                    onChange={handleCurrencyChange}
                    IconComponent={KeyboardArrowDown}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <Typography fontSize="14px" color="#aaa">Select source account</Typography>
                    </MenuItem>

                    {accountsList?.map((account) => {
                        const countryCode = account.currency ? account.currency.toLowerCase() : "default";
                        const imagePath = images[`${countryCode}.png`];
                        return (
                            <MenuItem key={account.currency} value={account.currency} sx={{ display: "flex", alignItems: "center" }}>
                                <img src={imagePath} alt={account.currency} style={{ width: "15px", marginRight: "8px" }} />
                                {account.currency}
                            </MenuItem>
                        );
                    })}
                </Select>
                {insufficientFunds && (
                    <Box mt={1} display="flex" alignItems="center" gap={1} color="error.main">
                        <ErrorOutline fontSize="small" />
                        <Typography fontSize="12px">
                            Insufficient funds.{" "}
                            <span
                                onClick={handleOpenTop}
                                style={{
                                    color: "#462A9C",
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                }}
                            >
                                Top up
                            </span>
                        </Typography>
                    </Box>
                )}
            </Box>

            <Box mt={2} display="flex" gap={1} width="100%">
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                        textTransform: "none",
                        borderColor: "#462A9C",
                        color: "#462A9C",
                        borderRadius: "8px",
                        fontFamily: "PPMori-Regular",
                        fontSize: "12px",
                        width: "100%",
                        py: 1,
                    }}
                    fullWidth
                    onClick={handleGoBack}
                >
                    Go back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!selectedPaymentMethod || !sourceCurrency || insufficientFunds}
                    sx={{
                        textTransform: "none",
                        backgroundColor: "#462A9C",
                        borderRadius: "8px",
                        color: "#ffffff",
                        fontSize: "12px",
                        fontFamily: "PPMori-Regular",
                        width: "100%",
                        py: 1,
                    }}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default SendFiatPaymentMethodForm;
