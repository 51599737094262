import React, { useEffect, useState } from 'react';

const Supplier = () => {
    const [iframeHeight, setIframeHeight] = useState('600px'); // Default height

    useEffect(() => {
        // Set the iframe height to 100% of the window height dynamically
        const handleResize = () => {
            setIframeHeight(`${window.innerHeight - 100}px`); // Adjust -100px for padding/margin if needed
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call the function to set initial height on mount

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup listener on unmount
        };
    }, []);

    return (
        <div>
            <iframe
                src="/suppliers.html"
                title="HTML Page"
                width="100%"
                height={iframeHeight}
                style={{ border: 'none' }}
            ></iframe>
        </div>
    );
};

export default Supplier;
