import React from "react";
import {
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Paper,
  Table,
  TableBody,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

// Define the RenderSkeletonRow component
const RenderSkeletonRow = ({ rowHeadingdata }) => (
  <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell align="left" sx={{ backgroundColor: "#F6F5F9" }} />
          {rowHeadingdata.map((item) => (
            <TableCell
              key={item}
              align="left"
              sx={{
                borderRight: "1px solid #ccc",
                backgroundColor: "#F6F5F9",
                fontWeight: "bold",
                fontFamily: "PPMori-Regular",
              }}
            >
              {item}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(5)].map((_, index) => (
          <TableRow>
            <TableCell>
              <Skeleton variant="rectangular" width={24} height={24} />
            </TableCell>
            {rowHeadingdata.map(() => (
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default RenderSkeletonRow;
