import sever_ENV from "../../../env"
import directService from "../AppService"
import { checkAuthToken } from "../AuthToken"

function getAuthToken() {
    return localStorage.getItem('authToken');
}

const getProfile = (data,tokenSet) => {
  try {
    const checkToken = checkAuthToken()
    if (checkToken) {
        const token = getAuthToken();
        let authToken = tokenSet ? tokenSet : token
        if (!token) {
            console.error('No auth token found. Please login.');
            return;
        }
        return directService(
            {
                url: `/api/v1/user/profile`,
                method: 'GET',
                data: data,
                headers: { Authorization: `Bearer ${authToken}` },
            },

        )
    }
} catch (error) {
    console.log(error)
}
}

const updateProfile = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `/api/v1/user/profile`,
                    method: 'PATCH',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                },
    
            )
        }
    } catch (error) {
        console.log(error)
    }
  }

  const getProfilePicture = (tokenSet) => {
    try {
      const checkToken = checkAuthToken()
      if (checkToken) {
          const token = getAuthToken();
          let authToken = tokenSet ? tokenSet : token
          console.log(authToken, 'authTokenauthToken')
          if (!token) {
              console.error('No auth token found. Please login.');
              return;
          }
          return directService(
              {
                  url: `/api/v1/user/profilePicture`,
                  method: 'GET',
                  headers: { Authorization: `Bearer ${authToken}`},
                  responseType: 'blob'
              },
  
          )
      }
  } catch (error) {
      console.log(error)
  }
}
  const uploadProfile = (data) => {
    try {
      const checkToken = checkAuthToken()
      if (checkToken) {
          const token = getAuthToken();
          if (!token) {
              console.error('No auth token found. Please login.');
              return;
          }
          return directService(
              {
                  url: `/api/v1/user/uploadProfilePicture`,
                  method: 'POST',
                  data: data,
                  headers: { Authorization: `Bearer ${token}` },
              },
  
          )
      }
  } catch (error) {
      console.log(error)
  }
  }
export { getProfile, updateProfile, getProfilePicture, uploadProfile };