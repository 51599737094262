import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  InputAdornment,
  OutlinedInput,
  Button,
  Typography,
  Grid
} from "@mui/material";
import ChargesModal from "../ModalComponent/CurrencyCalculator/ChargesModal";
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));

const TransferAmountForm = ({
  sourceCurrency,
  receiveCurrency,
  receiveAmount,
  sendAmount,
  showAmountError,
  handleGoBack,
  handleNext,
  handleSendAmountChange,
  chargesFee,
  chargeTotal,
  exchangeRate,
  accountDetails,
  isFetching,
  onReceiveAmountChange,
  totalFeesCurrency,
  expiresOn,
  isQuoteExpired,
  amountToConvert
}) => {
  const [showCharges, setshowCharges] = useState(false);
  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    if (expiresOn) {
      const expirationDate = new Date(expiresOn);
      
      const updateRemainingTime = () => {
        const now = new Date();
        const timeDiff = expirationDate - now;
        if (timeDiff <= 0) {
          setRemainingTime("Expired");
          isQuoteExpired = true;
        } else {
          const minutes = Math.floor(timeDiff / 1000 / 60);
          const seconds = Math.floor((timeDiff / 1000) % 60);
          setRemainingTime(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
        }
      };

      updateRemainingTime(); // Initial call
      const timerInterval = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [expiresOn, isQuoteExpired]);


  const handleSendAmountInputChange = (event) => {
    const rawValue = event.target.value.replace(/,/g, '');
    const numericRegex = /^\d*\.?\d{0,2}$/;
    
    if (!numericRegex.test(rawValue)) {
        return;
    }

    let formattedValue = rawValue;
    if (rawValue) {
      const parts = rawValue?.split('.');
      parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      formattedValue = parts?.join('.');
    }

    setRemainingTime("")
    handleSendAmountChange(formattedValue);
};

const handleReceiveAmountInputChange = (event) => {
    const rawValue = event.target.value.replace(/,/g, '');
    const numericRegex = /^\d*\.?\d{0,2}$/;
    
    if (!numericRegex.test(rawValue)) {
        return;
    }

    let formattedValue = rawValue;
    if (rawValue) {
      const parts = rawValue?.split('.');
      parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      formattedValue = parts?.join('.');
    }

    setRemainingTime("")
    onReceiveAmountChange(formattedValue);
};

  const imagePath = accountDetails?.currency
    ? accountDetails.currency.toLowerCase()
    : 'usd';
  return (
    <Box display={"flex"} gap={5} flexDirection={"column"}>
      <Box
        sx={{
          border: "1px solid #F2F1F0",
          borderRadius: 4,
          minWidth: 500,
          overflow: "hidden",
          textAlign: 'center'
        }}
      >
        <Box
          bgcolor={"#FBFAFC"}
          display={"flex"}
          gap={1}
          sx={{ padding: 2 }}
          borderBottom={'1px solid #F2F1F0'}
          borderColor={"1px solid #F2F1F0"}
        >
          <Box width={"100%"}>
            <Typography fontWeight={600} fontSize={14}>
              You send
            </Typography>
          </Box>
          <Box width={"100%"}>
            <Typography fontWeight={600} fontSize={14}>
              Add to account
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ padding: 3 }}
          display={"flex"}
          flexDirection={"column"}
          gap={4}
        >
          <Box display={"flex"} gap={1}>
            <Box display="flex" flexDirection="column" minHeight="75px" flex={1}>
              <OutlinedInput
                sx={{
                  width: "100%",
                  height: "45px",
                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "12px 14px",
                    borderRight: "1px solid rgba(0,0,0,0.20)",
                  },
                }}
                onChange={handleSendAmountInputChange}
                inputProps={{
                  style: {
                    py: 1,
                  },
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <img
                      src={images[`${sourceCurrency?.toLowerCase()}.png`]}
                      alt={sourceCurrency?.toLowerCase()}
                      style={{
                        width: "15px",
                        fontFamily: "PPMori-Regular",
                      }}
                    />
                    <Typography
                      variant="body6"
                      fontSize={15}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {sourceCurrency}
                    </Typography>
                  </InputAdornment>
                }
                value={sendAmount}
              />
              {showAmountError && (
                <Typography
                  variant="caption"
                  color="error"
                  display="block"
                  mt={1}
                  sx={{
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {showAmountError}
                </Typography>
              )}
            </Box>

            <Box width={"100%"} flex={1}>
              <OutlinedInput
                value={receiveAmount}
                onChange={handleReceiveAmountInputChange}
                sx={{
                  width: "100%",
                  height: "45px",
                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "12px 14px",
                    borderRight: "1px solid rgba(0,0,0,0.20)",
                  },
                }}
                inputProps={{
                  style: {
                    py: 1,
                  },
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <img
                      src={images[`${imagePath}.png`]}
                      alt={receiveCurrency?.toLowerCase()}
                      style={{
                        width: "15px",
                        fontFamily: "PPMori-Regular",
                      }}
                    />
                    <Typography
                      variant="body6"
                      fontSize={15}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {receiveCurrency}
                    </Typography>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>

          <Divider sx={{ borderColor: "#F2F1F0" }} />
          <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Grid container>
                <Grid item xs={6}>
                    <Box display={'flex'} alignItems={'centre'}><Typography
                      variant="body1"
                      style={{
                        fontSize: "14px",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                      Exchange rate
                    </Typography></Box>
                    <><Box display={'flex'} alignItems={'centre'}><Typography
                      variant="body1"
                      style={{ fontSize: "14px", fontFamily: "PPMori-Regular" }}
                    >
                      Total fee
                    </Typography></Box>
                    {totalFeesCurrency ? (<span onClick={() => setshowCharges(true)} style={{ cursor: 'pointer', color: "#462A9C", fontSize: "12px", display: 'flex' }}>Show more</span>) : null}</>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "0.875rem",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                     {exchangeRate ? (<>
                      1 {sourceCurrency} = {exchangeRate} {receiveCurrency}</>) : 0}
                    </Typography>
                    <><Typography
                      variant="body1"
                      style={{
                        fontSize: "0.875rem",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                      {chargeTotal} {totalFeesCurrency}
                    </Typography></>
                </Grid>
                {remainingTime && !isQuoteExpired && (
                  <Typography variant="body2" color="textSecondary">
                    Quote expires in: {remainingTime}
                  </Typography>
                )}
                {isQuoteExpired && (
                  <Typography color="error" variant="body1" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Quote expired! Please generate a new one.
                  </Typography>
                )}
              </Grid>
          </Box>
        </Box>
      </Box>
      <Box wrap="noWrap" display={"flex"} gap={1} width={"100%"}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            textTransform: "none",
            borderColor: "#462A9C",
            color: "#462A9C",
            fontSize: "12px",
            fontFamily: "PPMori-Regular",
            width: "100%",
            py: 1,
          }}
          fullWidth
          onClick={handleGoBack}
        >
          Go back
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "none",
            backgroundColor: "#462A9C",
            color: "#ffffff",
            fontSize: "12px",
            fontFamily: "PPMori-Regular",
            width: "100%",
            py: 1,
          }}
          onClick={handleNext}
          disabled={showAmountError ||
            !sendAmount ||
            isFetching || isQuoteExpired ||
            !totalFeesCurrency}
        >
          {" "}
          Next
        </Button>
      </Box>
      {showCharges && (
        <ChargesModal chargesFee={chargesFee} chargeTotal={chargeTotal} showCharges={showCharges} onClose={() => setshowCharges(false)} sendAmount={sendAmount} sendCurrency={sourceCurrency} receiveAmount={receiveAmount} receiveCurrency={receiveCurrency} amountToConvert={amountToConvert}  totalFeesCurrency={totalFeesCurrency} exchangeRate={exchangeRate}/>
      )}
    </Box>
  );
};

export default TransferAmountForm;
