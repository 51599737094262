import React, { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  LinearProgress,
  Skeleton,
  CircularProgress,
  Autocomplete
} from "@mui/material";
import { styled } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ReactComponent as Plus } from "../../Images/Plus.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import commonHelpers from "../../utils/common.helpers";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22, 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
    color: "black",
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#462A9C",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#462A9C",
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const SendMoneyPaymentDetails = ({
  selectedPaymentType,
  isEditing,
  recipients,
  handleEdit,
  handleInputChange,
  handleInputBlur,
  handlePaymentTypeOptionChange,
  handleRecipientOptionChange,
  handleNext,
  addRecipientHandler,
  selectedRecipient,
  isRecipientLoading,
  totalCount,
  fetchMoreRecipients,
  page,
  size,
  searchRecipients,
  searchInProgress,
  setSearchQuery
}) => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const handleScroll = async (event) => {
    const bottom =
      parseInt(event.target.scrollHeight - event.target.scrollTop) ===
      event.target.clientHeight;
    if (bottom && recipients.length < totalCount && !isRecipientLoading) {
      setIsFetchingMore(true);
      await fetchMoreRecipients();
      setTimeout(() => setIsFetchingMore(false), 500)
    }
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      gap={2}
      flexDirection={"column"}
    >
      <Box display={"flex"} gap={1} flexDirection={"column"} width={"100%"}>
        <Typography
          fontWeight={600}
          textAlign={"left"}
          width={"100%"}
        >
          Select payment type
        </Typography>
        <RadioGroup
          aria-label="payment-type"
          name="paymentType"
          value={selectedPaymentType}
          onChange={handlePaymentTypeOptionChange}
          style={{
            width: "100%",
          }}
        >
          {[
            { value: "fiatToCrypto", label: "Fiat to Crypto", disabled: true },
            { value: "fiatToFiat", label: "Fiat to Fiat" },
            { value: "cryptoToFiat", label: "Crypto to Fiat" },
          ].map((option) => (
            <Box
              key={option.value}
              sx={{
                border: "1px solid",
                borderColor:
                  option.value === selectedPaymentType ? "#dfdddb" : "#efeeeb",
                backgroundColor: "#fcfbfa",
                mb: 2,
                width: "100%",
                borderRadius: "5px",
                padding: 1,
              }}
            >
              <FormControlLabel
                value={option.value}
                control={<BpRadio />}
                label={option.label}
                disabled={option.disabled}
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "11px",
                  },
                  ml: 1,
                }}
              />
            </Box>
          ))}
        </RadioGroup>
      </Box>
      <Box display={"flex"} gap={1} flexDirection={"column"} width={"100%"} p={{ xs: "10px" }}>
        <Typography
          variant="h6"
          fontWeight={600}
          gutterBottom
          fontFamily="PPMori-Regular"
          textAlign={"left"}
          width={"100%"}
        >
          Recipient details
        </Typography>
        <Box>
          <Typography variant="body1" fontSize={12}>
            Recipient name
          </Typography>
          <FormControl fullWidth>
            {!selectedRecipient?.id && (
              <InputLabel shrink={false} id="recipientName-select-label">
                Select recipient
              </InputLabel>
            )}

            <Select
              labelId="recipientName-select-label"
              id="recipientName-select"
              value={selectedRecipient?.id || ""}
              onChange={handleRecipientOptionChange}
              renderValue={(selected) => {
                const recipient = recipients.find((r) => r.id === selected);
                return recipient ? recipient.name : "Select recipient";
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 400,
                  },
                  onScroll: handleScroll,
                },
                MenuListProps: {
                  disablePadding: true
                }
              }}
              sx={{
                padding: 0
              }}
              onClose={() => setSearchQuery("")}
            >
              <Box
                sx={{
                  position: "sticky",
                  zIndex: "100",
                  backgroundColor: "#FFF",
                  padding: "8px",
                  top: "0px",
                }}
              >
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Search recipient"
                  onChange={(e) => searchRecipients(e.target.value)}
                  variant="outlined"
                  autoFocus
                  onKeyDown={(e) => e.stopPropagation()}
                  autoComplete="off"
                />
              </Box>

              {isRecipientLoading && recipients.length === 0 ? (
                [...Array(5)].map((_, index) => (
                  <MenuItem key={index} disabled>
                    <Skeleton variant="rectangular" width={"100%"} height={20} />
                  </MenuItem>
                ))
              ) : (
                recipients.map((recipient) => (
                  <MenuItem key={recipient.id} value={recipient.id}>
                    <Box display={"flex"} gap={"2px"} flexDirection={"column"}>
                      <Typography variant="body1" fontSize={14}>
                        {recipient.name}
                      </Typography>
                      <Typography variant="body1" fontSize={12} color="rgba(0,0,0,0.70)">
                        {recipient.bankName} • {recipient.bankAccountNumber?.slice(-4)}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))
              )}
              {isFetchingMore && (
                <MenuItem disabled>
                  <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    py={1}
                    sx={{
                      position: "relative",
                      zIndex: 1,
                      height: "40px",
                      padding: "10px 0",
                    }}
                  >
                    <CircularProgress color="primary" size={24} />
                  </Box>
                </MenuItem>
              )}
              {!isRecipientLoading && recipients.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                  <Typography variant="body2">No results found</Typography>
                </Box>
              ) : null}
              <Box position="sticky" bottom={0} bgcolor="#EDE8FC" borderTop="1px solid #462A9C" padding={1}>
                <Button
                  onClick={addRecipientHandler}
                  variant="text"
                  sx={{
                    color: "#462A9C",
                    fontSize: "12px",
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    fontWeight: "bold",
                  }}
                  startIcon={<Plus stroke="#462A9C" width={16} height={16} />}
                >
                  Add New Recipient
                </Button>
              </Box>
            </Select>
          </FormControl>
        </Box>
        {selectedRecipient?.id && (
          <Box
            sx={{
              border: "1px solid #eeecea",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 1,
              marginBottom: "10px",
              borderRadius: "5px",
              width: "100%",
              backgroundColor: "#f9f8f5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>
                Bank account
              </Typography>
              <Typography
                variant="body1"
                fontSize={12}
                color="rgba(0,0,0,0.70)"
                display={"flex"}
                alignItems={"center"}
              >
                Bank Name : {selectedRecipient.bankName}

              </Typography>
              <Typography
                variant="body1"
                fontSize={12}
                color="rgba(0,0,0,0.70)"
                alignItems={"center"}>
                Account Number/IBAN : {selectedRecipient.bankAccountNumber?.slice(-4)}
              </Typography>
            </Box>
            {/* <Box>
                <IconButton onClick={() => handleEdit(selectedRecipient?.id)}>
                  <Pencil />
                </IconButton>
              </Box> */}
          </Box>
        )}

        <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
          <Button
            onClick={handleNext}
            variant="contained"
            color="primary"
            sx={{
              width: "50%",
              backgroundColor: "#462A9C",
              color: "#ffffff",
              fontSize: "12px",
              borderRadius: "8px",
              textTransform: "none",
              py: 1,
            }}
            disabled={!selectedRecipient?.id}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SendMoneyPaymentDetails;
