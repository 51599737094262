import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Box,
  FormControl,
  Radio,
  Select,
  Typography,
  MenuItem,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import { LocalDatePicker } from "../../Calender/NewCalender";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { relationshipToRecipientMap } from "../../../utils/recipeint.helpers";
import { IndianBankDetails } from "./IndianBankDetails";
import { CSJMPBankDetails } from "./CSJMPBankDetails";
import { CanadaBankDetails } from "./CanadaBankDetails";
import { USBankDetails } from "./USBankDetails";
import { BahamasBankDetails } from "./BahamasBankDetails";
import { GreatBritainBankDetails } from "./GreatBritainBankDetails";
import { EURegionBankDetails } from "./EURegionBankDetails";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../../../Images/flags", false, /\.(png)$/));

const AddRecipientBankAccountForm = ({
  formData,
  formErrors,
  handleChange,
  currencyList,
  countryList,
  isEdit=false,
  railList,
}) => {
  const getBankDetailsForm = (country) => {
    switch (country.id) {
      // India
      case 103:
        return (
          <IndianBankDetails
            formData={formData}
            formErrors={formErrors}
            handleChange={handleChange}
            isEdit={isEdit}
            countryList={countryList}
          />
        );
      // China, Singapore, Japan, Malaysia, Panama
      case 45:
      case 200:
      case 112:
      case 134:
      case 170:
        return (
          <CSJMPBankDetails
            formData={formData}
            formErrors={formErrors}
            handleChange={handleChange}
            isEdit={isEdit}
            countryList={countryList}
          />
        );
      // Canada
      case 40:
        return (
          <CanadaBankDetails
            formData={formData}
            formErrors={formErrors}
            handleChange={handleChange}
            isEdit={isEdit}
            countryList={countryList}
          />
        );
      // US
      case 236:
        return (
          <USBankDetails
            formData={formData}
            formErrors={formErrors}
            handleChange={handleChange}
            isEdit={isEdit}
            countryList={countryList}
          />
        );
      // Bahamas
      case 16:
        return (
          <BahamasBankDetails
            formData={formData}
            formErrors={formErrors}
            handleChange={handleChange}
            isEdit={isEdit}
            countryList={countryList}
          />
        );
      case 234:
        return (
          <GreatBritainBankDetails
            formData={formData}
            formErrors={formErrors}
            handleChange={handleChange}
            isEdit={isEdit}
            countryList={countryList}
          />
        );
      default:
        if (country.region !== null && country.region === "EU") {
          return (
            <EURegionBankDetails
              formData={formData}
              formErrors={formErrors}
              handleChange={handleChange}
              isEdit={isEdit}
              countryList={countryList}
            />
          );
        }
    }
  };

  const handleNameChange = (name, value) => {
    const regex = /^[a-zA-Z\s]*$/; 
    if (regex.test(value)) {
      handleChange(name, value); 
    }
  };

  const handlePhoneChange = (value) => {
    const regex = /^[0-9\b]*$/; 
    if (regex.test(value)) {
      handleChange("phone", value); 
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={3}>
      <Box display={"flex"} flexDirection={"column"}>
        <AccountType
          selectedType={formData.type}
          selectedTypeError={formErrors.error}
          handleInputChange={(e) => handleChange("type", e.target.value)}
          isEdit={isEdit}
        />
        {formErrors.type && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.type}
          </Typography>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        <Typography variant="h6">Recipient details</Typography>
        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            variant="body1"
            fontSize={12}
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
          >
            Country
          </Typography>
          <FormControl fullWidth error={formErrors.country}>
            <Select
              id="country-select"
              value={formData.country ? formData.country.id : "select"}
              onChange={(e) => handleChange("country", e.target.value)}
              name="country"
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 350,
                  },
                },
              }}
              inputProps={{
                sx: {
                  p: "11.5px 14px",
                },
              }}
              disabled={isEdit}
            >
              <MenuItem disabled value="select">
                Select
              </MenuItem>
              {countryList.map((country) => {
                const imageName = country.name
                const imagePath = images[`${imageName?.toLowerCase()}.png`];
                return <MenuItem
                  key={country.id}
                  value={country.id}
                  style={{
                    marginBottom: ".5rem",
                    marginTop: ".3rem",
                    minWidth: 250,
                  }}
                >
                  <img
                    src={imagePath} // Fallback image path if no image is found
                    alt={country.code}
                    style={{
                      width: "15px",
                      marginRight: "10px",
                      fontFamily: "PPMori-Regular",
                    }}
                  />
                  {country.name}
                </MenuItem>
              })}
            </Select>
          </FormControl>
          {formErrors.country && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.country}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
            variant="body1"
            fontSize={12}
          >
            Currency
          </Typography>
          <FormControl fullWidth error={formErrors.currency} disabled={!formData?.country?.id}>
            <Select
              id="currency-select"
              value={formData.currency ? formData.currency.id : "select"}
              onChange={(e) => handleChange("currency", e.target.value)}
              name="currency"
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 350,
                  },
                },
              }}
              inputProps={{
                sx: {
                  p: "11.5px 14px",
                },
              }}
              disabled={isEdit}
            >
              <MenuItem disabled value="select">
                Select
              </MenuItem>
              {currencyList?.map((currency) => {
                const imageName = currency.country || currency.name
                const imagePath = images[`${imageName?.toLowerCase()}.png`];
                return <MenuItem
                  key={currency.id}
                  value={currency.id}
                  style={{
                    marginBottom: ".9rem",
                    marginTop: ".6rem",
                    minWidth: 250,
                  }}
                >
                  {/* <img
                    src={imagePath} // Fallback image path if no image is found
                    alt={currency.code}
                    style={{
                      width: "15px",
                      marginRight: "10px",
                      fontFamily: "PPMori-Regular",
                    }}
                  /> */}
                  <span style={{color: '#000'}}>{currency.symbol}</span>
                  {" "}{currency.code || currency.code_iso_three}
                </MenuItem>
              })}
            </Select>
          </FormControl>
          {formErrors.currency && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.currency}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
            variant="body1"
            fontSize={12}
          >
            Rail
          </Typography>
          <FormControl fullWidth error={formErrors.rail}>
            <Select
              id="currency-select"
              value={formData.rail ? formData.rail : "select"}
              onChange={(e) => handleChange("rail", e.target.value)}
              name="rail"
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 350,
                  },
                },
              }}
              inputProps={{
                sx: {
                  p: "11.5px 14px",
                },
              }}
              disabled={isEdit}
            >
              <MenuItem disabled value="select">
                Select
              </MenuItem>
              {railList?.map((rail) => {
                return <MenuItem
                  key={rail}
                  value={rail}
                  style={{
                    marginBottom: ".9rem",
                    marginTop: ".6rem",
                    minWidth: 250,
                  }}
                >
                  {/* <img
                    src={imagePath} // Fallback image path if no image is found
                    alt={currency.code}
                    style={{
                      width: "15px",
                      marginRight: "10px",
                      fontFamily: "PPMori-Regular",
                    }}
                  /> */}
                  {rail}
                </MenuItem>
              })}
            </Select>
          </FormControl>
          {formErrors.rail && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.rail}
            </Typography>
          )}
        </Box>

        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
            variant="body1"
            fontSize={12}
          >
            Address line 1
          </Typography>
          <TextField
            placeholder="Enter address line 1"
            onChange={(e) => handleChange("addressLine1", e.target.value)}
            value={formData.addressLine1}
            name="addressLine1"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            error={!!formErrors.addressLine1}
          />
          {formErrors.addressLine1 && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.addressLine1}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography variant="body1" fontSize={12}>
            Address line 2
          </Typography>
          <TextField
            placeholder="Enter address line 2"
            onChange={(e) => handleChange("addressLine2", e.target.value)}
            value={formData.addressLine2}
            name="addressLine2"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            error={!!formErrors.addressLine2}
          />
          {formErrors.addressLine2 && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.addressLine2}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
            variant="body1"
            fontSize={12}
          >
            City
          </Typography>
          <TextField
            placeholder="Enter city"
            onChange={(e) => handleChange("city", e.target.value)}
            value={formData.city}
            name="city"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            error={!!formErrors.city}
          />
          {formErrors.city && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.city}
            </Typography>
          )}
        </Box>

        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
            variant="body1"
            fontSize={12}
          >
            State
          </Typography>
          <TextField
            placeholder="Enter state"
            onChange={(e) => handleChange("state", e.target.value)}
            value={formData.state}
            name="state"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            error={!!formErrors.state}
          />
          {formErrors.state && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.state}
            </Typography>
          )}
        </Box>

        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
            variant="body1"
            fontSize={12}
          >
            Postal code
          </Typography>
          <TextField
            placeholder="Enter postal code"
            onChange={(e) => handleChange("postalCode", e.target.value)}
            value={formData.postalCode}
            name="state"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            error={!!formErrors.postalCode}
          />
          {formErrors.postalCode && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.postalCode}
            </Typography>
          )}
        </Box>

        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            variant="body1"
            fontSize={12}
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
          >
            First name
          </Typography>
          <TextField
            placeholder="Enter first name"
            onChange={(e) => handleNameChange("firstName", e.target.value)}
            error={!!formErrors.firstName}
            name="firstName"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            value={formData.firstName}
          />
          {formErrors.firstName && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.firstName}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            variant="body1"
            fontSize={12}
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
          >
            Last name
          </Typography>
          <TextField
            placeholder="Enter last name"
            onChange={(e) => handleNameChange("lastName", e.target.value)}
            value={formData.lastName}
            error={!!formErrors.lastName}
            name="lastName"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
          />
          {formErrors.lastName && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.lastName}
            </Typography>
          )}
        </Box>

        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
            variant="body1"
            fontSize={12}
          >
            Alias (Nickname)
          </Typography>
          <TextField
            placeholder="Enter alias/nickname"
            onChange={(e) => handleNameChange("alias", e.target.value)}
            value={formData.alias}
            name="alias"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            error={!!formErrors.alias}
          />
          {formErrors.alias && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.alias}
            </Typography>
          )}
        </Box>

        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            variant="body1"
            fontSize={12}
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
          >
            Phone number
          </Typography>
          <TextField
            type="tel"
            placeholder="Enter phone number"
            onChange={(e) => handlePhoneChange(e.target.value)}
            error={!!formErrors.phone}
            name="phone"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            value={formData.phone}
          />
          {formErrors.phone && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.phone}
            </Typography>
          )}
        </Box>

        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
            variant="body1"
            fontSize={12}
          >
            Email
          </Typography>
          <TextField
            placeholder="Enter email"
            onChange={(e) => handleChange("email", e.target.value)}
            value={formData.email}
            name="email"
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            error={!!formErrors.email}
          />
          {formErrors.email && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.email}
            </Typography>
          )}
        </Box>

        <Box width={"100%"}>
          <Typography
            fontSize={13}
            color={"rgba(0,0,0,0.8)"}
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
          >
            Date of birth
          </Typography>
          <LocalDatePicker
            value={formData.dateOfBirth}
            onChange={(newDOB) => handleChange("dateOfBirth", newDOB)}
            error={!!formErrors.dateOfBirth}
          />
          {formErrors.dateOfBirth && (
            <Typography  component={"small"} fontSize={12} color={"red"}>
              {formErrors.dateOfBirth}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection={"column"} gap={"2px"}>
          <Typography
            sx={{
              "&::after": {
                content: '"*"',
                color: "red",
              },
            }}
            variant="body1"
            fontSize={12}
          >
            Relationship to recipient
          </Typography>
          <FormControl fullWidth error={formErrors.relationshipToCustomer}>
            <Select
              id="relationshipToCustomer-select"
              value={
                formData.relationshipToCustomer
                  ? formData.relationshipToCustomer
                  : "select"
              }
              onChange={(e) =>
                handleChange("relationshipToCustomer", e.target.value)
              }
              name="relationshipToCustomer"
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 350,
                  },
                },
              }}
              inputProps={{
                sx: {
                  p: "11.5px 14px",
                },
              }}
            >
              <MenuItem disabled value="select">
                Select
              </MenuItem>
              {Object.keys(relationshipToRecipientMap).map((key) => (
                <MenuItem
                  key={key}
                  value={key}
                  style={{
                    marginBottom: ".9rem",
                    marginTop: ".6rem",
                    minWidth: 250,
                  }}
                >
                  {relationshipToRecipientMap[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formErrors.relationshipToCustomer && (
            <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
              {formErrors.relationshipToCustomer}
            </Typography>
          )}
        </Box>
      </Box>

      {formData.country && (
        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
          <Typography variant="h6">Account details</Typography>
          {getBankDetailsForm(formData.country)}
        </Box>
      )}
    </Box>
  );
};

const AccountType = ({
  handleInputChange,
  selectedType,
  isEdit=false,
}) => {
  function StyledRadio(props) {
    return (
      <Radio
        color="primary"
        sx={{
          "&.Mui-checked": {
            color: "#462A9C",
          },
        }}
        {...props}
      />
    );
  }

  return (
    <FormControl>
      <RadioGroup
        row
        onChange={handleInputChange}
        value={selectedType}
        name="type"
      >
        <FormControlLabel
          value="INDIVIDUAL"
          control={<StyledRadio />}
          label="Individual"
          disabled={isEdit}
        />
        <FormControlLabel
          value="BUSINESS"
          control={<StyledRadio />}
          label="Business"
          disabled={isEdit}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default AddRecipientBankAccountForm;
