import React from 'react';

const CustomerAgreement = () => {
    return (
        <div className="customer-agreement">
            <h1>Customer Agreement</h1>
            <p><strong>Last Revised on August 29, 2024</strong></p>

            <p>
                This Customer Agreement ("Agreement") is between you and Layer 2 Financial Inc. ("Layer2," "we," or "us").
                This Agreement governs your use of the services provided by or on behalf of Layer2, as described herein
                (collectively "Services"), including, without limitation, the services and functionality provided through
                our website, technology software, and APIs (collectively "Platform").
            </p>

            <h2>General</h2>
            <h3>Overview of Services</h3>
            <ul>
                <li>Purchase and sell Digital Assets (including for Fiat Currency or other Digital Assets)</li>
                <li>Send and receive Digital Assets and Fiat Currency</li>
                <li>Convert Fiat Currency balances to balances in other supported Fiat Currencies</li>
                <li>For supported Digital Assets and Fiat Currencies, visit <a href="https://docs.layer2financial.com/guides/assets/">this link</a>.</li>
            </ul>

            <h3>Third-Party Platforms</h3>
            <p>Our Services may integrate with certain third-party platforms ("Third-Party Platforms"). You agree that such Third-Party Platforms may have additional terms and conditions.</p>

            <h2>Digital Assets</h2>
            <p>You acknowledge and agree that Digital Asset deposits and withdrawals may be delayed due to service downtimes or congestion on the Digital Asset network. Deposits may require a certain number of network confirmations before being settled in your Layer2 Account. We may set withdrawal limits or minimums for Digital Asset transactions from time to time.</p>

            <h3>Custody Providers</h3>
            <p>
                Layer2 may provide various custody solutions for Digital Assets. These custody solutions may be managed by third-party custodians or service providers. You acknowledge that Layer2 is not responsible for the security or management of Digital Assets held by third-party custodians.
            </p>

            <h2>Accounts</h2>
            <h3>Eligibility</h3>
            <p>
                To use the Services, you must not be a Prohibited Person or reside in a Restricted Jurisdiction. You must
                also be at least 18 years of age or the legal age of majority in your jurisdiction.
            </p>

            <p>
                A "Prohibited Person" refers to anyone subject to economic or trade sanctions administered by governmental
                authorities. You must also comply with the laws of your jurisdiction regarding the use of the Services.
            </p>

            <h3>Creating Your Account</h3>
            <p>
                To use the Services, you must create an account ("Layer2 Account"). You agree to provide accurate,
                complete, and up-to-date information. Notify us of any changes in your residence or place of formation,
                as such changes may affect your eligibility to use the Services.
            </p>

            <h3>Safeguarding Your Account</h3>
            <p>
                You are solely responsible for safeguarding your Layer2 Account and maintaining the confidentiality of
                your password. You must notify us immediately at <a href="mailto:support@layer2financial.com">support@layer2financial.com</a> if you suspect unauthorized use of your account.
            </p>

            <h3>Linked Bank Account</h3>
            <p>
                You may need to link a deposit or asset account you own at another institution to your Layer2 Account. The
                Linked Account is subject to our verification and approval process.
            </p>

            <h3>Layer2 Accounts</h3>
            <p>Each Layer2 Account may have sub-accounts, such as Fiat Currency accounts ("Fiat Account") and Digital Asset accounts ("Digital Asset Account"). Your Digital Assets held in your Digital Asset Account are custodial assets managed by Layer2 for your benefit.</p>

            <h3>Verification</h3>
            <p>Layer2 may require additional verification or documentation as part of the onboarding process or during your use of the Services. You authorize Layer2 to verify your identity and information, which may involve checking commercial databases or credit reports.
            </p>
            <h3>Wallets</h3>
            <p>To use certain Services, you may need to connect a compatible third-party digital wallet ("Wallet"). You acknowledge that Layer2 is not responsible for the security or management of your Wallet or any assets held therein.</p>

            <h3>Account Limits</h3>
            <p>Layer2 may impose transaction or withdrawal limits or place temporary holds on transactions. These restrictions are implemented to protect the security and integrity of the Services.</p>

            <h3>Account Closure</h3>
            <p>You may close your Layer2 Account at any time. However, closing your account will not affect any obligations or liabilities incurred prior to the closure date. You must cancel all open transactions before closing your account. If no transfer instructions are provided for remaining assets, Layer2 may take any legally permitted action to transfer them to you.</p>

            <h3>Actions Against Your Account</h3>
            <p>Layer2 may comply with legal actions such as subpoenas or garnishments, freezing assets in your account if required by law. Fees or expenses incurred in responding to such actions may be charged to your Layer2 Account.</p>
            {/* More sections can be similarly added... */}

            <h2>Fees, Subscriptions, and Taxes</h2>
            <h3>Fees</h3>
            <p>You agree to pay all fees associated with the use of the Services. These fees may include transaction fees, service fees, and subscription fees.</p>

            <h3>Taxes</h3>
            <p>You are responsible for reporting and paying any taxes applicable to your transactions via the Services. Layer2 may withhold tax amounts from your transactions if required by law.</p>

            <h3>Subscription Payment</h3>
            <p>If you subscribe to any of our paid Services, you agree to pay the applicable fees and taxes. Subscriptions will automatically renew unless canceled at least 90 days before the renewal date.</p>

            <h2>Privacy Policy</h2>
            <p>Our Privacy Policy describes how we handle your information. For more information, visit <a href="https://legal.layer2financial.com/legal/privacy/">our Privacy Policy</a>.</p>

            {/* Further sections continue similarly... */}

            <h2>Contact Information</h2>
            <p>
                For any inquiries regarding the Services or this Agreement, contact us at:
            </p>
            <ul>
                <li><strong>Address</strong>: 201 Biscayne Blvd., 28th Floor, Miami, FL 33131</li>
                <li><strong>Phone</strong>: +1 800 270 8770</li>
                <li><strong>Email</strong>: <a href="mailto:support@layer2financial.com">support@layer2financial.com</a></li>
            </ul>
        </div>
    );
};

export default CustomerAgreement;
