import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  isAuthenticated: false,
  profileData: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => ({
      ...state,
      isAuthenticated: action.payload,
    }),
    setProfileData: (state, action) => ({
      ...state,
      profileData: {
        ...state.profileData,
        ...action.payload,
      },
    }),
    resetProfileData: () => ({
      ...initialState,
    }),
  },
});

export const userActions = userSlice.actions;
export const userReducers = persistReducer(
  { key: "user", storage, whitelist: ["isAuthenticated", "profileData"] },
  userSlice.reducer
);
