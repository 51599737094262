import React from "react";
import {
  Box,
  FormControl,
  Select,
  Typography,
  MenuItem,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(
  require.context("../../../Images/flags", false, /\.(png)$/)
);

export const CanadaBankDetails = ({
  formData,
  handleChange,
  formErrors,
  isEdit,
  countryList,
}) => {
  return (
    <>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Account number
        </Typography>
        <TextField
          placeholder="Enter account number"
          onChange={(e) => handleChange("bankAccountNumber", e.target.value)}
          value={formData.bankAccountNumber}
          name="bankAccountNumber"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankAccountNumber}
          disabled={isEdit}
        />
        {formErrors.bankAccountNumber && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankAccountNumber}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Routing number
          <Tooltip
            fontFamily="PPMori-Regular"
            title={
              "Needs institution number (3 digit number) & transit number (5 digit number) which are unique for the destination bank. Combine them (institution number + transit number) and provide into routing number. If  004 is institution number and 00012 is transit number, put like this 00400012"
            }
            arrow
            placement="right"
          >
            <IconButton sx={{ marginLeft: "4px" }}>
              <InfoOutlinedIcon
                sx={{
                  width: "15px",
                  height: "15px",
                }}
              />
            </IconButton>
          </Tooltip>
        </Typography>
        <TextField
          placeholder="Enter routing number"
          onChange={(e) => handleChange("bankRoutingNumber", e.target.value)}
          value={formData.bankRoutingNumber}
          name="bankRoutingNumber"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankRoutingNumber}
          disabled={isEdit}
        />
        {formErrors.bankRoutingNumber && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankRoutingNumber}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Swift BIC code
        </Typography>
        <TextField
          placeholder="Enter Swift BIC code"
          onChange={(e) => handleChange("swiftBic", e.target.value)}
          value={formData.swiftBic}
          name="swiftBic"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.swiftBic}
          disabled={isEdit}
        />
        {formErrors.swiftBic && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.swiftBic}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Account type
        </Typography>
        <FormControl fullWidth error={formErrors.accountType}>
          <Select
            id="country-select"
            value={formData.accountType ? formData.accountType : "select"}
            onChange={(e) => handleChange("accountType", e.target.value)}
            name="accountType"
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 350,
                },
              },
            }}
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            disabled={isEdit}
          >
            <MenuItem disabled value="select">
              Select
            </MenuItem>
            {[
              { value: "CHECKING", label: "Checking" },
              { value: "SAVING", label: "Saving" },
            ].map((accType) => (
              <MenuItem
                key={accType.value}
                value={accType.value}
                style={{
                  marginBottom: ".5rem",
                  marginTop: ".3rem",
                  minWidth: 250,
                }}
              >
                {accType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {formErrors.accountType && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.accountType}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Bank name
        </Typography>
        <TextField
          placeholder="Enter bank name"
          onChange={(e) => handleChange("bankName", e.target.value)}
          value={formData.bankName}
          name="bankName"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankName}
          disabled={isEdit}
        />
        {formErrors.bankName && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankName}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
          variant="body1"
          fontSize={12}
        >
          Address line 1
        </Typography>
        <TextField
          placeholder="Enter address line 1"
          onChange={(e) => handleChange("accountAddressLine1", e.target.value)}
          value={formData.accountAddressLine1}
          name="addressLine1"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.accountAddressLine1}
        />
        {formErrors.accountAddressLine1 && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.accountAddressLine1}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography variant="body1" fontSize={12}>
          Address line 2
        </Typography>
        <TextField
          placeholder="Enter address line 2"
          onChange={(e) => handleChange("accountAddressLine2", e.target.value)}
          value={formData.accountAddressLine2}
          name="addressLine2"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.accountAddressLine2}
        />
        {formErrors.accountAddressLine2 && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.accountAddressLine2}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Country
        </Typography>
        <FormControl fullWidth error={formErrors.accountCountry}>
          <Select
            id="country-select"
            value={
              formData.accountCountry ? formData.accountCountry.id : "select"
            }
            onChange={(e) => handleChange("accountCountry", e.target.value)}
            name="accountCountry"
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 350,
                },
              },
            }}
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            disabled={isEdit}
          >
            <MenuItem disabled value="select">
              Select
            </MenuItem>
            {countryList.map((country) => {
              const imageName = country.name;
              const imagePath = images[`${imageName?.toLowerCase()}.png`];
              return (
                <MenuItem
                  key={country.id}
                  value={country.id}
                  style={{
                    marginBottom: ".5rem",
                    marginTop: ".3rem",
                    minWidth: 250,
                  }}
                >
                  <img
                    src={imagePath} // Fallback image path if no image is found
                    alt={country.code}
                    style={{
                      width: "15px",
                      marginRight: "10px",
                      fontFamily: "PPMori-Regular",
                    }}
                  />
                  {country.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {formErrors.accountCountry && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.accountCountry}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
          variant="body1"
          fontSize={12}
        >
          City
        </Typography>
        <TextField
          placeholder="Enter city"
          onChange={(e) => handleChange("accountCity", e.target.value)}
          value={formData.accountCity}
          name="city"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.accountCity}
        />
        {formErrors.accountCity && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.accountCity}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
          variant="body1"
          fontSize={12}
        >
          State
        </Typography>
        <TextField
          placeholder="Enter state"
          onChange={(e) => handleChange("accountState", e.target.value)}
          value={formData.accountState}
          name="state"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.accountState}
        />
        {formErrors.accountState && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.accountState}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
          variant="body1"
          fontSize={12}
        >
          Postal code
        </Typography>
        <TextField
          placeholder="Enter postal code"
          onChange={(e) => handleChange("accountPostalCode", e.target.value)}
          value={formData.accountPostalCode}
          name="state"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.accountPostalCode}
        />
        {formErrors.accountPostalCode && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.accountPostalCode}
          </Typography>
        )}
      </Box>
    </>
  );
};
