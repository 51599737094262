import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Grid,
  Divider,
  FormLabel,
  IconButton,
  Paper,
  FormHelperText,
  Stack,
  Tooltip,
  Skeleton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "../../Images/Plus.svg";
import { styled } from "@mui/system";
import {
  getTranscation,
  getCurrency,
  getQuote,
  transcation,
} from "../../lib/service/DashboardApi/dashboardApi";
import { getProfile } from "../../lib/service/ProfileApi/profileApi";
import { getAllTranscation } from "../../lib/service/TranscationApi/transcationApi";
import DashboardTransactionTable from "../../components/TableComponent/DashboardTransactionTable";
import "./Dashboard.css";
import ConnectWallet from "../../container/DashboardContainer/ConnectWallet";
import { useAccount, useBalance } from "wagmi";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PopoverList from "../../components/Popover/PopoverList";
import SendMoneyModal from "../../components/ModalComponent/SendMoneyModal/SendMoneyModal";
import { ReactComponent as Send2 } from "../../Images/Send2.svg";
import CardLoader from "../../components/LoaderUI/CardLoaderFax";
import VerificationNotification from "../../components/NotificationBar/VerificationNotification";
import VerificationModal from "../../components/NotificationBar/VerificationModal";
import { useProfile } from "../../context/ProfileContext";
import { getAllAccountsList } from "../../lib/service/accountsApi/accountsApi";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import AddCardIcon from '@mui/icons-material/AddCard';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenAccountModal from "../../components/ModalComponent/OpenAccountModal";
import InternalTransferModal from "../../components/ModalComponent/InternalTransfarModal/InternalTransferModal";
import AddAndEditManualRecipientModal from "../../components/ModalComponent/AddAndEditManualRecipientModal";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/slices/userSlice";
import ChargesModal from "../../components/ModalComponent/CurrencyCalculator/ChargesModal";
import TopUpModal from "../../components/ModalComponent/TopUpModal/TopUpModal";
import { notify } from "../../components/Notification/Notification";
import { getRailList } from "../../lib/service/currencyAndCoutryApi/currencyAndCountryApi";
const toCamelCase = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const AddRecipientButton = styled(Button)({
  backgroundColor: "white",
  color: "#462A9C",
  textTransform: "none",
  fontFamily: "PPMori-Regular",
  "&:hover": {
    backgroundColor: "white",
  },
});

const SendMoneyButton = styled(Button)({
  backgroundColor: "#462A9C",
  color: "#FFFFFF",
  textTransform: "none",
  fontFamily: "PPMori-Regular",
  "&:hover": {
    backgroundColor: "#462A9C",
  },
});



const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));

const flagImages = importAll(
  require.context("../../Images/flags", false, /\.(png)$/)
);

const usdcToUsdConversionRate = 0.9991;
const usdtToUsdConversionRate = 1.0;

const Dashboard = () => {
  const [sendAmount, setSendAmount] = useState("");
  const [depositeType, setDepositeType] = useState('BANK_TRANSFER')
  const [receiveAmount, setReceiveAmount] = useState("");
  const [amountValid, setAmountValid] = useState("");
  const [sendCurrency, setSendCurrency] = useState("USDC");
  const [receiveCurrency, setReceiveCurrency] = useState("INR");
  const [transactions, setTransactions] = useState([]);
  const [cardDetails, setCardDetails] = useState([
    {
      title: "Combined balance: crypto + fiat",
      amount: "0.00",
      updateInfo: "Live update",
    },
    { title: "Last payment", amount: "0.00", updateInfo: "24 Jan, 2024" },
    {
      title: "Transactions this month",
      amount: "0",
      updateInfo: "1 Jan - 24 Jan",
    },
    { title: "Pending transactions", amount: "0", updateInfo: "Live update" },
  ]);
  const [showTransactions, setShowTransactions] = useState(false);
  // const [recipent, setRecipent] = useState([]);
  const [sourceRegularCurrencies, setSourceRegularCurrencies] = useState([]);
  const [destinationRegularCurrencies, setDestinationRegularCurrencies] =
    useState([]);
  const [transactionsList, setTransactionsList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [loadingQuote, setLoadingQuote] = useState(false);
  const [chargeTotal, setchargeTotal] = useState(0);
  const [totalFeesCurrency, setTotalFeesCurrency] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showVerification, setShowVerification] = useState(null);
  const [showVerificationLoader, setshowVerificationLoader] = useState(false);
  const [userType, setUserType] = useState('');
  // const [countryList, setCountryList] = useState([]);
  const [usdcBalance, setUsdcBalance] = useState(null);
  const [usdtBalance, setUsdtBalance] = useState(null);
  const [showQuoteError, setShowQuoteError] = useState("");

  const [exchangeRate, setExchangeRate] = useState("");
  const [chargesFee, setChargesFees] = useState([]);
  const [accountsData, setAccountsData] = useState(null);
  const [apiCallAccount, setApiCallAccount] = useState(false);
  const [showTotalAccountBalance, setShowTotalAccountBalance] = useState(true);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [internalTransferModalOpen, setInternalTransferModalOpen] = useState(false);
  const [topUpModalOpen, setTopUpModalOpen] = useState(false);
  const [showCharges, setshowCharges] = useState(false);
  const [isAccountOpenLoading, setIsAccountOpenLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { address, isConnected } = useAccount();
  const { data } = useBalance({
    address: address,
  });
  const [addRecipientsPopoverEle, setaAddRecipientsPopoverEle] = useState(null);
  const [openSendMoneyModal, setOpenSendMoney] = useState(false);
  const [kycInfo, setkycInfo] = useState({});
  const [addRecipientModalOpen, setAddRecipientModalOpen] = useState(false);
  const { profile, fullName, previewUrl } = useProfile();
  const [railList, setRailList] = useState([]);
  const [rail, setRail] = useState("");
  const [reciveAmountValid, setRecieveAmountValid] = useState("");
  const [amountToConvert , setAmountToConvert] = useState("");

  const getuserTranscation = useCallback(async () => {
    try {
      const response = await getTranscation();
      if (response.data) {
        setTransactions(response.data);
        if(response.data.totalBalanceError){
          notify.warning({
            message: response.data.totalBalanceError,
            description: "Unable to fetch the balance. Please try again in a moment."
          })
        }
      }
    } catch (error) {
      console.log("Error Object", error);
    }
  }, []);

  const fetchCurrencyList = useCallback(async () => {
    try {
      const response = await getCurrency({});
      const { source, destination } = response.data;
      setSourceRegularCurrencies(source.currency);
      setDestinationRegularCurrencies(destination.currency);
    } catch (error) {
      console.error("Error fetching currency list:", error);
    }
  }, []);
  const getAllTranscationList = useCallback(async () => {
    try {
      const data = {
        page: 0,
        size: 5,
        dateRange: "ALL_TIME",
        sortBy: "createdOn,desc"
      }
      const response = await getAllTranscation(data);
      if (response.data && response.data["txns"].length) {
        setTransactionsList(response.data["txns"]);
        setLoading(false);
        setShowTransactions(true);
      } else {
        setTransactionsList([]);
      }
    } catch (error) {
      console.log("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchRailList = async (currency) => {
    try {
      const response = await getRailList(currency);
      setRailList(
        response.data.railsList
      );
    } catch (error) {
      console.error("Error fetching rail list:", error);
    }
  };

  const showCombinedBalance = (transactions) => {
    const totalBalance = transactions.totalBalance ? (transactions.totalBalance) : '0';
    const usdcBalanceUsd = usdcBalance != null ? (usdtBalance * usdcToUsdConversionRate) : 0;

    const usdtBalanceUsd = usdtBalance != null ? (usdcBalance * usdtToUsdConversionRate) : 0;

    const combinedTotal = ((usdcBalanceUsd) + (usdtBalanceUsd) + (totalBalance));
    return isConnected ? `${parseFloat(parseFloat(combinedTotal)?.toFixed(2))?.toLocaleString()} USD` : `${totalBalance?.toLocaleString()} USD`;
  }

  const fetchQuote = useCallback(async (amount, destinationAmount, sendCurrency, receiveCurrency, rail) => {
    setLoadingQuote(true);
    setExchangeRate("");
    setchargeTotal(0);

    try {
      const payload = {
        partnerId: "1112",
        depositType: 'CRYPTO_WALLET',
        accountType: "INDIVIDUAL",
        source: {
          currency: sendCurrency,
        },
        destination: {
          currency: receiveCurrency,
        },
        recipientReceiveMode: rail || "SWIFT_IN",
      }
      if (amount) {
        payload.source.amount = Number(amount?.split(',')?.join(''));
      }
      if (destinationAmount) {
        payload.destination.amount = Number(destinationAmount?.split(',')?.join(''));
      }
      const response = await getQuote(payload);

      if (response.code === 200) {
        const { destination, fxRate, feeList, feeTotal, feeCurrency, source } = response.data;
        setExchangeRate(fxRate);
        setChargesFees(feeList);
        setReceiveAmount(Number(parseFloat(destination?.amount)?.toFixed(2))?.toLocaleString());
        setchargeTotal(feeTotal);
        setShowQuoteError("");
        setAmountToConvert(source?.amountToConvert);
        setSendAmount(Number(parseFloat(source?.amount)?.toFixed(2))?.toLocaleString());
        setTotalFeesCurrency(feeCurrency)
      } else {
        setExchangeRate(0);
        // setReceiveAmount("");
        setChargesFees([]);
        setchargeTotal(0);
        setShowQuoteError(response.errors[0]?.errDesc);
        setTotalFeesCurrency('')
        setAmountToConvert("");
      }
    } catch (error) {
      if(error && error?.data){
        const { source, destination } = error?.data;
        if(source?.amount === null){
          setSendAmount('')
        }else{
          setSendAmount(Number(parseFloat(source?.amount)?.toFixed(2))?.toLocaleString());
        }
        if(destination?.amount === null){
          setReceiveAmount("")
        }else{
          setReceiveAmount(Number(parseFloat(destination?.amount)?.toFixed(2))?.toLocaleString());
        }
      }
      setExchangeRate(0);
      setChargesFees([]);
      setShowQuoteError(error?.errors?.[0]?.errDesc);
      setchargeTotal(0);
      setTotalFeesCurrency('')
      console.error("Error fetching quote:", error);
      setAmountToConvert("");
    } finally {
      setLoadingQuote(false);
    }
  }, []);


  useEffect(() => {
    getuserTranscation();
    fetchCurrencyList();
    getAllTranscationList();
    fetchProfileData();
    fetchAllAccounts();
    fetchRailList(receiveCurrency)
  }, []);

  const fetchProfileData = useCallback(async () => {
    try {
      setshowVerificationLoader(true);
      const response = await getProfile();
      if (response.data) {
        const { kycInfo, userType } = response.data;
        setkycInfo(kycInfo);
        setUserType(userType);
        setshowVerificationLoader(false);
        if (kycInfo.showNotification) {
          setShowVerification(true);
        } else {
          setShowVerification(false);
        }
      }
    } catch (error) {
      setshowVerificationLoader(false);
      console.error("Error fetching profile data:", error);
    }
  }, []);

  // Update card details with API response data
  useEffect(() => {
    if (transactions) {
      const updatedCardDetails = [
        {
          title: "Combined balance: crypto + fiat",
          amount: showCombinedBalance(transactions),
          updateInfo: "Live update",
        },
        {
          title: "Last payment",
          amount: transactions.lastTxAmount ? `${(transactions.lastTxAmount)?.toLocaleString()} ${transactions?.lastTxCurrency || "USDC"}` : `0 ${transactions?.lastTxCurrency || "USDC"}`,
          updateInfo: transactions.lastTxDate ?? "",
        },
        {
          title: "Transactions this month",
          amount: transactions.txCountThisMonth ? transactions.txCountThisMonth : 0,
          updateInfo: transactions.thisMonthDateRange ?? "",
        },
        {
          title: "Pending transactions",
          amount: transactions?.pendingTxCount ? transactions.pendingTxCount : 0,
          updateInfo: "Live update",
        },
      ];
      setCardDetails(updatedCardDetails);
    }
    dispatch(userActions.setProfileData({
      usdt: usdcBalance,
      usdc: usdtBalance,
    }));
  }, [transactions, isConnected, usdcBalance, usdtBalance]);

const handleSendAmountChange = (event) => {
  setReceiveAmount("");
  setExchangeRate(0);
  setChargesFees([]);
  setchargeTotal(0);
  setRail("");
  setTotalFeesCurrency("");

  const rawValue = event.target.value.replace(/,/g, '');
  const numericRegex = /^\d*\.?\d{0,2}$/;
  
  if (!numericRegex.test(rawValue)) {
    setAmountValid("Only numeric values with up to 2 decimal places are allowed.");
    return;
  }

  let formattedValue = rawValue;
  if (rawValue) {
    const parts = rawValue?.split('.');
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    formattedValue = parts?.join('.');
  }

  setSendAmount(formattedValue);

  if (!rawValue || isNaN(rawValue)) {
    setReceiveAmount("");
    setExchangeRate(0);
    setSendAmount("");
    setRail("");
    setReceiveAmount("");
    setChargesFees([]);
    setchargeTotal(0);
    setTotalFeesCurrency('');
    
  } else {
    setShowQuoteError("");
  }
};

const handleReciveAmountChange = (event) => {
  setSendAmount("");
  setExchangeRate(0);
  setChargesFees([]);
  setchargeTotal(0);
  setTotalFeesCurrency("");
  setRail("");
  
  const rawValue = event.target.value.replace(/,/g, "");
  const numericRegex = /^\d*\.?\d{0,2}$/;

  if (!numericRegex.test(rawValue)) {
    setRecieveAmountValid(
      "Only numeric values with up to 2 decimal places are allowed."
    );
    return;
  }

  let formattedValue = rawValue;
  if (rawValue) {
    const parts = rawValue?.split(".");
    parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    formattedValue = parts?.join(".");
  }

  setReceiveAmount(formattedValue);

  if (!rawValue || isNaN(rawValue)) {
    setExchangeRate(0);
    setSendAmount("");
    setChargesFees([]);
    setchargeTotal(0);
    setShowQuoteError("");
    setTotalFeesCurrency("");
  } else {
    setShowQuoteError("");
  }
};

  const handleSendCurrencyChange = (event) => {
    setSendCurrency(event.target.value);
    setExchangeRate(0);
    setRail("");
    setSendAmount("");
    setReceiveAmount("");
    setChargesFees([]);
    setchargeTotal(0);
    setTotalFeesCurrency('')
  };

  const handleReceiveCurrencyChange = (event) => {
    const value = event.target.value;
    setReceiveCurrency(value);
    fetchRailList(value);
    setRail("");
    setSendAmount("");
    setExchangeRate(0);
    setReceiveAmount("");
    setChargesFees([]);
    setchargeTotal(0);
    setTotalFeesCurrency('')
  };

  const handleRailChange = (event) => {
    const value = event.target.value;
    setRail(value);
    setExchangeRate(0);
    setChargesFees([]);
    setchargeTotal(0);
    setTotalFeesCurrency('')
    if(chargesFee?.length){
      if(prevSendAmount === sendAmount){
        setSendAmount(sendAmount)
        setReceiveAmount("")
      } 
      if(prevRecieveAmount === receiveAmount){
        setReceiveAmount(receiveAmount)
        setSendAmount('')
      }
    }
  }

  const toggleAddRecipientModal = () => setAddRecipientModalOpen(!addRecipientModalOpen);

  const AddRecipientsPopoverOptions = [
    {
      label: "Add recipient manually",
      onClick: toggleAddRecipientModal,
      icon: <PersonAddAltOutlinedIcon />,
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchAllAccounts = async () => {
    try {
      setApiCallAccount(true);
      const res = await getAllAccountsList();
      setAccountsData(res.data);
      setApiCallAccount(false);
    } catch (error) {
      setApiCallAccount(false);
      console.log(`Error while fetching accounts in dashboard`, error)
    }
  }

  const onAccountOpen = async () => {
    setIsAccountOpenLoading(true);
    try {
      navigate('/accounts');
    } catch (error) {
      console.log(error);
    } finally {
      setIsAccountOpenLoading(false);
    }
  };

  const sendCurrencySymbol = () => {
    const currentCurremcy = sourceRegularCurrencies.find((item) => item.code === sendCurrency);
    return currentCurremcy?.symbol || "$";
  }

  const receipentsCurrencySymbol = () => {
    const currentCurremcy = destinationRegularCurrencies.find((item) => item.code === receiveCurrency);
    return currentCurremcy?.symbol || "$";
  }

  const handleOpenTop = () => {
    if (accountsData?.accounts?.length)
      setTopUpModalOpen(true)
    else if (!apiCallAccount)
      notify.warning({
        message: 'Please open an account to access the top-up feature'
      })
    else
      console.log("")
  }

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevSendAmount = usePrevious(sendAmount);
  const prevRecieveAmount = usePrevious(receiveAmount);

  return (
    <>
      {openSendMoneyModal && (
        <SendMoneyModal
          open={openSendMoneyModal}
          onClose={() => {
            setOpenSendMoney(false);
            getuserTranscation();
            getAllTranscationList();
          }}
          addNewTransaction={() => getAllTranscationList()}
        />
      )}
      {addRecipientModalOpen && (
        <AddAndEditManualRecipientModal
          open={addRecipientModalOpen}
          onClose={toggleAddRecipientModal}
          addNewRecipient={(data) => navigate("/recipients")}
        />
      )}
      <Box sx={{ mt: 0, mb: 4 }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          padding={3}
          gap={2}
          sx={{ backgroundColor: "#F6F5F9" }}
        >
          {kycInfo &&
            Object.keys(kycInfo).length > 0 &&
            !showVerificationLoader &&
            !showVerification && (
              <>
                <VerificationNotification
                  statusLink={kycInfo}
                  onOpenModal={handleOpenModal}
                />
                <VerificationModal
                  open={isModalOpen}
                  statusLink={kycInfo}
                  onClose={handleCloseModal}
                  fetchProfileData={fetchProfileData}
                  userType={userType}
                />
              </>
            )}
          <Typography
            variant="h3"
            fontSize="26px"
            style={{ color: "#0F0F0F", fontFamily: "PPMori-Regular" }}
          >
            Welcome, {toCamelCase(profile.firstName)}
          </Typography>
          <Stack
            spacing={2}
            flexDirection={{ xs: "column", lg: "row" }}
            alignItems={{ xs: "flex-start", lg: "center" }}
            sx={{
              fontSize: "0.75rem",
              padding: "6px",
              gap: { xs: 2, sm: 2 },
            }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              width={{ xs: "100%", lg: "70%" }}
            >
              {cardDetails.map((card, index) => (
                <Box
                  key={index}
                  display="flex"
                  gap={2}
                  sx={{ minWidth: { xs: "100%", sm: "9.375rem" } }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography fontSize="0.875rem" color="#0F0F0F">
                      {card.title}
                    </Typography>
                    {index === 0 ? (
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography color="#0F0F0F" fontSize="1.125rem">
                          {showCombinedBalance(transactions)}
                        </Typography>
                        {/* <IconButton
                          onClick={() => setShowTotalAccountBalance(!showTotalAccountBalance)}
                        >
                          {showTotalAccountBalance ? <Visibility /> : <VisibilityOff />}
                        </IconButton> */}
                      </Box>
                    ) : (
                      <Typography color="#0F0F0F" fontSize="1.125rem">
                        {card.amount}
                      </Typography>
                    )}
                    <Typography color="#0F0F0F" fontSize="0.75rem">
                      {card.updateInfo}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" />
                </Box>
              ))}
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              alignItems="center"
              width={{ xs: "100%", lg: "30%" }}
            >
              <AddRecipientButton
                variant="outlined"
                color="primary"
                disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
                startIcon={
                  <Plus
                    style={{
                      color:
                        profile?.kycInfo?.kycStatus !== "COMPLETED"
                          ? "grey"
                          : "inherit",
                      stroke:
                        profile?.kycInfo?.kycStatus !== "COMPLETED"
                          ? "grey"
                          : "#462A9C",
                    }}
                  />
                }
                onClick={(e) => setaAddRecipientsPopoverEle(e.currentTarget)}
                sx={{ width: { xs: "100%" } }}
              >
                Add recipient
              </AddRecipientButton>
              <PopoverList
                open={Boolean(addRecipientsPopoverEle)}
                anchorEl={addRecipientsPopoverEle}
                handleClose={() => setaAddRecipientsPopoverEle(null)}
                options={AddRecipientsPopoverOptions}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              />
              <SendMoneyButton
                variant="contained"
                startIcon={<Send2 />}
                disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
                onClick={() => setOpenSendMoney(true)}
                sx={{ width: { xs: "100%" } }}
              >
                Send money
              </SendMoneyButton>
            </Stack>
          </Stack>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            padding: 2.5,
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
          gap={2}
        >
          <Paper
            variant="outlined"
            sx={{
              height: "350px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              padding={2}
            >
              <Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography
                    variant="h6"
                    fontSize="13px"
                    fontFamily="PPMori-Regular"
                  >
                    Stablecoin balance
                  </Typography>
                  {/* <IconButton
                    onClick={() => setShowTotalAccountBalance((prev) => !prev)}
                  >
                    {showTotalAccountBalance ? <Visibility /> : <VisibilityOff />}
                  </IconButton> */}
                </Box>
                <Typography
                  variant="h5"
                  fontSize="18px"
                  fontFamily="PPMori-Regular"
                >
                  {profile?.kycInfo?.kycStatus === "COMPLETED" && data ? (
                    <>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Box>
                          <Typography variant="h5" fontSize="18px">
                              <>
                                {usdcBalance !== null && (
                                  <Box display="flex" alignItems="center" gap={1}>
                                    <img
                                      src={require("../../Images/usdt.png")}
                                      alt="USDT"
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <span>USDT: {usdcBalance?.toLocaleString()}</span>
                                  </Box>
                                )}
                                {usdcBalance !== null && (
                                  <Box display="flex" alignItems="center" gap={1}>
                                    <img
                                      src={require("../../Images/usdc.png")}
                                      alt="USDC"
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <span>USDC: {usdtBalance?.toLocaleString()}</span>
                                  </Box>
                                )}
                              </>
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    "0"
                  )}
                </Typography>
              </Box>
              {profile?.kycInfo?.kycStatus === "COMPLETED" && (
                <ConnectWallet
                  usdcBalance={usdcBalance}
                  usdtBalance={usdtBalance}
                  setUsdcBalance={setUsdcBalance}
                  setUsdtBalance={setUsdtBalance}
                />
              )}
            </Box>
            <Divider />
            <Box flexGrow={1} padding={2}>
              <Box height={"100%"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexDirection={"row"}
                >
                  <Stack>
                    <Typography sx={{ fontSize: "0.875rem" }}>
                      Total account balance
                    </Typography>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Typography variant="h5">
                        {(accountsData?.total_accounts_balance)?.toLocaleString()}{" "}
                        {accountsData?.currency ?? "USD"}
                      </Typography>
                      {/* <IconButton
                        onClick={() =>
                          setShowTotalAccountBalance((prev) => !prev)
                        }
                      >
                        {showTotalAccountBalance ? (
                          <Visibility fontSize="small" />
                        ) : (
                          <VisibilityOff fontSize="small" />
                        )}
                      </IconButton> */}
                    </Box>
                    {accountsData?.accounts?.length > 1 && (
                      <Typography sx={{ fontSize: "0.6875rem" }}>
                        among {accountsData?.accounts?.length} account(s)
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <IconButton
                        sx={{
                          border: "1px solid #462A9C",
                          borderRadius: "4px",
                          padding: "4px",
                          height: "25px",
                          width: "25px",
                          stroke: "#462A9C",
                        }}
                        disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
                        onClick={() => setOpenAccountModal(true)}
                      >
                        <Plus />
                      </IconButton>
                      <Typography align="center" sx={{ fontSize: "0.625rem" }}>
                        Add
                        <br />
                        account
                      </Typography>
                    </Stack>
                    {/* <Stack justifyContent={"center"} alignItems={"center"} gap={1}>
                      <IconButton
                        sx={{
                          border: "1px solid #462A9C",
                          borderRadius: "4px",
                          padding: "4px",
                          height: "25px",
                          width: "25px",
                        }}
                        disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
                        onClick={() => setInternalTransferModalOpen(true)}
                      >
                        <SyncAltIcon sx={{ color: "#462A9C", fontSize:"12px" }} />
                      </IconButton>
                      <Typography align="center" sx={{ fontSize: "10px" }}>Internal<br/>Transfer</Typography>
                    </Stack> */}
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <IconButton
                        sx={{
                          border: "1px solid #462A9C",
                          borderRadius: "4px",
                          padding: "4px",
                          height: "25px",
                          width: "25px",
                          cursor: "pointer",
                        }}
                        onClick={handleOpenTop}
                        disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
                      >
                        <AddCardIcon
                          sx={{ color: "#462A9C", fontSize: "12px" }}
                        />
                      </IconButton>
                      <Typography align="center" sx={{ fontSize: "10px" }}>
                        Top up
                        <br />
                        account
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  display={"flex"}
                  flex={1}
                  mt={2}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={1}
                  sx={{ overflowX: "auto" }}
                >
                  {accountsData?.accounts?.length > 0 ? (
                    accountsData?.accounts?.slice(0, 3).map((item) => {
                      const imagePath =
                        flagImages[`${item?.currency?.toLowerCase()}.png`] ||
                        "";
                      return (
                        <Paper
                          key={item?.currency} // add key to avoid warning
                          variant="outlined"
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: 2,
                            padding: "8px 10px",
                            width: "100%",
                            maxWidth: { xs: "100%", sm: "50%", md: "100%" , lg:"33.33%"},
                          }}
                        >
                          <img
                            src={imagePath}
                            alt={item?.currency}
                            style={{
                              width: "30px",
                              fontFamily: "PPMori-Regular",
                              backgroundColor: "#F8F8F7",
                              padding: 5,
                              borderRadius: "5px",
                            }}
                          />
                          <Stack>
                            <Stack
                              flexDirection={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={1}
                            >
                              <Typography sx={{ fontSize: "0.9375rem" }}>
                                {item?.currency}
                              </Typography>
                              <Typography sx={{ fontSize: "0.9375rem" }}>
                                {(item?.balance)?.toLocaleString() ?? 0}
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{ fontSize: "0.6875rem", color: "#92918E" }}
                            >
                              {item?.name}
                            </Typography>
                          </Stack>
                        </Paper>
                      );
                    })
                  ) : (
                    <Paper
                      variant="outlined"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: 2,
                        padding: "8px 10px",
                        width: "100%",
                        maxWidth: { xs: "100%", sm: "50%", md: "100%" , lg:"33.33%"},
                      }}
                    >
                      <img
                        src={require("../../Images/flags/usd.png")}
                        alt="USD"
                        style={{
                          width: "30px",
                          fontFamily: "PPMori-Regular",
                          backgroundColor: "#F8F8F7",
                          padding: 5,
                          borderRadius: "5px",
                        }}
                      />
                      <Stack>
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          gap={1}
                        >
                          <Typography sx={{ fontSize: "0.9375rem" }}>
                            USD
                          </Typography>
                          <Typography sx={{ fontSize: "0.9375rem" }}>
                            -
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{ fontSize: "0.6875rem", color: "#92918E" }}
                        >
                          USD Account
                        </Typography>
                      </Stack>
                    </Paper>
                  )}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      profile?.kycInfo?.kycStatus === "COMPLETED"
                        ? navigate("/accounts")
                        : console.log("abc");
                    }}
                  >
                    {accountsData?.accounts &&
                      accountsData?.accounts?.length > 3 ? (
                      <Paper
                        variant="outlined"
                        sx={{
                          padding: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.8125rem",
                            fontWeight: "semi-bold",
                          }}
                        >
                          +{" "}
                          {accountsData?.accounts &&
                            accountsData?.accounts?.length > 3
                            ? accountsData.accounts.length - 3
                            : 0}
                        </Typography>
                      </Paper>
                    ) : null}
                    {accountsData?.accounts?.length > 1 && (
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "semi-bold",
                          marginLeft: "5px",
                          textDecoration:
                            accountsData?.accounts &&
                              accountsData?.accounts?.length > 3
                              ? null
                              : "underline",
                        }}
                      >
                        See all accounts
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              height: "350px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "16px",
              gap: "5px",
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
              Currency Calculator
            </Typography>

            <Box container spacing={0} alignItems="center" gap={1}>
              <FormLabel
                style={{
                  fontSize: "0.75rem",
                  fontFamily: "PPMori-Regular",
                }}
              >
                You send
              </FormLabel>
              <Box
                display={"flex"}
                gap={1}
                container
                spacing={0}
                alignItems="center"
              >
                <TextField
                  fullWidth
                  type="text"
                  value={sendAmount }
                  onChange={handleSendAmountChange}
                  variant="outlined"
                  placeholder={`${sendCurrencySymbol()} 0.00`}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    style: {
                      height: "10px",
                      fontSize: "0.75rem",
                      fontFamily: "PPMori-Regular",
                      borderColor: amountValid ? "initial" : "red",
                    },
                  }}
                  onKeyDown={(e) => {
                    if (
                      !(
                        e.key === "Backspace" ||
                        e.key === "Tab" ||
                        e.key === "ArrowLeft" ||
                        e.key === "ArrowRight" ||
                        e.key === "Delete" ||
                        (e.key >= "0" && e.key <= "9") ||
                        (e.key === "." && !e.target.value.includes("."))
                      )
                    ) {
                      e.preventDefault();
                    }
                  }}
                  autoComplete="off"
                />
                <TextField
                  select
                  value={sendCurrency}
                  onChange={handleSendCurrencyChange}
                  variant="outlined"
                  sx={{ width: "150px" }}
                  SelectProps={{
                    style: {
                      height: "45px",
                      fontSize: "0.75rem",
                      fontFamily: "PPMori-Regular",
                    },
                  }}
                  autoComplete="off"
                >
                  {sourceRegularCurrencies.map((currency, index) => {
                    const countryCode = currency.isCrypto
                      ? currency.code.toLowerCase()
                      : currency.country.toLowerCase();

                    // Construct the image path
                    const imagePath = images[`${countryCode}.png`];

                    return (
                      <MenuItem key={currency.code} value={currency.code}>
                        <img
                          src={imagePath}
                          alt={currency.code}
                          style={{
                            width: "15px",
                            marginRight: "10px",
                            fontFamily: "PPMori-Regular",
                          }}
                        />
                        {currency.code}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
              {showQuoteError !== "" && (
                <FormHelperText error>{showQuoteError}</FormHelperText>
              )}
            </Box>

            <Box container spacing={0} alignItems="center">
              <FormLabel style={{ fontSize: "0.75rem" }}>
                Recipient gets
              </FormLabel>
              <Box
                display={"flex"}
                gap={1}
                container
                spacing={0}
                alignItems="center"
              >
                <TextField
                  fullWidth
                  type="text"
                  value={receiveAmount }
                  onChange={handleReciveAmountChange}
                  variant="outlined"
                  placeholder={`${receipentsCurrencySymbol()} 0.00`}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    style: {
                      height: "10px",
                      fontSize: "0.75rem",
                      fontFamily: "PPMori-Regular",
                      color: "black",
                      WebkitTextFillColor: "black",
                      borderColor: reciveAmountValid ? "initial" : "red",
                    },
                  }}
                  autoComplete="off"
                />

                <TextField
                  select
                  value={receiveCurrency}
                  onChange={handleReceiveCurrencyChange}
                  variant="outlined"
                  sx={{ width: "150px" }}
                  SelectProps={{
                    style: {
                      height: "45px",
                      fontSize: "0.75rem",
                      fontFamily: "PPMori-Regular",
                    },
                  }}
                >
                  {destinationRegularCurrencies.map((currency) => {
                    return (
                      <MenuItem key={currency.code} value={currency.code}>
                        <img
                          src={
                            images[`${currency.code.toLowerCase()}.png`] ||
                            images[`${currency.country.toLowerCase()}.png`]
                          }
                          alt={currency.code}
                          style={{
                            width: "15px",
                            marginRight: "10px",
                            fontFamily: "PPMori-Regular",
                          }}
                          onClick={handleSendAmountChange}
                        />
                        {currency.code}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            </Box>

            <Box container spacing={0} alignItems="center" mb={1}>
              <FormLabel style={{ fontSize: "0.75rem" }}>
                Payment rail
              </FormLabel>
              <Box
                display={"flex"}
                gap={1}
                container
                spacing={0}
                alignItems="center"
              >
                <TextField
                  select
                  value={rail}
                  onChange={handleRailChange}
                  variant="outlined"
                  sx={{ flex: 1 }}
                  SelectProps={{
                    style: {
                      height: "45px",
                      fontSize: "0.75rem",
                      fontFamily: "PPMori-Regular",
                    },
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (selected.length === 0) {
                        return (
                          <span
                            style={{
                              fontSize: "0.75rem",
                              fontFamily: "PPMori-Regular",
                              color: "#c9c3c3",
                            }}
                          >
                            Select payment rail
                          </span>
                        );
                      }
                      return selected;
                    },
                  }}
                >
                  {railList.map((currency) => {
                    return (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    );
                  })}
                </TextField>

                <SendMoneyButton
                  variant="contained"
                  disabled={!rail}
                  onClick={() => fetchQuote(sendAmount, receiveAmount, sendCurrency, receiveCurrency, rail)}
                  sx={{ width: { xs: 105, lg: 115 }, padding: "10px 0px" }}
                >
                  Get quote
                </SendMoneyButton>
              </Box>
            </Box>

            <Box style={{ maxHeight: "100vh" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "0.875rem",
                      fontFamily: "PPMori-Regular",
                    }}
                  >
                    Exchange rate
                  </Typography>
                  <>
                    <Box display={"flex"} alignItems={"centre"}>
                      <Typography
                        variant="body1"
                        style={{
                          fontSize: "14px",
                          fontFamily: "PPMori-Regular",
                        }}
                      >
                        Total fees
                      </Typography>
                    </Box>
                    {loadingQuote ? (
                      <Skeleton variant="text" width={80} height={20} />
                    ) : chargesFee.length ? (
                      <span
                        onClick={() => setshowCharges(true)}
                        style={{
                          cursor: "pointer",
                          color: "#462A9C",
                          fontSize: "12px",
                          textDecoration: 'underline'
                        }}
                      >
                        Show more
                      </span>
                    ) : null}
                  </>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  {loadingQuote ? (
                    <Box display="flex" justifyContent="flex-end">
                      <Skeleton variant="text" width={100} height={20} />
                    </Box>
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "0.875rem",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                      {chargesFee.length
                        ? `1 ${sendCurrency} = ${exchangeRate} ${receiveCurrency}`
                        : 0}
                    </Typography>
                  )}
                  {loadingQuote ? (
                    <Box display="flex" justifyContent="flex-end">
                      <Skeleton variant="text" width={100} height={20} />
                    </Box>
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "0.875rem",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                      {showQuoteError ? 0 : <>{chargeTotal}</>}{" "}
                      {showQuoteError ? null : totalFeesCurrency}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Box
            py={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="p">Recent transactions</Typography>
            <Button
              variant="text"
              style={{
                backgroundColor: "white",
                color: "#462A9C",
                fontSize: "0.75rem",
                fontFamily: "PPMori-Regular",
                textTransform: "none",
                fontWeight: "bold",
              }}
              disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
              onClick={() => {
                navigate("/transactions");
              }}
            >
              See all
            </Button>
          </Box>
          <Paper variant="outlined">
            <DashboardTransactionTable
              rows={transactionsList}
              page={page}
              rowsPerPage={rowsPerPage}
              loading={loading}
              onNoTransactionYetClick={setOpenSendMoney}
              currencyList={sourceRegularCurrencies}
            />
          </Paper>
        </Grid>
      </Box>
      {openAccountModal && (
        <OpenAccountModal
          open={openAccountModal}
          onClose={() => setOpenAccountModal(false)}
          onAccountOpen={onAccountOpen}
        />
      )}
      {internalTransferModalOpen && (
        <InternalTransferModal
          open={internalTransferModalOpen}
          onClose={() => setInternalTransferModalOpen(false)}
        />
      )}
      {topUpModalOpen && (
        <TopUpModal
          open={topUpModalOpen}
          onClose={() => setTopUpModalOpen(false)}
          accountDetails={
            accountsData?.accounts?.length
              ? accountsData?.accounts[0]
              : accountsData
          }
        />
      )}
      {showCharges && (
        <ChargesModal
          chargesFee={chargesFee}
          chargeTotal={chargeTotal}
          showCharges={showCharges}
          onClose={() => setshowCharges(false)}
          sendAmount={sendAmount}
          sendCurrency={sendCurrency}
          receiveAmount={receiveAmount}
          receiveCurrency={receiveCurrency}
          totalFeesCurrency={totalFeesCurrency}
          exchangeRate={exchangeRate}
          amountToConvert={amountToConvert}
        />
      )}
    </>
  );
};

export default Dashboard;
