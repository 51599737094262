import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  Tooltip,
} from '@mui/material';
import { triggerMail } from '../../lib/service/DashboardApi/dashboardApi';
import { notify } from '../Notification/Notification';

const VerificationModal = ({ open, onClose, statusLink, fetchProfileData,userType }) => {
  const [loading, setLoading] = useState(false);

  const handleGenerateKYC = async () => {
    setLoading(true);
    console.log(statusLink, 'statusLink')
    try {
      if (statusLink.initiateKycMailSent === false) {
        await triggerMail();
        await fetchProfileData();
        onClose();
        notify.success({
          message: `Successfully sent you a message`,
        });
      }
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (e) => {
    if (e?.errors?.length > 0) {
      e.errors.forEach((err) =>
        notify.error({
          message: err.errMsg,
          description: err.errDesc,
        })
      );
    } else {
      notify.error({
        message: 'An error occurred.',
        description: 'Please try again later.',
      });
    }
  };

  const renderIndividualRequirements = () => (
    <List dense>
      <ListItem>
        <ListItemText
          secondary={
            <ul style={{ color: '#000', lineHeight: '1.4' }}>
              <li>Personal details are required.</li>
              <li>Government-issued ID (e.g., Passport, PAN, etc.) is needed.</li>
              <li>US residents must provide a valid 9-digit SSN; otherwise, leave blank.</li>
              <li>Upload documents in JPG format only.</li>
            </ul>
          }
        />
      </ListItem>
    </List>
  );

  const renderBusinessRequirements = () => (
    <List dense>
      <ListItem>
        <ListItemText
          secondary={
            <ul style={{ color: '#000', lineHeight: '1.4' }}>
              <li>Personal and company documents are required.</li>
              <li>Government-issued ID (e.g., Passport, PAN, etc.) is needed.</li>
              <li>Company documents like Incorporation Certificate, etc.</li>
              <li>US residents must provide a valid 9-digit SSN; otherwise, leave blank.</li>
              <li>Upload documents in JPG format only.</li>
            </ul>
          }
        />
      </ListItem>
    </List>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: '1rem', fontWeight: 'bold', padding: '12px 16px' }}>
        Complete Your KYC in 3 Easy Steps
      </DialogTitle>
      <DialogContent sx={{ padding: '12px 16px' }}>
        {userType === 'INDIVIDUAL' ? renderIndividualRequirements() : renderBusinessRequirements()}

        <Typography variant="body1" sx={{ marginTop: '8px', lineHeight: '1.6' }}>
          Follow the steps below to complete your KYC and unlock full platform access:
        </Typography>

        <List dense sx={{ marginTop: '8px', lineHeight: '1.4' }}>
          <ListItem sx={{ paddingBottom: '4px' }}>
            <ListItemText
              primary={<Typography sx={{ fontWeight: 'bold' }}>Step 1: Check Your Mailbox</Typography>}
              secondary={
                <>
                  Look for an email from <strong>noreply@aerotruth.com</strong> to activate your account.
                </>
              }
            />
          </ListItem>

          <ListItem sx={{ paddingBottom: '4px' }}>
            <ListItemText
              primary={<Typography sx={{ fontWeight: 'bold' }}>Step 2: Sign Up & Complete KYC</Typography>}
              secondary="Fill in your details and complete the KYC process by uploading the required documents."
            />
          </ListItem>

          <ListItem sx={{ paddingBottom: '4px' }}>
            <ListItemText
              primary={<Typography sx={{ fontWeight: 'bold' }}>Step 3: Wait for Verification</Typography>}
              secondary="Once submitted, we will notify you when your verification is complete."
            />
          </ListItem>
        </List>

        <Divider sx={{ marginTop: '12px', marginBottom: '8px' }} />

        <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
          Note: If you haven't received the email yet, please allow some time for it to arrive.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ padding: '12px', justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#462A9C',
            borderRadius: '20px',
            padding: '6px 16px',
            marginRight: '10px',
          }}
        >
          Not Now
        </Button>
        <Tooltip title="Start KYC to complete verification">
          <Button
            onClick={handleGenerateKYC}
            sx={{
              color: '#FFF',
              backgroundColor: '#462A9C',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': { backgroundColor: '#351f7e' },
            }}
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Start KYC'}
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default VerificationModal;
