import React from "react";
import { Box, Grid } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import FormSummerySidebar from "../FormSummerySidebar";
import ReviewDetails from "./ReviewDetails";
import {
  getCurrency,
  getQuote,
  showTranscationPurpose,
  showQRcode,
  showWalletAddress,
  invoiceUpload,
} from "../../../../lib/service/DashboardApi/dashboardApi";
import { useAccount } from "wagmi";
import { submitDeposit } from "../../../../lib/service/depositApi/depositApi";
import { submitTransaction } from "../../../../lib/service/TranscationApi/transcationApi";
import LoadingTxn from "../../../LoaderUI/LoadingTxn";
import TransactionSuccess from "../../../Alerts/TransactionSuccess";
import TransactionFailure from "../../../Alerts/TransactionFailure";
import TransactionOTPVerifyForm from "../../../Forms/TransactionVerifyOTPForm";
import SendMoneyTransferAmountForm from "../../../Forms/SendMoneyTransferAmountFrom";
import SendPaymentMethodForm from "../../../Forms/SendMoneyPaymentMethodFrom";
import { CONTRACT_INFO } from "../../../../configs/ContractsInfo";
import { useChainId, useWriteContract } from "wagmi";
import { calculateTokensWithDecimals } from "../../../../utils/web3.utils";
import ManualPaymentReviewDetails from "./ManualPaymentReviewDetails";
import _debounce from "lodash.debounce";

const CryptoToFiat = ({
  handleGoBack,
  handleNext,
  selectedPaymentType,
  selectedRecipient,
  step,
  addNewTransaction,
  onClose,
  errorTxn,
  successTxn,
  setErrorTxn,
  setSuccessTxn
}) => {
  const { address: walletAddress, isConnected } = useAccount();
  const [sourceCurrency, setSourceCurrency] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [receiveCurrency, setReceiveCurrency] = useState(selectedRecipient?.currency);
  const [transaction, setTransaction] = useState({});
  const [exchangeRate, setExchangeRate] = useState(null);
  const [receiveAmount, setReceiveAmount] = useState("");
  const [chargeTotal, setchargeTotal] = useState(0);
  const [totalFeesCurrency, setTotalFeesCurrency] = useState(0);
  const [totalFeesAmount, setTotalFeesAmount] = useState(0);
  const [destinationCurrencies, setDestinationCurrencies] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [sendAmount, setSendAmount] = useState("");
  const [sourceCurrencies, setSourceCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { writeContractAsync } = useWriteContract();
  const [chargesFee, setChargesFees] = useState([]);
  const chainId = useChainId();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(walletAddress && isConnected ? "CONNECTED_WALLET" : "MANUAL_PAYMENT");
  const [showReason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [depositAddress, setDepositAddress] = useState("");
  const [isQrLoading, setQrLoading] = useState("");
  const [transactionPurpose, setTransactionPurpose] = useState("");
  const [invoiceFileIdSave, setinvoiceFileIdSave] = useState("");
  const [showAmountError, setAmountError] = useState("");
  const [networkList, setNetworkList] = useState([
    { id: 1, name: "ETH", value: "Ethereum" },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [networkValue, setNetworkValue] = useState("Ethereum");
  const [IsQRGenerate, setIsQRGenerate] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [txnError , setTxnError] = useState('');
  const [isReasonMandatory , setIsResonMandatory] = useState(false);
  const [isQuoteExpired, setIsQuoteExpired] = useState(false);
  const [expiresOn, setExpiresOn] = useState(null);
  const [amountToConvert , setAmountToConvert] = useState("");

  useEffect(() => {
    const fetchTransactionPurpose = async () => {
      try {
        const response = await showTranscationPurpose();
        setTransactionPurpose(response.data);
      } catch (error) {
        console.error("Error fetching transaction purpose:", error);
      }
    };

    fetchTransactionPurpose();
  }, []);

  const handleSelectedPaymentMethod = async (value) => {
    setSelectedPaymentMethod(value);
    if (value === 'MANUAL_PAYMENT') {
      setQrLoading(true);
      try {
        const qrCodeResponse = await showQRcode();
        if (qrCodeResponse !== undefined) {
          setIsQRGenerate(true)
          const reader = new FileReader();
          reader.onloadend = () => {
            const url = reader.result;
            setQrCodeImage(url);
          };
          reader.readAsDataURL(qrCodeResponse);

          const walletAddressResponse = await showWalletAddress();
          setDepositAddress(walletAddressResponse.data.crypto_deposit_address);
        } else {
          setIsQRGenerate(false)
        }
        setQrLoading(false)
      } catch (error) {
        setQrLoading(false)
        console.error("Error fetching payment details", error);
      }
    }
  };

  useEffect(() => {
    if (selectedPaymentMethod === "MANUAL_PAYMENT" && !walletAddress) {
      handleSelectedPaymentMethod(selectedPaymentMethod);
    }
    if(!walletAddress)
      setSelectedPaymentMethod('MANUAL_PAYMENT')
  }, [selectedPaymentMethod, walletAddress]);

  const handleSubmit = async() => {
    let number = Number(sendAmount.replaceAll(",", ""));
      setIsLoading(true);
      try {
        let response;
        if(selectedFile){
          response = await invoiceUpload(selectedFile);
        }
        const data = await writeContractAsync({
          chainId: chainId,
          address: CONTRACT_INFO[chainId][sourceCurrency]["ADDRESS"],
          functionName: "transfer",
          abi: CONTRACT_INFO[chainId][sourceCurrency]["ABI"],
          args: [
            "0x8997fd9840a5eb753e1902fF766E8b1bd8Cb61AD",
            calculateTokensWithDecimals(
              parseInt(number),
              CONTRACT_INFO[chainId][sourceCurrency]["DECIMALS"]
            ),
          ],
        });

        const depositData = {
          depositType: "CRYPTO_WALLET",
          confirmationId: data,
          amountRequested: number,
          currencyRequested: sourceCurrency,
        };
        const res = await submitDeposit(depositData);
        const deposit = res.data;
        const txnData = {
          nameOrAlias: "Test Deposit 1",
          userId: deposit.userId,
          quoteId: transaction.quoteId,
          depositId: deposit.depositId,
          transferPurpose: description,
          transferPurposeCode: showReason,
          recipientId: selectedRecipient.id,
          fundsSource: "SALARY",
          endlTransactionMode: "CRYPTO_TO_FIAT",
          otpVerified : false,
          invoiceFileId: response?.data?.invoiceFileId || ""
        };
        await submitTransaction(txnData);
        addNewTransaction();
        setSuccessTxn(true);
        setIsLoading(false);
      } catch (error) {
        setErrorTxn(true);
        setTxnError(error)
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

  const submitMaunalTranscation = async () => {
    let number = Number(sendAmount.replaceAll(",", ""));
    try {
      setIsLoading(true);
      let response;
      if(selectedFile){
        response = await invoiceUpload(selectedFile);
      }
      
      const confirmationId = `txn_${Math.random().toString(36).substr(2, 9)}_${Date.now()}`;

      const depositData = {
        depositType: "CRYPTO_MANUAL_WALLET",
        confirmationId: confirmationId,
        amountRequested: number,
        currencyRequested: sourceCurrency,
      };
      const res = await submitDeposit(depositData);
      const deposit = res.data;

      const txnData = {
        nameOrAlias: "Test Deposit 1",
        userId: deposit.userId,
        quoteId: transaction.quoteId,
        depositId: deposit.depositId,
        transferPurpose: description,
        transferPurposeCode: showReason,
        recipientId: selectedRecipient.id,
        fundsSource: "SALARY",
        endlTransactionMode: "CRYPTO_TO_FIAT",
        otpVerified : false,
        invoiceFileId: response?.data?.invoiceFileId || ""
      };
      await submitTransaction(txnData);
      setSuccessTxn(true);
      setIsLoading(false);
    } catch (error) {
      setErrorTxn(true);
        setTxnError(error)
        setIsLoading(false);
      console.log(error, 'database issue');
    } finally {
      setIsLoading(false);
    }
  };

  const handleReceiveCurrencyChange = useCallback((event) => {
    setReceiveCurrency(event.target.value);
  }, []);

  const fetchQuote =_debounce(
    async ({ sourceCurrency, sourceAmount, receiveCurrency, receiveAmount }) => {
      setIsFetching(true);
      try {
        const payload = {
          partnerId: "1112",
          depositType: 'CRYPTO_WALLET',
          accountType: "INDIVIDUAL",
          source: {
            currency: sourceCurrency,
          },
          destination: {
            currency: receiveCurrency,
          },
          recipientReceiveMode: selectedRecipient?.paymentRail || "SWIFT_IN",
        }
        if(sourceAmount){
          payload.source.amount = sourceAmount;
        }
        if(receiveAmount){
          payload.destination.amount = receiveAmount;
        }

        const response = await getQuote(payload);
        if (response?.code === 200) {
          const { source, destination, fxRate, feeList, feeTotal, feeCurrency } =
            response.data;

          setTransaction(response.data);
          setExchangeRate(response.data.fxRate);
          setChargesFees(feeList);
          setTotalFeesAmount(destination.amountWithoutFeesIncluded);
          setExpiresOn(new Date(response.data.expiresOn)); 
          setchargeTotal(feeTotal);
          setTotalFeesCurrency(feeCurrency)
          setAmountError("");
          setSendAmount(Number(parseFloat(source?.amount)?.toFixed(2))?.toLocaleString());
          setReceiveAmount(Number(parseFloat(destination?.amount)?.toFixed(2))?.toLocaleString());
          setAmountToConvert(source?.amountToConvert);
        } else {
          setTransaction({})
          setExchangeRate(0);
          setChargesFees([]);
          setchargeTotal(0);
          setExpiresOn(null)
          setTotalFeesCurrency('')
          setAmountError(response.errors[0]['errDesc'])
          setAmountToConvert("")
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
        if(error && error?.data){
          const { source, destination } = error?.data;
          if(source?.amount === null){
            setSendAmount("")
          }else{
            setSendAmount(Number(parseFloat(source?.amount)?.toFixed(2))?.toLocaleString());
          }
          if(destination?.amount === null){
            setReceiveAmount("")
          }else{
            setReceiveAmount(Number(parseFloat(destination?.amount)?.toFixed(2))?.toLocaleString());
          }
        }
        setTransaction({});
        setExchangeRate(0);
        setChargesFees(0);
        setTotalFeesAmount(0);
        setchargeTotal(0);
        setExpiresOn(null)
        setTotalFeesCurrency('')
        setAmountError(error?.errors?.[0]?.['errDesc'])
        setAmountToConvert("")
      } finally {
        setIsFetching(false);
      }
    },1200
  )

  useEffect(() => {
    const checkExpiration = () => {
      if (expiresOn) {
        setIsQuoteExpired(new Date() > expiresOn);
      }
    };

    const interval = setInterval(checkExpiration, 1000);
    return () => clearInterval(interval);
  }, [expiresOn]);

  const handleSendAmountChange = useCallback(
    (amount) => {
      setSendAmount(amount);
      setReceiveAmount("");
      setTransaction({});
      setExchangeRate(0);
      setChargesFees([]);
      setchargeTotal(0);
      setAmountError("");
      setTotalFeesCurrency("");
      setExpiresOn(null);
      fetchQuote({
        sourceCurrency,
        sourceAmount: Number(amount?.split(',')?.join('')),
        receiveCurrency,
        receiveAmount: "",
      });
    },
    [
      sourceCurrency,
      receiveCurrency,
      setReceiveAmount,
      setTransaction,
      setExchangeRate,
      setChargesFees,
      setchargeTotal,
      setAmountError,
      setTotalFeesCurrency,
    ]
  );

  const handleReceiveAmountChange = useCallback(
    (amount) => {
      setReceiveAmount(amount);
      setSendAmount("");
      setTransaction({});
      setExchangeRate(0);
      setChargesFees([]);
      setchargeTotal(0);
      setAmountError("");
      setTotalFeesCurrency("");
      setExpiresOn(null);
      fetchQuote({
        sourceCurrency,
        sourceAmount: "",
        receiveCurrency,
        receiveAmount: Number(amount?.split(',')?.join('')),
      });
    },
    [
      sourceCurrency,
      receiveCurrency,
      setSendAmount,
      setTransaction,
      setExchangeRate,
      setChargesFees,
      setchargeTotal,
      setAmountError,
      setTotalFeesCurrency,
    ]
  );

  const toggleInfo = useCallback(() => {
    setShowInfo((prevShowInfo) => !prevShowInfo);
  }, [showInfo, setShowInfo]);

  const fetchCurrencyList = useCallback(async () => {
    try {
      const response = await getCurrency({});
      const { destination, source } = response.data;
      const filteredSource = source.currency.filter(
        (currency) => currency.isCrypto
      );
      setSourceCurrencies(filteredSource);
      setDestinationCurrencies(destination.currency);
    } catch (error) {
      console.error("Error fetching currency list:", error);
    }
  }, [selectedPaymentType]);

  const handleSendOTP = async () => {
    handleNext()
    handleSubmit()
  };

  const handleManualPayment = async () => {
    handleNext()
    submitMaunalTranscation()
  };

  useEffect(() => {
    if (!selectedPaymentType || selectedPaymentType === "") return;
    fetchCurrencyList();
  }, [selectedPaymentType]);

  const handleCurrencyChange = useCallback((event) => {
    setSourceCurrency(event.target.value);
    setSendAmount("");
    setReceiveAmount('');
    setTransaction({})
    setExchangeRate(0);
    setChargesFees([]);
    setchargeTotal(0);
    setTotalFeesCurrency('')
    setAmountError("");
    setExpiresOn(null)
  }, []);

  const handleNetworkChange = useCallback((event) => {
    setNetworkValue(event.target.value);
  }, []);

  const retryTransaction = useCallback(() => {
    setErrorTxn(false);
    if(selectedPaymentMethod === "CONNECTED_WALLET")
       handleSubmit();
    else
      submitMaunalTranscation();  
  }, [handleSubmit, submitMaunalTranscation]);

  const handleSelectChange = (event, newValue) => {    
    const selectedCode = newValue ? newValue.code : "";
    const selectedItem = transactionPurpose.codes.find((i) => i.code === selectedCode);
    
    setReason(selectedCode);
    setDescription(selectedItem?.description || "");
  };

  const isValidateInvoice = () => {
    if(selectedRecipient?.paymentRail === "SWIFT" && selectedRecipient?.relationshipToCustomer !== "SELF"){
      return true;
    }else{
      return false;
    }
  }

  const handleSendMoneyBackBtn = () => {
    setReceiveAmount("");
    setSendAmount("");
    setTransaction({})
    setExchangeRate(0);
    setChargesFees([]);
    setchargeTotal(0);
    setTotalFeesCurrency('')
    setAmountError("");
    handleGoBack();
    setReason("");
    setExpiresOn(null)
    setSelectedFile(null);
  }
  
  return (
    <>
      {step === 1 && (
        <Grid container py={5} justifyContent={{xs:"center", sm:"center" , lg:"flex-start"}}>
          <Grid item xs={12} sm={12} lg={4} p={{xs:"0px 20px", sm:"0px"}}>
            <FormSummerySidebar
              title={"Payment method"}
              showSummery
              showStepOneSummery
              selectedPaymentType={selectedPaymentType}
              selectedRecipient={selectedRecipient}
              networkValue={networkValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} p={{xs:"0px 20px" , sm:"0px"}}>
            <SendPaymentMethodForm
              sourceCurrency={sourceCurrency}
              walletAddress={walletAddress}
              handleCurrencyChange={handleCurrencyChange}
              handleGoBack={handleGoBack}
              handleNext={handleNext}
              toggleInfo={toggleInfo}
              sourceCurrencies={sourceCurrencies}
              onPaymentMethodChange={handleSelectedPaymentMethod}
              selectedPaymentMethod={selectedPaymentMethod}
              networkList={networkList}
              handleNetworkChange={handleNetworkChange}
              networkValue={networkValue}
              IsQRGenerate={IsQRGenerate}
              isConnected={isConnected}
              isQrLoading={isQrLoading}
            />
          </Grid>
        </Grid>
      )}
      {step === 2 && (
        <Box
          padding={{xs:3,md:5}}
          display={"flex"}
          style={{ maxHeight: "100vh", overflow: "auto" }}
          flexDirection={{xs:"column" , sm:"column" , lg:"row"}}
        >
          <FormSummerySidebar
            title={"Transfer amount"}
            showSummery
            showStepOneSummery
            selectedPaymentType={selectedPaymentType}
            selectedPaymentMethod={selectedPaymentMethod}
            selectedRecipient={selectedRecipient}
            showStepTwoSummery
            sourceCurrency={sourceCurrency}
            walletAddress={walletAddress}
            width={{lg:"30%"}}
            networkValue={networkValue}
          />
          <Box mt={2} flexGrow={1}>
            <SendMoneyTransferAmountForm
              sourceCurrency={sourceCurrency}
              destinationCurrencies={destinationCurrencies}
              receiveCurrency={receiveCurrency}
              receiveAmount={receiveAmount}
              totalFeesAmount={totalFeesAmount}
              chargesFee={chargesFee}
              exchangeRate={exchangeRate}
              sendAmount={sendAmount}
              handleReceiveCurrencyChange={handleReceiveCurrencyChange}
              handleSendAmountChange={handleSendAmountChange}
              isFetching={isFetching}
              handleGoBack={handleSendMoneyBackBtn}
              handleNext={handleNext}
              chargeTotal={chargeTotal}
              showReason={showReason}
              handleSelectChange={handleSelectChange}
              transactionPurpose={transactionPurpose}
              setinvoiceFileIdSave={setinvoiceFileIdSave}
              showAmountError={showAmountError}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
              isReasonMandatory={isReasonMandatory}
              selectedRecipient={selectedRecipient?.institutionalAddress?.countryThreeLetter}
              receiveCurrencyValue={selectedRecipient?.institutionalAddress?.country}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              onReceiveAmountChange={handleReceiveAmountChange}
              totalFeesCurrency={totalFeesCurrency}
              isQuoteExpired={isQuoteExpired}
              expiresOn={expiresOn}
              isValidateInvoice={selectedRecipient?.paymentRail === "SWIFT" && selectedRecipient?.relationshipToCustomer !== "SELF"}
              amountToConvert={amountToConvert}
            />
          </Box>
        </Box>
      )}
      {step === 3 && (
        <div style={{ maxHeight: "100vh", overflow: "auto" }}>
          {selectedPaymentMethod === "CONNECTED_WALLET" ? (
            <ReviewDetails
              sendAmount={sendAmount}
              chargesFee={chargesFee}
              walletAddress={walletAddress}
              sourceCurrency={sourceCurrency}
              handleGoBack={handleGoBack}
              handleSubmit={handleSubmit}
              isFetching={isFetching}
              receiveAmount={receiveAmount}
              receiveCurrency={receiveCurrency}
              selectedPaymentType={selectedPaymentType}
              selectedRecipient={selectedRecipient}
              chargeTotal={chargeTotal}
              handleSendOTP={handleSendOTP}
              totalFeesCurrency={totalFeesCurrency}
              isQuoteExpired={isQuoteExpired}
              expiresOn={expiresOn}
              attachedFile={selectedFile}
              exchangeRate={exchangeRate}
              amountToConvert={amountToConvert}
            />
          ) : (
            <ManualPaymentReviewDetails
              receiveCurrency={receiveCurrency}
              handleGoBack={handleGoBack}
              sendAmount={sendAmount}
              sourceCurrency={sourceCurrency}
              chargeTotal={chargeTotal}
              selectedRecipient={selectedRecipient}
              receiveAmount={receiveAmount}
              onClose={onClose}
              submitMaunalTranscation={submitMaunalTranscation}
              qrCodeImage={qrCodeImage}
              depositAddress={depositAddress}
              handleManualPayment={handleManualPayment}
              chargesFee={chargesFee}
              totalFeesCurrency={totalFeesCurrency}
              attachedFile={selectedFile}
              isQuoteExpired={isQuoteExpired}
              expiresOn={expiresOn}
              exchangeRate={exchangeRate}
              selectedPaymentType={selectedPaymentType}
              amountToConvert={amountToConvert}
            />
          )}
        </div>
      )}
      {step === 4 &&
        (isLoading ? (
          <Box flexGrow={1}>
            <LoadingTxn message={"Waiting for crypto..."} title1={'All done. We are now waiting to receive your cryptocurrency.'} title2={'This typically takes only a few minutes.'}/>
          </Box>
        ) : successTxn ? (
          <Box flexGrow={1}>
            <TransactionSuccess
              title={"Transaction Initiated"}
              message={
                "Your cryptocurrency has been received and is now being converted to fiat currency for the recipient. We'll notify you once the transfer is done"
              }
              actionButtonText={"Go to dashboard"}
              buttonAction={() => window.location.reload()}
            />
          </Box>
        ) : errorTxn ? (
          <Box flexGrow={1}>
            <TransactionFailure
              goBackAction={onClose}
              retryAction={retryTransaction}
              retryActionText={"Retry Transaction"}
              goBackActionText={"Go to Dashboard"}
              error={txnError}
            />
          </Box>
        ) : null)}
    </>
  );
};

export default CryptoToFiat;
