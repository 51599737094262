import { Box, Divider, Typography, Button, useMediaQuery,Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AttachFile } from "@mui/icons-material";
import PdfViewer from "./PdfViewer";

const ReviewDetails = ({
  sendAmount,
  chargesFee,
  walletAddress,
  sourceCurrency,
  handleGoBack,
  handleSubmit,
  handleSendOTP,
  isFetching,
  receiveAmount,
  receiveCurrency,
  selectedPaymentType,
  selectedRecipient,
  chargeTotal,
  totalFeesCurrency,
  isQuoteExpired,
  expiresOn,
  attachedFile = {},
  exchangeRate,
  amountToConvert,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [remainingTime, setRemainingTime] = useState("");
  const [open, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!open);
  };

  useEffect(() => {
    if (expiresOn) {
      const expirationDate = new Date(expiresOn);
      const totalDuration = expirationDate - new Date();

      const updateRemainingTime = () => {
        const now = new Date();
        const timeDiff = expirationDate - now;
        if (timeDiff <= 0) {
          setRemainingTime("Expired");
          isQuoteExpired = true;
        } else {
          const minutes = Math.floor(timeDiff / 1000 / 60);
          const seconds = Math.floor((timeDiff / 1000) % 60);
          setRemainingTime(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
        }
      };

      updateRemainingTime(); // Initial call
      const timerInterval = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [expiresOn, isQuoteExpired]);

  return (
    <Box display={"flex"} justifyContent={"center"} padding={{ xs: 2, sm: 5 }}>
      <Box display={"flex"} flexDirection={"column"} width={{ xs: '90%', sm: "60%", md: "40%" }} gap={1}>
        {isQuoteExpired && (
          <Typography
            color="error"
            mt={2}
            fontWeight="bold"
            textAlign="center"
            fontSize={16}
          >
            Quote expired! Please generate a new one.
          </Typography>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography fontWeight={600} fontSize={24} display="flex" alignItems="center">
            Summary
          </Typography>
          {remainingTime && !isQuoteExpired && (
            <Box
              px={1}
              py={0.5}
              display="flex"
              alignItems="center"
              color="error.main"
              fontSize={14}
              fontWeight={500}
            >
              Quote expires in: {remainingTime}
            </Box>
          )}
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"} fontSize={22}>
            Total amount {isMobile && <br />} you send
          </Typography>
          <Typography fontFamily="PPMori-Regular" fontWeight={600} fontSize={22}>
            {(sendAmount)?.toLocaleString()} {sourceCurrency}
          </Typography>
        </Box>
        {chargesFee?.length && chargesFee.map((charges, index) => (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              variant="body1"
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
            >
              {charges.feeName}
            </Typography>
            <Typography variant="body1" fontFamily="PPMori-Regular">
              {charges.feeAmount} {charges.feeCurrency}
            </Typography>
          </Box>
        ))}

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
            Total fee amount
          </Typography>
          <Typography fontFamily="PPMori-Regular" fontWeight={600} fontSize={22}>
            {chargeTotal} {totalFeesCurrency}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography color="text.secondary">
            Exchange rate
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "0.875rem",
              fontFamily: "PPMori-Regular",
            }}
          >

            {exchangeRate ? (<>
              1 {sourceCurrency} = {exchangeRate} {receiveCurrency}</>) : 0}

          </Typography>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
            Total Amount  {isMobile && <br />} we'll Convert
          </Typography>
          <Typography fontFamily="PPMori-Regular" fontWeight={600} fontSize={22}>
            {Number(amountToConvert)?.toLocaleString()} {sourceCurrency}
          </Typography>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
            Recipient get
          </Typography>
          <Typography fontFamily="PPMori-Regular" fontWeight={600} fontSize={22}>
            {(receiveAmount)?.toLocaleString()} {receiveCurrency}
          </Typography>
        </Box>

        <Divider />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            variant="body1"
            fontFamily="PPMori-Regular"
            color={"rgba(0,0,0,0.60)"}
          >
            Recipient
          </Typography>
          <Typography variant="body1" fontFamily="PPMori-Regular">
            {selectedPaymentType === "cryptoToFiat" ? selectedRecipient?.name ? selectedRecipient?.name : selectedRecipient.firstName + "  " + selectedRecipient.lastName : selectedRecipient?.name}
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
            {selectedPaymentType === "cryptoToFiat" || selectedPaymentType === "cryptoCurrency"
              ? "Account Number/IBAN"
              : "Wallet address"}
          </Typography>
          <Typography fontFamily="PPMori-Regular" variant="body1">
            {selectedPaymentType === "cryptoToFiat"
              ? selectedRecipient?.address?.region === "EU" ? `${selectedRecipient?.bankName
                }...${selectedRecipient?.iban?.slice(-4)}` : `${selectedRecipient.bankName
                }...${selectedRecipient?.bankAccountNumber?.slice(-4)}`
              : selectedRecipient.walletAddress?.slice(-4)}
            {selectedPaymentType === "cryptoCurrency" ? selectedRecipient?.name + "..." + selectedRecipient?.account_number_external?.slice(-4) : null}
          </Typography>
        </Box>
        <Divider />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            variant="body1"
            fontFamily="PPMori-Regular"
            color={"rgba(0,0,0,0.60)"}
          >
            Payment method
          </Typography>
          <Typography variant="body1" fontFamily="PPMori-Regular">
            Connected wallet
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          color={"rgba(0,0,0,0.60)"}
        >
          <Typography
            variant="body1"
            fontFamily="PPMori-Regular"
            color={"rgba(0,0,0,0.60)"}
          >
            From
          </Typography>
          <Typography variant="body1" fontFamily="PPMori-Regular">
            MetaMask({walletAddress.slice(0, 6)}...{walletAddress.slice(-4)})
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            variant="body1"
            fontFamily="PPMori-Regular"
            color={"rgba(0,0,0,0.60)"}
          >
            Source currency
          </Typography>
          <Typography variant="body1" fontFamily="PPMori-Regular">
            {sourceCurrency}
          </Typography>
        </Box>
        {selectedPaymentType === "cryptoToFiat" && (
          <Box display={"flex"} justifyContent={"space-between"} alignItems="center" mb={2}>
            <Typography color="text.secondary">Attached document</Typography>
            {attachedFile?.name ? (
              <Button
                startIcon={attachedFile?.name && <AttachFile />}
                variant="text"
                onClick={toggleIsOpen}
                sx={{ textDecoration: attachedFile?.name ? "underline" : "none" }}
              >
                <Typography
                  component="span"
                  sx={{
                    maxWidth: "250px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                    fontSize: '14px'
                  }}
                >
                  { attachedFile?.name?.length > 20 ? `${attachedFile?.name?.slice(0,20)}` + `...` : attachedFile?.name }
                </Typography>
              </Button>
            ) : (
              <Typography variant="body1" fontFamily="PPMori-Regular">
                N/A
              </Typography>
            )}

          </Box>
        )}

        <Box width={"100%"} display={"flex"} gap={1}>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: "none",
              borderColor: "#462A9C",
              color: "#462A9C",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
            }}
            fullWidth
            onClick={handleGoBack}
          >
            Go back
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              backgroundColor: "#462A9C",
              color: "#ffffff",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
            }}
            onClick={handleSendOTP}
            disabled={sendAmount === 0 || isFetching || isQuoteExpired}
          >
            {" "}
            Next
          </Button>
        </Box>
      </Box>
      <Modal open={open} onClose={toggleIsOpen}>
        <Box
          width={"80%"}
          height={"80%"}
          display="flex"
          flexDirection="column"
          bgcolor="background.paper"
          borderRadius={3}
          boxShadow={5}
          mx="auto"
          my="15vh"
          p={2}
          outline="none"
        >
          <PdfViewer file={attachedFile} />
        </Box>
      </Modal>
    </Box>
  );
};

export default ReviewDetails;