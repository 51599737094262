import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Box, Modal, IconButton, useMediaQuery, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StepperUI from "../../Common/StepperUI/StepperUI";
import SidebarSummery from "./SidebarSummery";
import Summery from "./Summery";
import TransactionOTPVerifyForm from "../../Forms/TransactionVerifyOTPForm";
import LoadingTxn from "../../LoaderUI/LoadingTxn";
import TransactionSuccess from "../../Alerts/TransactionSuccess";
import TransactionFailure from "../../Alerts/TransactionFailure";
import TopUpPaymentMethodForm from "../../Forms/TopUpPaymentMethodForm";
import TransferAmountForm from "../../Forms/TransferAmountForm";
import SendMoneyTransferAmountForm from "../../Forms/SendMoneyTransferAmountFrom";
import endlMainLogo from "../../../Images/brandings/endlMainLogo.png";
import TopUpPaymentDetailsForm from "../../Forms/TopUpPaymentDetailsForm";
import { CONTRACT_INFO } from "../../../configs/ContractsInfo";
import { useChainId, useAccount, useWriteContract } from "wagmi";
import { useNavigate } from "react-router-dom";
import {
  getCurrency,
  getQuote,
  showQRcode,
  showTranscationPurpose,
  showWalletAddress,
} from "../../../lib/service/DashboardApi/dashboardApi";
import { submitDeposit } from "../../../lib/service/depositApi/depositApi";
import { submitTransaction } from "../../../lib/service/TranscationApi/transcationApi";
import { calculateTokensWithDecimals } from "../../../utils/web3.utils";
import { notify } from "../../Notification/Notification";
import ManualPaymentReviewDetails from "../SendMoneyModal/CryptoToFiatFlow/ManualPaymentReviewDetails";
import LocalBankTransfer from "./LocalBankTransfer";
import ReviewDetails from "../SendMoneyModal/CryptoToFiatFlow/ReviewDetails";
import _debounce from "lodash.debounce";
import { fetchDepositeDetails } from "../../../lib/service/accountsApi/accountsApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
};

const logoStyle = {
  position: "absolute",
  top: "20px",
  left: "20px",
  width: "100px",
  height: "auto",
};

const TopUpModal = ({ open, onClose, accountDetails }) => {
  const { address: walletAddress } = useAccount();
  const chainId = useChainId();
  const navigate = useNavigate();
  const [selectedPaymentMethodType, setSelectedPaymentMethodType] = useState(
    walletAddress ? "CONNECTED_WALLET" : "MANUAL_PAYMENT"
  );
  const { writeContractAsync } = useWriteContract();
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [step, setStep] = useState(0);
  const [isTransferring, setIsTransFerring] = useState(false);
  const [successTxn, setSuccessTxn] = useState(false);
  const [errorTxn, setErrorTxn] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("cryptoCurrency");
  const [transactionPurpose, setTransactionPurpose] = useState("");
  const [localBankResponse, setLocalBankResponse] = useState();
  const [isQuoteExpired, setIsQuoteExpired] = useState(false);
  const [expiresOn, setExpiresOn] = useState(null);

  const steps = useMemo(
    () => ({
      cryptoCurrency: [
        "Payment Details",
        "Payment Method",
        "Recipient & Amount",
        "Review and Pay",
      ],
      localBank: ["Payment method", "Beneficiary Details"],
    }),
    []
  );
  const [networkList, setNetworkList] = useState([
    { id: 1, name: "ETH", value: "Ethereum" },
  ]);
  const [networkValue, setNetworkValue] = useState("Ethereum");
  const [sourceCurrency, setSourceCurrency] = useState("");
  const [receiveCurrency, setReceiveCurrency] = useState(
    accountDetails?.currency || "USD"
  );
  const [transaction, setTransaction] = useState({});
  const [exchangeRate, setExchangeRate] = useState(null);
  const [receiveAmount, setReceiveAmount] = useState("");
  const [chargeTotal, setchargeTotal] = useState(0);
  const [totalFeesAmount, setTotalFeesAmount] = useState(0);
  const [totalFeesCurrency, setTotalFeesCurrency] = useState(0);
  const [destinationCurrencies, setDestinationCurrencies] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [sendAmount, setSendAmount] = useState();
  const [chargesFee, setChargesFees] = useState([]);
  const [sourceCurrencies, setSourceCurrencies] = useState([]);
  const [showAmountError, setAmountError] = useState("");
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [depositAddress, setDepositAddress] = useState("");
  const [isQrLoading, setQrLoading] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [IsQRGenerate, setIsQRGenerate] = useState("");
  const [txnError, setTxnError] = useState("");
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [showReason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [amountToConvert , setAmountToConvert] = useState("");

  useEffect(() => {
    const fetchTransactionPurpose = async () => {
      try {
        const response = await showTranscationPurpose();
        const firstCode = response.data.codes[0].code;
        const firstDescription = response.data.codes[0].description;
        setReason(firstCode);
        setDescription(firstDescription || "");

        setTransactionPurpose(response.data);
      } catch (error) {
        console.error("Error fetching transaction purpose:", error);
      }
    };

    fetchTransactionPurpose();
  }, []);

  const handleNext = async () => {
    if (selectedPaymentMethod === "localBank") {
      if (step < 1) {
        setStep((prev) => prev + 1);
      }
      try {
        const response = await fetchDepositeDetails(
          accountDetails.account_number_external
        );
        setLocalBankResponse(response.data);
      } catch (error) {
        notify.error({
          message: `Failed to get customer details.`,
        });
        console.error("Error calling fetchDepositeDetails:", error);
      }
    } else {
      setStep((prev) => prev + 1);
    }

  };
  const handleGoBack = () => {
    if (step == 0) return;
    setStep((prev) => prev - 1);
  };

  const handleClickDone = () => {
    setStep(0);
    onClose();
  }

  const fetchCurrencyList = useCallback(async () => {
    try {
      const response = await getCurrency({});
      const { destination, source } = response.data;
      const filteredSource = source.currency.filter(
        (currency) => currency.isCrypto
      );
      setSourceCurrencies(filteredSource);
      setDestinationCurrencies(destination.currency);
    } catch (error) {
      console.error("Error fetching currency list:", error);
    }
  }, []);

  useEffect(() => {
    if (!selectedPaymentMethod || selectedPaymentMethod === "") return;
    fetchCurrencyList();
  }, [selectedPaymentMethod]);

  const fetchQuote = _debounce(
    async ({
      sourceCurrency,
      sourceAmount,
      receiveCurrency,
      receiveAmount,
    }) => {
      setIsFetching(true);
      try {
        const payload = {
          partnerId: "1112",
          depositType: "CRYPTO_WALLET",
          source: {
            currency: sourceCurrency,
          },
          destination: {
            currency: receiveCurrency,
          },
          recipientReceiveMode: "TOPUP_FIAT",
        };
        if (sourceAmount) {
          payload.source.amount = sourceAmount;
        }
        if (receiveAmount) {
          payload.destination.amount = receiveAmount;
        }

        const response = await getQuote(payload);
        if (response?.code === 200) {
          const {
            source,
            destination,
            fxRate,
            feeList,
            feeTotal,
            feeCurrency,
          } = response.data;
          setTransaction(response.data);
          setExchangeRate(fxRate);
          setChargesFees(feeList);
          setSendAmount(Number(parseFloat(source?.amount)?.toFixed(2))?.toLocaleString());
          setReceiveAmount(Number(parseFloat(destination?.amount)?.toFixed(2))?.toLocaleString());
          setTotalFeesAmount(destination.amountWithoutFeesIncluded);
          setExpiresOn(new Date(response.data.expiresOn));
          setchargeTotal(feeTotal);
          setTotalFeesCurrency(feeCurrency);
          setAmountError("");
          setAmountToConvert(source?.amountToConvert);
        } else {
          setTransaction({});
          setExchangeRate(0);
          setChargesFees([]);
          setchargeTotal(0);
          setExpiresOn(null)
          setTotalFeesCurrency("");
          setAmountError(response.errors[0]["errDesc"]);
          setAmountToConvert('')
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
        if(error && error.data){
          const { source, destination } = error?.data;
          if(source?.amount === null){
            setSendAmount('')
          }else{
            setSendAmount(Number(parseFloat(source?.amount)?.toFixed(2))?.toLocaleString());
          }
          if(destination?.amount === null){
            setReceiveAmount("")
          }else{
            setReceiveAmount(Number(parseFloat(destination?.amount)?.toFixed(2))?.toLocaleString());
          }
        }
        setTransaction({});
        setExchangeRate(0);
        setChargesFees(0);
        setTotalFeesAmount(0);
        setchargeTotal(0);
        setExpiresOn(null)
        setTotalFeesCurrency("");
        setAmountError(error?.errors?.[0]?.["errDesc"]);
        setAmountToConvert('')
      } finally {
        setIsFetching(false);
      }
    },
    1200
  );

  const handleSelectedPaymentMethod = async (value) => {
    if (value === "MANUAL_PAYMENT") {
      try {
        setQrLoading(true);
        const qrCodeResponse = await showQRcode();
        if (qrCodeResponse !== undefined) {
          setIsQRGenerate(true);
          const reader = new FileReader();
          reader.onloadend = () => {
            const url = reader.result;
            setQrCodeImage(url);
          };
          reader.readAsDataURL(qrCodeResponse);

          const walletAddressResponse = await showWalletAddress();
          setDepositAddress(walletAddressResponse.data.crypto_deposit_address);
        } else {
          setIsQRGenerate(false);
        }
        setQrLoading(false);
      } catch (error) {
        setQrLoading(false);
        notify.error({
          message: `QR Code payment service is unavailable right now. You can try alternate payment method.`,
        });
        console.error("Error fetching payment details", error);
      }
    }
    setSelectedPaymentMethodType(value);
  };

  useEffect(() => {
    if (!walletAddress && selectedPaymentMethodType === "MANUAL_PAYMENT") {
      handleSelectedPaymentMethod(selectedPaymentMethodType);
    }
    if (!walletAddress) {
      setSelectedPaymentMethodType("MANUAL_PAYMENT");
    }
  }, [selectedPaymentMethodType, walletAddress, setSelectedPaymentMethodType]);

  const handleCurrencyChange = useCallback((event) => {
    setSourceCurrency(event.target.value);
    setSendAmount();
    setReceiveAmount("");
    setTransaction({});
    setExchangeRate(0);
    setChargesFees([]);
    setchargeTotal(0);
    setTotalFeesCurrency("");
    setAmountError("");
    setIsQuoteExpired(false)
    setExpiresOn(null)
  }, []);

  useEffect(() => {
    const checkExpiration = () => {
      if (expiresOn) {
        setIsQuoteExpired(new Date() > expiresOn);
      }
    };

    const interval = setInterval(checkExpiration, 1000);
    return () => clearInterval(interval);
  }, [expiresOn]);

  const handleSendAmountChange = useCallback(
    (amount) => {
      setSendAmount(amount);
      setReceiveAmount("");
      setTransaction({});
      setExchangeRate(0);
      setChargesFees([]);
      setchargeTotal(0);
      setTotalFeesCurrency("");
      setAmountError("");
      setIsQuoteExpired(false)
      setExpiresOn(null)
      fetchQuote({
        sourceCurrency,
        sourceAmount: Number(amount?.split(',')?.join('')),
        receiveCurrency,
        receiveAmount: "",
      });
    },
    [
      sourceCurrency,
      receiveCurrency,
      setReceiveAmount,
      setTransaction,
      setExchangeRate,
      setChargesFees,
      setchargeTotal,
      setAmountError,
      setTotalFeesCurrency,
    ]
  );

  const handleSubmit = async () => {
    setIsTransFerring(true);
    try {
      let number = Number(sendAmount.replaceAll(",", ""));
      const data = await writeContractAsync({
        chainId: chainId,
        address: CONTRACT_INFO[chainId][sourceCurrency]["ADDRESS"],
        functionName: "transfer",
        abi: CONTRACT_INFO[chainId][sourceCurrency]["ABI"],
        args: [
          "0x8997fd9840a5eb753e1902fF766E8b1bd8Cb61AD",
          calculateTokensWithDecimals(
            number,
            CONTRACT_INFO[chainId][sourceCurrency]["DECIMALS"]
          ),
        ],
      });

      const depositData = {
        depositType: "CRYPTO_WALLET",
        confirmationId: data,
        amountRequested: number,
        currencyRequested: sourceCurrency,
      };
      const res = await submitDeposit(depositData);
      const deposit = res.data;
      const txnData = {
        nameOrAlias: "Test Deposit 50",
        userId: deposit.userId,
        quoteId: transaction.quoteId,
        depositId: deposit.depositId,
        recipientId: accountDetails.id,
        transferPurposeCode: showReason,
        transferPurpose: description,
        fundsSource: "SALARY",
        endlTransactionMode: "TOP_UP",
        otpVerified : false
      };
      await submitTransaction(txnData);
      setSuccessTxn(true);
      setIsTransFerring(false);
    } catch (error) {
      setErrorTxn(true);
      setTxnError(error);
      setIsTransFerring(false);
    } finally {
      setIsTransFerring(false);
    }
  };

  const submitMaunalTranscation = async () => {
    setIsTransFerring(true);
    try {
      let number = Number(sendAmount.replaceAll(",", ""));
      const confirmationId = `txn_${Math.random()
        .toString(36)
        .substr(2, 9)}_${Date.now()}`;

      const depositData = {
        depositType: "CRYPTO_MANUAL_WALLET",
        confirmationId: confirmationId,
        amountRequested: number,
        currencyRequested: sourceCurrency,
      };
      const res = await submitDeposit(depositData);
      const deposit = res.data;

      const txnData = {
        nameOrAlias: "Test Deposit 50",
        userId: deposit.userId,
        quoteId: transaction.quoteId,
        depositId: deposit.depositId,
        recipientId: accountDetails.id,
        transferPurposeCode: showReason,
        transferPurpose: description,
        fundsSource: "SALARY",
        endlTransactionMode: "TOP_UP",
        otpVerified : false
      };
      await submitTransaction(txnData);
      setSuccessTxn(true);
      setIsTransFerring(false);
    } catch (error) {
      setTxnError(error);
      setIsTransFerring(false);
      setErrorTxn(true);
      console.log(error, "database issue");
    } finally {
      setIsTransFerring(false);
    }
  };

  const handleSendOTP = async () => {
    handleNext();
    handleSubmit();
  };

  const handleManualPayment = async () => {
    handleNext();
    submitMaunalTranscation();
  };

  const retryTransaction = useCallback(() => {
    setErrorTxn(false);
    if (selectedPaymentMethodType === "CONNECTED_WALLET") handleSubmit();
    else submitMaunalTranscation();
  }, [handleSubmit, submitMaunalTranscation]);

  const handleReceiveCurrencyChange = useCallback((event) => {
    setReceiveCurrency(event.target.value);
  }, []);

  const handleSelectChange = (event, newValue) => {
    const selectedCode = newValue ? newValue.code : "";
    const selectedItem = transactionPurpose.codes.find(
      (i) => i.code === selectedCode
    );
    const isReasonRequired = selectedItem?.invoiceMandatory || false;
    setReason(selectedCode);
    setDescription(selectedItem?.description || "");
  };

  const isValidateInvoice = () => {
    return false;
  }

  const handleReceiveAmountChange = useCallback(
    (amount) => {
      setReceiveAmount(amount);
      setSendAmount("");
      setTransaction({});
      setExchangeRate(0);
      setChargesFees([]);
      setchargeTotal(0);
      setTotalFeesCurrency("");
      setAmountError("");
      setIsQuoteExpired(false)
      setExpiresOn(null)
      fetchQuote({
        sourceCurrency,
        sourceAmount: "",
        receiveCurrency,
        receiveAmount: Number(amount?.split(',')?.join('')),
      });
    },
    [
      sourceCurrency,
      receiveCurrency,
      setSendAmount,
      setTransaction,
      setExchangeRate,
      setChargesFees,
      setchargeTotal,
      setAmountError,
      setTotalFeesCurrency,
    ]
  );

  const handleSendMoneyBackBtn = () => {
    setReceiveAmount("");
    setSendAmount('');
    setTransaction({})
    setExchangeRate(0);
    setChargesFees([]);
    setchargeTotal(0);
    setTotalFeesCurrency('')
    setAmountError("");
    handleGoBack();
    setReason("");
    setExpiresOn(null)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style }}>
        <Box
          padding={2}
          borderBottom={1}
          display={"flex"}
          justifyContent={{ xs: "flex-start", sm: "center", md: "center" }}
          alignItems={"center"}
          borderColor={"rgba(0,0,0,0.3)"}
          position={"relative"}
          width="100%"
        >
          {isLargeScreen && (
            <img
              src={endlMainLogo}
              alt="Logo"
              className="endlLogo"
              style={logoStyle}
            />
          )}
          <Box sx={{ width: "40%" }}>
            <StepperUI step={step} steps={steps[selectedPaymentMethod]} />
          </Box>

          <IconButton
            onClick={() => {
              setStep(0);
              onClose();
            }}
            aria-label="close-open-account-modal"
            sx={{ position: "absolute", right: "12px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          flexGrow={1}
          overflow={"scroll"}
        >
          {step === 0 && (
            <Box
              padding={{ xs: 2, sm: 5 }}
              display={"flex"}
              flexDirection={{ xs: "column", sm: "column", lg: "row" }}
              alignItems={{ xs: "center", sm: "center", lg: "flex-start" }}
            >
              <SidebarSummery title={"Payment details"} />
              <Box width={{ xs: "90%", sm: "50%", md: "50%", lg: "100%" }}>
                <TopUpPaymentMethodForm
                  handleNext={handleNext}
                  handleSelectedPaymentMethodOptionChange={(e) =>
                    setSelectedPaymentMethod(e)
                  }
                  selectedPaymentMethod={selectedPaymentMethod}
                  accountDetails={accountDetails}
                />
              </Box>
              <Box width={"100%"} />
            </Box>
          )}
          {step === 1 && selectedPaymentMethod === "localBank" && (
            <Box
              p={{ xs: 2, sm: 5 }}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <LocalBankTransfer
                handleNext={handleNext}
                handleGoBack={handleGoBack}
                localBankResponse={localBankResponse}
                handleClickDone={handleClickDone}
              />
            </Box>
          )}
          {step === 1 && selectedPaymentMethod === "cryptoCurrency" && (
            <Box
              padding={5}
              display={"flex"}
              flexDirection={{ xs: "column", md: "column", lg: "row" }}
              alignItems={{ xs: "center", lg: "flex-start" }}
            >
              <SidebarSummery
                title={"Payment method"}
                showSummery
                stepOneSummery
                selectedPaymentMethod={selectedPaymentMethod}
                accountDetails={accountDetails}
              />
              <Box width={{ xs: "100%", sm: "50%", lg: "100%" }}>
                <TopUpPaymentDetailsForm
                  selectedPaymentMethod={selectedPaymentMethod}
                  handleNext={handleNext}
                  handleGoBack={handleGoBack}
                  networkValue={networkValue}
                  sourceCurrency={sourceCurrency}
                  onPaymentMethodChange={handleSelectedPaymentMethod}
                  networkList={networkList}
                  sourceCurrencies={sourceCurrencies}
                  selectedPaymentMethodType={selectedPaymentMethodType}
                  walletAddress={walletAddress}
                  handleCurrencyChange={handleCurrencyChange}
                  IsQRGenerate={IsQRGenerate}
                  handleSelectedPaymentMethod={handleSelectedPaymentMethod}
                  isQrLoading={isQrLoading}
                />
              </Box>
              <Box width={"100%"} />
            </Box>
          )}
          {step === 2 && selectedPaymentMethod === "cryptoCurrency" && (
             <Box padding={5} display={"flex"} style={{ maxHeight: '100vh', overflow: 'hidden' }}>
              <SidebarSummery
                title={"Transfer amount"}
                showSummery
                stepOneSummery
                showStepTwoSummery
                selectedPaymentMethodType={selectedPaymentMethodType}
                accountDetails={accountDetails}
                sourceCurrency={sourceCurrency}
                walletAddress={walletAddress}
                networkValue={networkValue}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />
               <Box width={"100%"}>
                <TransferAmountForm
                  sourceCurrency={sourceCurrency}
                  destinationCurrencies={destinationCurrencies}
                  receiveCurrency={receiveCurrency}
                  receiveAmount={receiveAmount}
                  totalFeesAmount={totalFeesAmount}
                  chargesFee={chargesFee}
                  exchangeRate={exchangeRate}
                  sendAmount={sendAmount}
                  handleReceiveCurrencyChange={handleReceiveCurrencyChange}
                  handleSendAmountChange={handleSendAmountChange}
                  isFetching={isFetching}
                  handleGoBack={handleSendMoneyBackBtn}
                  handleNext={handleNext}
                  chargeTotal={chargeTotal}
                  showReason={showReason}
                  handleSelectChange={handleSelectChange}
                  transactionPurpose={transactionPurpose}
                  showAmountError={showAmountError}
                  isQrLoading={isQrLoading}
                  selectedRecipient={accountDetails?.currency}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  onReceiveAmountChange={handleReceiveAmountChange}
                  topUp={true}
                  totalFeesCurrency={totalFeesCurrency}
                  isQuoteExpired={isQuoteExpired}
                  expiresOn={expiresOn}
                  receiveCurrencyValue={accountDetails?.currency}
                  selectedRecipientCountry={accountDetails?.currency}
                  isValidateInvoice={isValidateInvoice}
                  amountToConvert={amountToConvert}
                />
              </Box>
              <Box width={"100%"} />
            </Box>
          )}
          {step === 3 && selectedPaymentMethod === "cryptoCurrency" && (
            <div style={{ maxHeight: "100vh", overflow: "auto" }}>
              {selectedPaymentMethodType === "CONNECTED_WALLET" ? (
                <ReviewDetails
                  sendAmount={sendAmount}
                  chargesFee={chargesFee}
                  walletAddress={walletAddress}
                  sourceCurrency={sourceCurrency}
                  handleGoBack={handleGoBack}
                  handleSubmit={handleSubmit}
                  isFetching={isFetching}
                  receiveAmount={receiveAmount}
                  receiveCurrency={receiveCurrency}
                  selectedPaymentType={selectedPaymentMethod}
                  selectedRecipient={accountDetails}
                  chargeTotal={chargeTotal}
                  handleSendOTP={handleSendOTP}
                  isQuoteExpired={isQuoteExpired}
                  expiresOn={expiresOn}
                  exchangeRate={exchangeRate}
                  amountToConvert={amountToConvert}
                  totalFeesCurrency={totalFeesCurrency}
                />
              ) : (
                <ManualPaymentReviewDetails
                  receiveCurrency={receiveCurrency}
                  handleGoBack={handleGoBack}
                  sendAmount={sendAmount}
                  sourceCurrency={sourceCurrency}
                  chargeTotal={chargeTotal}
                  selectedRecipient={accountDetails}
                  receiveAmount={receiveAmount}
                  onClose={onClose}
                  submitMaunalTranscation={submitMaunalTranscation}
                  qrCodeImage={qrCodeImage}
                  depositAddress={depositAddress}
                  handleManualPayment={handleManualPayment}
                  chargesFee={chargesFee}
                  totalFeesCurrency={totalFeesCurrency}
                  exchangeRate={exchangeRate}
                  isQuoteExpired={isQuoteExpired}
                  expiresOn={expiresOn}
                  amountToConvert={amountToConvert}
                />
              )}
            </div>
          )}
          {step == 4 && selectedPaymentMethod === "cryptoCurrency" &&
            (isTransferring ? (
              <Box flexGrow={1}>
                <LoadingTxn message={"Transferring amount..."} title1={'All done. We are now waiting to receive your cryptocurrency.'} title2={'This typically takes only a few minutes.'} />
              </Box>
            ) : successTxn ? (
              <Box flexGrow={1}>
                <TransactionSuccess
                  title={"Top-up Initiated"}
                  message={
                    "Your cryptocurrency desposit has been received and is now being converted to your account’s currency. We’ll notify you once the conversion is done."
                  }
                  actionButtonText={"Go to dashboard"}
                  buttonAction={() => {
                    onClose();
                    navigate("/");
                    window.location.reload();
                  }}
                />
              </Box>
            ) : errorTxn ? (
              <Box flexGrow={1}>
                <TransactionFailure
                  goBackAction={() => {
                    onClose();
                    navigate("/");
                    window.location.reload();
                  }}
                  retryAction={retryTransaction}
                  retryActionText={"Retry Transaction"}
                  goBackActionText={"Go to dashboard"}
                  error={txnError}
                />
              </Box>
            ) : null)}
        </Box>
      </Box>
    </Modal>
  );
};

export default TopUpModal;
