import directService from "../AppService";
import { checkAuthToken } from "../AuthToken";

function getAuthToken() {
    return localStorage.getItem('authToken');
}

const getCurrency = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            return directService(
                {
                    url: `/api/v1/currency/list`,
                    method: 'GET',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
        }
    } catch (error) {
        console.log(error)
    }
}

const getCountry = (data) => {
    try {        
        const checkToken = checkAuthToken();
        if(checkToken) {
            const token = getAuthToken();
            return directService({
                url: `/api/v1/country/list?operation=${data.opertionType}`,
                method: 'GET',
                data: data,
                headers: { Authorization: `Bearer ${token}` },
            })
        }
    } catch (error) {
        console.log(error);
    }
}

const getRailList = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            return directService(
                {
                    url: `/api/v1/recipient/supported/payment/rails?currency=${data}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
        }
    } catch (error) {
        console.log(error)
    }
}

export { getCurrency, getCountry , getRailList}
