import {
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getCurrency } from "../../lib/service/DashboardApi/dashboardApi";
import { fetchCurrencyToOpenAccount, openAccount } from "../../lib/service/accountsApi/accountsApi";
import { notify } from "../Notification/Notification";
import CircularProgress from "@mui/material/CircularProgress";
import endlMainLogo from "../../Images/brandings/endlMainLogo.png";
import useMediaQuery from "@mui/material/useMediaQuery";

const logoStyle = {
  position: 'absolute',
  top: '20px',
  left: '20px',
  width: '100px',
  height: 'auto',
};

const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(
  require.context("../../Images/flags", false, /\.(png)$/)
);

const cryptoImages = importAll(
  require.context("../../Images/CryptoFlags", false, /\.(png)$/)
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "80vh",
  overflow: "auto",
  px: 5,
  py: 3,
  display: "flex",
  flexDirection: "column",
  gap: 3,
  borderRadius: 5,
  width: "600px",
  overflow: 'auto'
};

const OpenAccountModal = ({ open, onClose, onAccountOpen }) => {
  const [currency, setCurrency] = useState();
  const [accountName, setAccountName] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  useEffect(() => {
    fetchCurrency();
  }, []);

  const handleOpenAccount = async () => {
    try {
      setIsLoading(true);
      const res = await openAccount({
        currency,
        accountName,
      });
      notify.success({
        message: `Successfully created ${currency} account`,
      });
      onAccountOpen();
      onClose();
    } catch (error) {
      console.error("Error during opening the account:", error);
      if (error?.errors?.length > 0) {
        error.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: `Failed to create ${currency} account`,
          description:
            "We cannot proceed your request to open account now. Please try again later",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCurrency = async() => {
    setIsLoading(true);
    try {
      const response =  await fetchCurrencyToOpenAccount();
      setCurrencies(response.data.currency);
    } catch (error) {
      console.log("error while fetching currenct to open account in open account modal file",error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style , width: {xs:"90%" , md:"600px"} }}>
        <Box>
          {/* <img src={endlMainLogo} alt="Logo" className="endlLogo" style={logoStyle}/> */}
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", right: 10}}
            disabled={isLoading}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            style={{
              // width: "500px",
              marginBottom : 50
            }}
          >
            <Typography variant="h6">Open new Account</Typography>
            <Box display={"flex"} flexDirection={"column"} gap={5}>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Typography variant="body1" fontSize={12} color={"grey"}>
                  Currency
                </Typography>
                <FormControl>
                  {!currency && (
                    <InputLabel shrink={false} id="currency-select-label">
                      Select currency
                    </InputLabel>
                  )}
                  <Select
                    labelId="currency-select-label"
                    id="currency-select"
                    placeholder="text"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    sx={{
                      width: "100%",
                      maxHeight: 400,
                    }}
                    className="show-custom-scrollbar"
                    MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    {isLoading ? (
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        p={2}
                      >
                        Loading...
                      </Box>
                    ) : currencies.length === 0 ? (
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        p={2}
                      >
                        No currency found. Please try again later
                      </Box>
                    ) : (
                      currencies.map((currency) => (
                        <MenuItem key={currency.code} value={currency.code}>
                          <img
                            src={
                              images[`${currency.code.toLowerCase()}.png`] ||
                              images[`${currency.country.toLowerCase()}.png`]
                            }
                            alt={currency.code}
                            style={{
                              width: "15px",
                              marginRight: "10px",
                              fontFamily: "PPMori-Regular",
                            }}
                          />
                          {currency.code}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>
              {currency && (
                <>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography variant="body1" fontSize={12} color={"grey"}>
                      Account name 
                    </Typography>
                    <TextField
                      value={accountName}
                      id="account-name"
                      placeholder="Account name"
                      variant="outlined"
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                  </Box>
                  <Box width={"100%"}>
                    <Button
                      fullWidth
                      onClick={handleOpenAccount}
                      style={{
                        backgroundColor: "#462A9C",
                        color: "#FFFF",
                        textTransform: "none",
                        fontFamily: "PPMori-Regular",
                      }}
                      disabled={isLoading}
                      endIcon={
                        isLoading ? (
                          <CircularProgress
                            size={20}
                            sx={{
                              color: "white",
                            }}
                          />
                        ) : (
                          <></>
                        )
                      }
                    >
                      {!isLoading ? "Confirm" : "Opening account"}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default OpenAccountModal;
