import React, { useState, useEffect, useRef, useContext } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  Box,
  Container,
  Grid,
  Divider,
  TextField,
  Button,
  CircularProgress,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import { LocalDatePicker } from "../../components/Calender/NewCalender";
import {
  getProfile,
  updateProfile,
  uploadProfile,
  getProfilePicture,
} from "../../lib/service/ProfileApi/profileApi";
import {
  forgetPasswordOTPEmail,
  resetPassword,
} from "../../lib/service/authApi/authApi";
import { clearAuthToken } from "../../lib/service/AuthToken";
import { notify } from "../../components/Notification/Notification";
import Profile from "../../pages/Profile/Profile";
import dayjs from "dayjs";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../components/LogoutModal/LogoutModal";
import ProfileContext from "../../context/ProfileContext";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../store/slices/userSlice";
import TermsContent from "../../pages/TermsOfUseModule/TermsContent";
import PrivacyContent from "../../pages/PrivacyModule/PrivacyContent";
import commonHelpers from "../../utils/common.helpers";
import { useDisconnect } from 'wagmi';
const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: "24px",
    background: "#FFFFFF",
  },
  avatar: {
    width: "90px",
    height: "90px",
    marginBottom: "16px",
    position: "relative",
  },
  editIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "#fff",
    borderRadius: "50%",
    padding: "2px",
  },
  tab: {
    fontSize: "14px",
    textTransform: "none",
  },
  tabPanel: {
    marginTop: "16px",
  },
  section: {
    marginBottom: "16px",
  },
  button: {
    marginTop: "16px",
  },
  editButton: {
    alignSelf: "flex-start",
    marginBottom: "16px",
  },
  textField: {
    "& .MuiInputBase-root": {
      borderBottom: "none",
    },
  },
  logoutButton: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  logoutIcon: {
    marginRight: "8px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  datePicker: {
    "& .MuiInputBase-root": {
      borderBottom: "none",
    },
  },
});

function ProfileContainer() {
  const classes = useStyles();
  const {setProfile, setFormsData, setFullName, setPreviewUrl, setLoading, profile, formsData, loading,fullName} = useContext(ProfileContext);
  const [value, setValue] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [currStep, setCurrStep] = useState(1);
  const logoInputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const user = useSelector(state => state.user.profileData);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    otp: "",
    password_again: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
    password_again: null,
  });
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isTablet = useMediaQuery((theme)=>theme.breakpoints.up("lg"));

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { isValid, message } = commonHelpers.validateFile({ file, type: 'image' });
      if (!isValid) {
        notify.error({
          message: message.message,
          description: message.description,
        });
        return;
      }
  
      const imageUrl = URL.createObjectURL(file);
      if (imageUrl) {
        const formData = new FormData();
        formData.append("file", file);
        const res = await uploadProfile(formData);
        if(res.status === "Success") {
          window.location.reload();
        }
        setPreviewUrl(imageUrl);
      }
    }
  };
  
  const handleNext = async () => {
    if (currStep === 1) {
      try {
        await handleSendOTP();
        setCurrStep(2);
      } catch (e) {
        console.log(e);
      }
    }
    if (currStep === 2) {
      try {
        setIsLoading(true);
        console.log(formData);
        const res = await resetPassword({
          email: formsData.email,
          password: formData.password,
          password_again: formData.password_again,
          otp: formData.otp,
        });
        notify.success({
          message: "Your password has been reset successfully",
        });
        setCurrStep(1);
        setIsLoading(false);
        
      } catch (e) {
        if (e?.errors?.length > 0) {
          e.errors.map((err) =>
            notify.error({
              message: err.errMsg,
              description: err.errDesc,
            })
          );
        } else {
          notify.error({
            message: "Failed to reset.",
            description:
              "We can not proceed th request for forgot password now. Please try again later",
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleStorOTP = (otp) => {
    setFormData((prev) => ({
      ...prev,
      otp: otp,
    }));
  };
  const handleInput = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [e.target.name]: null,
    }));
  };
  const handleSendOTP = async () => {
    try {
      setIsSendingOTP(true);
      const res = await forgetPasswordOTPEmail({ email: formsData.email, phone: null, otpMode: 'EMAIL', operationType: 'RESET_PASSWORD'  });
      notify.success({
        message: "OTP has been sent to your email",
        description: `We have sent the OTP to ${formsData.email}.Please check your email`,
      });
    } catch (e) {
      if (e?.errors?.length > 0) {
        e.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        setFormData({
          email: "",
          password: "",
          otp: "",
          password_again: "",
        })
        notify.error({
          message: "Failed to send the OTP.",
          description:
            "We can not proceed the request for forgot password now. Please try again later",
        });
      }
      throw Error();
    } finally {
      setIsSendingOTP(false);
    }
  };
  const handleGoBack = () => {
    setCurrStep((prev) => prev - 1);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

   const fetchProfile = async() => {
    try {
      const response = await getProfile();
      if (response.status === "Success") {
        setProfile(response.data);
        dispatch(userActions.setProfileData({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          dateOfBirth: dayjs(response.data.dateOfBirth),
          phone: response.data.phone,
          email: response.data.email,
          addressLine1: response.data.addressLine1,
          addressLine2: response.data.addressLine2,
          city: response.data.city,
          state: response.data.state,
          country: response.data.country,
          postalCode: response.data.postalCode,
        }));
        setFormsData({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          dateOfBirth: dayjs(response.data.dateOfBirth),
          phone: response.data.phone,
          email: response.data.email,
          addressLine1: response.data.addressLine1,
          addressLine2: response.data.addressLine2,
          city: response.data.city,
          state: response.data.state,
          country: response.data.country,
          postalCode: response.data.postalCode,
        });
        setFullName(`${response.data.firstName} ${response.data.lastName}`);
      }
      fetchProfilePicture();
    } catch (error) {
      console.error("Failed to fetch profile data", error);
    } finally {
      setLoading(false);
    }
  }

  const fetchProfilePicture = async() => {
    setLoading(true);
    try {
      const profileResponse = await getProfilePicture();
      const reader = new FileReader();
      reader.onloadend = () => {
        const url = reader.result;
        const updatedUrl = `${url}?${new Date().getTime()}`;
        setPreviewUrl(updatedUrl);
        dispatch(userActions.setProfileData({
          previewUrl: updatedUrl
        }));
      };
      reader.readAsDataURL(profileResponse);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleEdit = () => {
    if (isEditing) {
      handleSubmit();
    }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormsData({
      ...formsData,
      [name]: value,
    });

    if (name === "firstName" || name === "lastName") {
      setFullName(`${formsData.firstName} ${formsData.lastName}`);
    }
  };

  const handleDateChange = (date) => {
    setFormsData({
      ...formsData,
      dateOfBirth: date,
    });
  };

  const handleSubmit = async () => {
    try {
      const [firstName, lastName] = fullName.split(" ");
      const updatedFields = {
        ...formsData,
        firstName,
        lastName,
        dateOfBirth: formsData.dateOfBirth?.format("YYYY-MM-DD"),
      };
      if (
        Object.keys(updatedFields).some(
          (key) => updatedFields[key] !== profile[key]
        )
      ) {
        
        const response = await updateProfile(updatedFields);
        if (response.status === "Success") {
          setProfile({ ...profile, ...updatedFields });
          notify.success({
            message: "Profile updated successfully!",
          });
        } else {
          notify.error({
            message: "Failed to update profile",
          });
        }
      }
    } catch (error) {
      console.error("Failed to update profile", error);
    }
  };

  const openLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  const handleLogout = () => {
    disconnect(); 
    clearAuthToken();
    closeLogoutModal();
    dispatch(userActions.setIsAuthenticated(false));
    dispatch(userActions.resetProfileData());
    navigate('/login', { replace: true });
    notify.success({
      message: "You are successfully logged out.",
    });
  };

  const formatName = (name) => {
    return name
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  if (loading) {
    return (
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress />
      </Stack>
  );
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            padding={{ xs: 2, sm: 3 }}
            gap={5}
            sx={{
              backgroundColor: "#F6F5F9",
              width: "100%",
              margin: 0,
              paddingBottom: { xs: 2, sm: 3 },
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant={{ xs: "h5", sm: "h4", md: "h3" }}
                fontSize={{ xs: "22px", sm: "24px", md: "26px" }}
                fontFamily="PPMori-Regular"
              >
                Profile
              </Typography>
            </Grid>
          </Box>

          <AppBar
            position="static"
            color="transparent"
            elevation={0}
            sx={{
              backgroundColor: "#F6F5F9",
              width: "100%",
              margin: 0,
            }}
          >
            <Toolbar className={classes.toolbar}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab className={classes.tab} label="Personal Information" />
                <Tab className={classes.tab} label="Change Password" />
                <Tab className={classes.tab} label="Terms and Conditions" />
                <Tab className={classes.tab} label="Privacy Policy" />
              </Tabs>

              <Button
                color="inherit"
                // className={classes.logoutButton}
                onClick={openLogoutModal}
                sx={{
                  ml: 'auto',
                  display: { xs: 'none', sm: 'flex' }, // This will override styles on xs devices
                  ...classes.logoutButton, // Spread any other styles from the class if needed
                }}
              >
                <LogoutIcon
                  className={classes.logoutIcon}
                  sx={{ color: "#DD2B22" }}
                />
                {isTablet && <span style={{ color: "#DD2B22" }}>Log out</span>}
              </Button>
            </Toolbar>
          </AppBar>

          <Box p={{ xs: 2, sm: 3 }} className={classes.tabPanel}>
            {value === 0 && (
              <Container maxWidth="md">
              <Box display="flex" alignItems="center" mb={2}>
                {/* Profile Image */}
                <Box position="relative">
                  <input hidden type="file" ref={logoInputRef} onChange={handleImageChange} />
                  <Box
                    mt={1}
                    bgcolor="#f9f8f5"
                    border={1}
                    borderRadius="100%"
                    height={50}
                    width={50}
                    borderColor="#e5e2e0"
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                    onClick={() => logoInputRef?.current?.click()}
                  >
                    {user.profileUrl ? (
                      <Box width={50} height={50} borderRadius="100%" overflow="hidden">
                        <img src={user.profileUrl} width={50} height={50} style={{ objectFit: "cover" }} />
                      </Box>
                    ) : (
                      <Typography variant="body1" fontSize={25} color="#e5e2e0">
                        {profile?.firstName[0]}
                      </Typography>
                    )}
                    {!logoutModalOpen && (
                      <Box
                        position="absolute"
                        bgcolor="#a6a4a3"
                        borderRadius="100%"
                        height={20}
                        width={20}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          right: -4,
                          top: -4,
                          zIndex: 0,
                        }}
                      >
                        <CreateOutlinedIcon sx={{ color: "white", fontSize: "12px" }} />
                      </Box>
                    )}
                  </Box>
                </Box>
            
                {/* Personal Information Header */}
                <Box ml={2} flexGrow={1}>
                  <Typography variant="h6">Personal Information</Typography>
                  <Typography variant="body2">Update your photo and personal details here.</Typography>
                </Box>
              </Box>
            
              <Divider sx={{ margin: "16px 0" }} />
            
              {/* Full Name */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} sm={4}>
                  <Typography variant="body1">Full name</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  {isEditing ? (
                    <TextField
                      fullWidth
                      name="fullName"
                      value={formatName(fullName)}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  ) : (
                    <Typography variant="body2">{formatName(fullName)}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ margin: "16px 0" }} />
            
              {/* Date of Birth */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} sm={4}>
                  <Typography variant="body1">Date of birth</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  {isEditing ? (
                    <LocalDatePicker value={formsData.dateOfBirth} onChange={(newDOB) => handleDateChange(newDOB)} />
                  ) : (
                    <Typography variant="body2">{profile?.dateOfBirth ? profile?.dateOfBirth : 'N/A'}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ margin: "16px 0" }} />
            
              {/* Phone Number */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} sm={4}>
                  <Typography variant="body1">Phone number</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  {isEditing ? (
                    <TextField fullWidth name="phone" value={formsData.phone} onChange={handleInputChange} />
                  ) : (
                    <Typography variant="body2">{profile?.phone ? profile?.phone : 'N/A'}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ margin: "16px 0" }} />
            
              {/* Email Address */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} sm={4}>
                  <Typography variant="body1">Email address</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  {isEditing ? (
                    <TextField fullWidth name="email" value={formsData.email} onChange={handleInputChange} />
                  ) : (
                    <Typography variant="body2">{profile?.email}</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ margin: "16px 0" }} />
            
              {/* Address */}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} sm={4}>
                  <Typography variant="body1">Address</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  {isEditing ? (
                    <>
                      <TextField
                        fullWidth
                        name="addressLine1"
                        label="Address Line 1"
                        value={formsData.addressLine1}
                        onChange={handleInputChange}
                        multiline
                        rows={2}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        name="addressLine2"
                        label="Address Line 2"
                        value={formsData.addressLine2}
                        onChange={handleInputChange}
                        multiline
                        rows={2}
                        margin="normal"
                      />
                      <TextField fullWidth name="city" label="City" value={formsData.city} onChange={handleInputChange} margin="normal" />
                      <TextField fullWidth name="state" label="State" value={formsData.state} onChange={handleInputChange} margin="normal" />
                      <TextField fullWidth name="country" label="Country" value={formsData.country} onChange={handleInputChange} margin="normal" />
                      <TextField
                        fullWidth
                        name="postalCode"
                        label="Postal Code"
                        value={formsData.postalCode}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                    </>
                  ) :(!profile?.address || Object.values(profile.address).every((value) => value === null)) ? (
                    <Typography variant="body2">N/A</Typography>
                  )  : (
                    <Typography variant="body2">
                      {profile?.address?.addressLine1 ? profile.address.addressLine1 : ""},{" "}
                      {profile?.address?.addressLine2 ? profile.address.addressLine2 : ""}
                      {profile?.address?.addressLine1 || profile.address.addressLine2 ? <br /> : ""}
                      {profile?.address.city ? profile.address.city : ""},{" "}
                      {profile?.address.postalCode ? profile.address.postalCode : ""}
                      {profile?.address.city || profile?.address.postalCode ? <br /> : ""}
                      {profile?.address.state ? profile.address.state : ""} ,{" "}
                      {profile?.address.country ? profile.address.country : ""}{" "}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ margin: "16px 0" }} />
            </Container>
            
            )}

            {value === 1 && (
              <Container maxWidth="md">
                <Profile
                  currStep={currStep}
                  handleInputChange={handleInput}
                  formData={formData}
                  formErrors={formErrors}
                  handleNext={handleNext}
                  handleStoreOTP={handleStorOTP}
                  isLoading={isLoading}
                  isSendingOTP={isSendingOTP}
                  resendOTPHandler={handleSendOTP}
                  handleGoBack={handleGoBack}
                />
              </Container>
            )}

            {value === 2 && <TermsContent />}

            {value === 3 && <PrivacyContent />}
          </Box>
        </Grid>

        <LogoutModal
          isOpen={logoutModalOpen}
          onCLose={closeLogoutModal}
          onLogout={handleLogout}
        />
      </Grid>
    </Box>
  );
}

export default ProfileContainer;
