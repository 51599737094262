import React from 'react';
import { Box, Typography } from '@mui/material';
import PrivacyContent from './PrivacyContent';
const PrivacyNotice = () => {
  return (
    <Box p={2} sx={{ maxWidth: '800px', margin: 'auto' }}>
      <PrivacyContent />
    </Box>
  );
};

export default PrivacyNotice;
