import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

const PdfViewer = ({ file }) => {
  const [src, setSrc] = useState("");

  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setSrc(fileUrl);

      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [file]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <iframe
        src={src}
        width="100%"
        height="100%"
        title="PDF Viewer"
        style={{ border: "none" }}
      />
    </Box>
  );
};

export default PdfViewer;
