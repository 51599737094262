import React, { useEffect, useState } from 'react';

const Payment = () => {
    const [iframeHeight, setIframeHeight] = useState('600px');

    useEffect(() => {
        const handleResize = () => {
            setIframeHeight(`${window.innerHeight - 100}px`); 
        };

        window.addEventListener('resize', handleResize);
        handleResize(); 
        return () => {
            window.removeEventListener('resize', handleResize); 
        };
    }, []);

    return (
        <div>
            <iframe
                src="/payments.html"
                title="HTML Page"
                width="100%"
                height={iframeHeight}
                style={{ border: 'none' }}
            ></iframe>
        </div>
    );
};

export default Payment;
