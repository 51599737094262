import { toast } from "react-hot-toast";

const setAuthToken = (token,expiryDate) => {
    const expiryLogin = new Date(expiryDate);
    const expiryTimeInMilliseconds = expiryLogin.getTime(); // 24 hours in milliseconds
    console.log(token,expiryTimeInMilliseconds,expiryDate, 'date')
    localStorage.setItem('authToken', token);
    localStorage.setItem('tokenExpiry', expiryTimeInMilliseconds);
}

const clearAuthToken = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiry');
    // redirectToLogin();
};

const checkAuthToken = () => {
    const token = localStorage.getItem('authToken');
    const expiryTime = localStorage.getItem('tokenExpiry');
    const now = new Date().getTime();

    if ((!token && !expiryTime) || (now > expiryTime)) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('tokenExpiry');
        redirectToLogin();
    }
    return true
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = String(date.getFullYear()).slice(-2);
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00';
  
    return `${day} ${month}, ${year} ${hours}:${minutes}:${seconds}`;
  };

  
const redirectToLogin = () =>{
    window.location.href = '/login'; 
}


const handleErrorResponse = (response) => {
    if (response.code === 400 && response.errors) {
      const errorMessages = response.errors.map(error => `• ${error.errDesc}`).join('\n');
      toast.error(errorMessages, {
        style: {
          whiteSpace: 'pre-wrap',
          lineHeight: '1.5'
        }
      });
    } else {
      toast.error('An unexpected error occurred.');
    }
  };

export {setAuthToken,checkAuthToken ,redirectToLogin, formatDate, clearAuthToken, handleErrorResponse}