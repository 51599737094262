import React, { useState } from "react";
import {
  Box, Typography, Button, TextField, MenuItem, IconButton, Tab, Tabs,
  Collapse,
  Divider
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));

const flagImages = importAll(
  require.context("../../Images/flags", false, /\.(png)$/)
);

function ContactSupportForm() {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0); // State to track active tab
  const [expandedCountry, setExpandedCountry] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };


  const toggleCountryDropdown = (country) => {
    setExpandedCountry(expandedCountry === country ? null : country);
  };

  const countries = [
    { label: "United States", flag: require('../../Images/flags/usd.png') },
    // { label: "United Kingdom", flag: require('../../Images/flags/gbp.png') },
    // { label: "China", flag: require('../../Images/flags/china.png') },
    // { label: "India", flag: require('../../Images/flags/india.png') },
    // { label: "Japan", flag: require('../../Images/flags/japan.png') },
  ];

  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} height={"100svh"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          spacing={3}
          style={{
            backgroundColor: "#F6F5F9",
            width: "100%",
            margin: 0,
          }}
          p={3}
          gap={3}
        >
          {/* <Typography
            variant="h1"
            fontSize="14px"
            color="#8A8887"
            style={{ fontFamily: "PPMori-Regular" }}
          >
            Dashboard / Help Center/{" "}
          </Typography> */}

          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => navigate("/help-center")}>
              <ArrowBackIosNewOutlinedIcon />
            </IconButton>
            <Typography variant="h3" fontSize="26px" fontFamily="PPMori-Regular">
              Contact Support
            </Typography>
          </Box>
        </Box>

        {/* Tabs for Send Message, Call Us, Live Chat */}
        <Box p={3}>
          <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
            {/* <Tab label="Send Message" /> */}
            <Tab label="Call Us" />
            {/* Note:- Commented for future refrence */}
            {/* <Tab label="Live Chat" /> */}
          </Tabs>

          {/* Tab Panel Content */}
       

          {tabIndex === 0 && (
            <Box display="flex" p={3} justifyContent="space-between" gap={2} flexDirection={{ xs: "column", sm: "row" }}>
              {/* Left Side Text */}
              <Box sx={{ width: "100%" }}>
                <Typography variant="h5" fontWeight="bold" mb={1}>Call us from any phone</Typography>
                {/* <Typography>Your ID is: <strong>11292304</strong></Typography> */}
                <Typography mt={1}>When contacting us, please refer to your email id</Typography>
                <Typography fontSize="12px" color="gray" mt={2}>Local or international charges may apply</Typography>
              </Box>

              {/* Right Side Dropdown List for Countries */}
              <Box display="flex" flexDirection="column" width={{xs: "100%", md: "50%"}}>
                {countries.map((country) => (
                  <Box key={country.label} mb={2} sx={{ borderBottom: '1px solid lightgray' }}>
                    {/* Country Flag + Name + Expand Icon */}
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle1">
                        <img src={country.flag} alt={country.label} /> {country.label}
                      </Typography>
                      <IconButton onClick={() => toggleCountryDropdown(country.label)}>
                        {expandedCountry === country.label ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Box>

                    {/* Collapsible Section */}
                    <Collapse in={expandedCountry === country.label}>
                      <Divider sx={{ my: 1 }} />
                      {/* Languages */}
                      {/* <Typography fontSize="14px" color="gray">+1,000,000 English, Spanish</Typography> */}

                      {/* Working Hours */}
                      <Box display="flex" alignItems="center" mt={1}>
                        <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                        <Typography fontSize="14px" color="gray">
                          Working hours: Monday-Sunday 1 PM - 10 PM UTC
                        </Typography>
                      </Box>
                    </Collapse>
                  </Box>
                ))}
              </Box>

            </Box>
          )}

          {tabIndex === 1 && (
            <Box display="flex" flexDirection="column" p={3} gap={3}>
              {/* Main Header */}
              <Typography variant="h5">
                Live chat
              </Typography>

              {/* Description */}
              <Box width={{ xs: "100%", md: "600px" }}>
                <Typography fontSize="14px" mb={1}>
                  Got questions or need assistance? Our Live Chat is here for you! Connect with our intelligent
                  chatbot for instant help and support. Fast, convenient, and available 24/7.
                </Typography>

                <Typography fontSize="14px">
                  Feel free to chat with us! Our team is here to assist you.
                </Typography>
              </Box>
              {/* Working Hours Section */}
              <Box display="flex" alignItems="center" gap={1} mt={2}>
                <AccessTimeIcon fontSize="small" />
                <Typography fontSize="14px">
                  Working hours: Monday–Sunday, 24 hours
                </Typography>
              </Box>

              {/* Contact Support Button */}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ backgroundColor: "#462A9C", mt: 3, maxWidth: "300px" }}
              >
                Contact support
              </Button>
            </Box>
          )}

        </Box>
      </Box>
    </Box>
  );
}

export default ContactSupportForm;
